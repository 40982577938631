import { Loader } from '../../../_core/loader.component';
import { IRequestTableContainer, RequestTableNestedContainer } from './request-table.nested.container';
import { useRequests } from '../../../domains/request/request.service';

export const RequestTableContainer: React.FC<IRequestTableContainer> = (props) => {
  const { requests, loading } = useRequests();

  if (loading) return <Loader />;
  return <RequestTableNestedContainer requests={requests} {...props} />;
};
