import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

// Providers
import { AuthProvider } from './providers/auth.provider';
import { GraphqlProvider } from './providers/graphql.provider';

// Layouts
import { LandingPageLayout } from './components/_layouts/landing-page.layout';
import { InsetLayout } from './components/_layouts/inset.layout';

// Pages
import { AuthRedirectPageContainer } from './components/_pages/AuthRedirect/auth-redirect-page.container';
import ErrorPage from './components/_pages/error.page';
import { ProfilePageContainer } from './components/_pages/ProfilePage/profile-page.container';
import { ToastProvider } from './providers/toast.provider';
import { NoMatchPageLayout } from './components/_layouts/404-page.layout';
import { FirmProfilePageContainer } from './components/_pages/FirmProfilePage/firm-profile-page.container';
import { DashboardPageContainer } from './components/_pages/DashboardPage/dashboard-page.container';
import { JoinWithCodePageContainer } from './components/_pages/JoinWithCodePage/join-with-code-page.container';
import { GetInviteByCodeContainer } from './components/domains/invite/get-by-code/get-invite-by-code-form.container';
import { AuthenticatedLayoutWrapper } from './components/_layouts/authenticated-wrapper.layout';
import { EntityProfilePageContainer } from './components/_pages/EntityProfilePage/entity-profile-page.container';
import { ClientProfilePageContainer } from './components/_pages/ClientProfilePage/client-profile-page.container';

import { DefaultPageLayoutWithOutlet } from './components/_layouts/default-page.layout';
import { RequestPageAuthorizationWrapper } from './components/_pages/RequestPage/request-page-auth-wrapper/request-page-auth-wrapper';

export enum ROUTE_PATHS {
  LANDING = '/',
  DASHBOARD = '/dashboard',
  LESSONS = '/lessons',
  CONNECT_AUTH0 = '/connect/0auth',
  PROFILE = '/profile',
  CLIENT_PROFILE = '/profile/client',
  ENTITY_PROFILE = '/profile/entity',
  FIRM_PROFILE = '/profile/firm',
  JOIN = '/join',
  JOIN_WITH_CODE = '/join/:code',
  REQUEST = '/request',
  ASSIGNED_REQUEST = '/assigned-request'
}

const ROUTES = [
  <Route element={<ToastProvider />}>
    <Route element={<AuthProvider />}>
      <Route element={<GraphqlProvider />}>
        <Route path={ROUTE_PATHS.LANDING} element={<LandingPageLayout />} />
        <Route
          path={ROUTE_PATHS.CONNECT_AUTH0}
          element={<InsetLayout unauthenticatedMode />}
          errorElement={<ErrorPage />}
        >
          <Route index element={<AuthRedirectPageContainer />} />
        </Route>
        <Route element={<AuthenticatedLayoutWrapper />}>
          <Route path={ROUTE_PATHS.DASHBOARD} element={<InsetLayout />} errorElement={<ErrorPage />}>
            <Route index element={<DashboardPageContainer />} />
          </Route>
          <Route path={ROUTE_PATHS.REQUEST} element={<DefaultPageLayoutWithOutlet dark />} errorElement={<ErrorPage />}>
            <Route path={ROUTE_PATHS.REQUEST + '/:requestId'} element={<RequestPageAuthorizationWrapper />} />
          </Route>
          <Route path={ROUTE_PATHS.PROFILE} element={<InsetLayout />} errorElement={<ErrorPage />}>
            <Route index element={<ProfilePageContainer />} />
            <Route path={ROUTE_PATHS.CLIENT_PROFILE + '/:clientId'} element={<ClientProfilePageContainer />} />
            <Route path={ROUTE_PATHS.ENTITY_PROFILE + '/:entityId'} element={<EntityProfilePageContainer />} />
            <Route path={ROUTE_PATHS.FIRM_PROFILE} element={<FirmProfilePageContainer />} />
          </Route>
        </Route>
        <Route path={ROUTE_PATHS.JOIN_WITH_CODE} element={<InsetLayout />} errorElement={<ErrorPage />}>
          <Route
            element={
              <AuthenticatedLayoutWrapper
                redirectPath={ROUTE_PATHS.JOIN}
                redirectMessage="Find code and login to continue"
              />
            }
          >
            <Route index element={<JoinWithCodePageContainer />} />
          </Route>
        </Route>
        <Route path={ROUTE_PATHS.JOIN} element={<InsetLayout unauthenticatedMode />} errorElement={<ErrorPage />}>
          <Route index element={<GetInviteByCodeContainer />} />
        </Route>
        <Route
          path={ROUTE_PATHS.ASSIGNED_REQUEST}
          element={<DefaultPageLayoutWithOutlet dark />}
          errorElement={<ErrorPage />}
        >
          <Route
            path={ROUTE_PATHS.ASSIGNED_REQUEST + '/:requestId'}
            element={<RequestPageAuthorizationWrapper clientMode />}
          />
        </Route>
        <Route path="*" element={<NoMatchPageLayout />} />
      </Route>
    </Route>
  </Route>
];

export const createRouter = () => {
  return createBrowserRouter(createRoutesFromElements(ROUTES));
};
