import { useParams } from 'react-router-dom';
import { AuthorizedRequestPageWrapper } from './request-page-auth-wrapper.authorized';
import { TokenAccessRequestPageWrapper } from './request-page-auth-wrapper.token.wrapper';

export interface IRequestPageAuthorizationWrapper {
  clientMode?: boolean;
}

export const RequestPageAuthorizationWrapper = ({ clientMode }: IRequestPageAuthorizationWrapper) => {
  const params = useParams();
  const requestId = params.requestId ?? '';

  if (clientMode) return <TokenAccessRequestPageWrapper requestId={requestId} />;
  return <AuthorizedRequestPageWrapper requestId={requestId} />;
};
