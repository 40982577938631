import type { RefObject } from 'react';
import { useEventListener } from 'usehooks-ts';

type EventType = 'mousedown' | 'mouseup' | 'touchstart' | 'touchend';

const doesNodeContainTarget = (target: Node, node?: Node | null) => {
  let isInside = false;
  if (node) {
    // Check if the node and the target are the same node
    isInside = node == target || node.isSameNode(target);

    // Check if the node contains the target
    // NOTE: I found this check to not be reliable, and had to rely on recursively checking the child nodes sameness (like above)
    if (!isInside) isInside = node.contains(target);

    // Recurse through the child nodes and check if they contain the target
    if (!isInside) {
      node.childNodes.forEach((child) => {
        if (!isInside) isInside = doesNodeContainTarget(child);
      });
    }
  }

  return isInside;
};

/**
 * Custom hook for handling clicks outside a specified element.
 * @template T - The type of the element's reference.
 * @param {RefObject<T>} ref - The React ref object representing the element to watch for outside clicks.
 * @param {(event: MouseEvent | TouchEvent) => void} handler - The callback function to be executed when a click outside the element occurs.
 * @param {EventType} [eventType] - The mouse event type to listen for (optional, default is 'mousedown').
 * @returns {void}
 * @see [Documentation](https://usehooks-ts.com/react-hook/use-on-click-outside)
 * @example
 * const containerRef = useRef(null);
 * useOnClickOutside([containerRef], () => {
 *   // Handle clicks outside the container.
 * });
 */
export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: MouseEvent | TouchEvent) => void,
  eventType: EventType = 'mousedown'
): void {
  useEventListener(eventType, (event) => {
    const target = event.target as Node;

    // Do nothing if the target is not connected element with document
    if (!target || !target.isConnected) {
      return;
    }

    const isInside = doesNodeContainTarget(target, ref.current);
    if (!isInside) {
      handler(event);
    }
  });
}
