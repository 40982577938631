import { RegisterCompanyForm } from './register-company-form.component';
import { useRegisterCompany } from '../company.service';
import { IRegisterCompanyProps } from '../company.types';
import { IFormWithStepperProps } from '../../../_core/form/form.types';
import { logError } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';

export const RegisterCompanyContainer = ({ onError, onSuccess, ...rest }: IFormWithStepperProps) => {
  const { registerCompany } = useRegisterCompany();

  const handleRegister = async (props: IRegisterCompanyProps) => {
    try {
      await resetFormMessages({ setSubmitError: onError, setSuccess: onSuccess });

      await registerCompany(props);
      onSuccess('Successfully created firm');
    } catch (err) {
      const errMsg = 'Unable to create firm.';
      logError(errMsg, (err as Error).message);
      onError(errMsg);
    }
  };

  return <RegisterCompanyForm onSubmit={handleRegister} {...rest} />;
};
