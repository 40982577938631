import { showError } from '../../../../lib/utils';
import { useDeleteLocation, useRestoreLocation } from '../../../domains/locations/location.service';
import { LocationTable, ILocationTableProps } from './location-table.component';

export const LocationTableContainer: React.FC<ILocationTableProps> = (props) => {
  const { deleteLocation, loading: deleting } = useDeleteLocation();
  const { restoreLocation, loading: restoring } = useRestoreLocation();

  return (
    <LocationTable
      {...props}
      {...{
        deleteLocation: (props) => deleteLocation(props).catch((err) => showError('Unable to delete location', err)),
        deleting,
        restoreLocation: (props) => restoreLocation(props).catch((err) => showError('Unable to restore location', err)),
        restoring
      }}
    />
  );
};
