import { PropsWithChildren } from 'react';

// Labels
export const Label = ({ children }: PropsWithChildren) => (
  <span className="font-bold text-xl tracking-tight uppercase flex-1">{children}</span>
);

export const LabelValue = ({ children }: PropsWithChildren) => (
  <span className="font-light text-xl tracking-tight flex-1 text-left text-black">{children}</span>
);

// Titles
export const PageTitle = ({ children }: PropsWithChildren) => (
  <span className="ml-2 text-white md:text-3xl sm:text-xl">{children}</span>
);

export const SectionTitle = ({ children }: PropsWithChildren) => (
  <span className="text-black-800 text-xl border-b border-gray-200 uppercase text-center w-full">{children}</span>
);

// Basic Texts

// Meant for use as a text field in combo of other similar items
export const LowlightText = ({ children }: PropsWithChildren) => (
  <span className="text-sm text-gray-500">{children}</span>
);

// Meant for use to draw attention to basic text
export const HighlightText = ({ children }: PropsWithChildren) => (
  <span className="text-sm font-medium text-black">{children}</span>
);

export const ScreenReaderText = ({ children }: PropsWithChildren) => <span className="sr-only">{children}</span>;
