import { Button } from '../../../../_core/button/button.component';
import { useDeleteEntityAction } from './entity-table-delete-action';
import { IEntityTableMultiselectActionProps } from './entity-table-multiselect-action-button.types';
import { useRestoreEntityAction } from './entity-table-restore-action';

interface IEntityTableMultiselectActionButtonProps extends IEntityTableMultiselectActionProps {
  selected: boolean[];
}

export const EntityTableMultiselectActionButton: React.FC<IEntityTableMultiselectActionButtonProps> = ({
  selected,
  ...actionProps
}) => {
  const deleteEntityAction = useDeleteEntityAction(actionProps);
  const restoreEntityAction = useRestoreEntityAction(actionProps);

  return (
    <>
      <Button
        disabled={!selected.some((s) => s)}
        dropdown={{ options: [restoreEntityAction, deleteEntityAction] }}
        text="ACTION"
        size="large"
      />
    </>
  );
};
