import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

// Utils
import { AuthRedirectPage } from './auth-redirect-page.component';

export const AuthRedirectPageContainer: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  const [text, setText] = useState('Loading...');
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      setText('Authenticated. Redirecting...');
      setTimeout(() => {
        navigate('/dashboard');
      }, 2000);
    }
  }, [isAuthenticated, navigate]);

  return <AuthRedirectPage text={text} />;
};
