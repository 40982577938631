import { useState } from 'react';
import { FormContainer } from '../../../_core/form/form-container.component';
import { CreateLocationsNestedContainer } from './create-locations-form-nested.container';

export const CreateLocationsContainer = () => {
  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  return (
    <FormContainer
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      title="Add Locations"
    >
      <CreateLocationsNestedContainer ignoreDefaults onError={setSubmitError} onSuccess={setSuccess} />
    </FormContainer>
  );
};
