import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { IInvite, INVITE_STATUS, Identifiable } from '../../../lib/types';
import { ICompanyInviteQueryParams, ICompanyInviteResult, IInviteQueryParams, IInviteResult } from './invite.types';
import {
  ACCEPT_INVITE,
  GET_COMPANY_INVITES,
  GET_INVITE,
  REMOVE_INVITE,
  RESEND_INVITE,
  SEND_INVITES,
  UPDATE_INVITES
} from './invite.queries';
import { IUpdateMemberParams } from '../../../lib/query.types';
import { readInvitesCache, writeInvitesCache } from './invite.utils';
import { wipeCurrCompanyCache } from '../companies/company.utils';
import { mergeCacheLists } from '../../../lib/utils';
import { USER_COMPANIES_REFETCH_QUERIES } from '../companies/company.const';

export const useAcceptInvite = () => {
  const [mutation, rest] = useMutation<{ acceptInvite: IInvite }, IInviteQueryParams>(ACCEPT_INVITE, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache) => wipeCurrCompanyCache(cache),
    refetchQueries: USER_COMPANIES_REFETCH_QUERIES
  });

  const acceptInvite = useCallback((variables: IInviteQueryParams) => mutation({ variables }), [mutation]);

  return { acceptInvite, ...rest };
};

export const useCompanyInvites = (status: INVITE_STATUS = INVITE_STATUS.PENDING) => {
  const { data, ...rest } = useQuery<ICompanyInviteResult, ICompanyInviteQueryParams>(GET_COMPANY_INVITES, {
    fetchPolicy: 'cache-and-network',
    variables: { status }
  });

  return { ...rest, ...data };
};

export const useLazyInvite = () => {
  const [query, { data, ...rest }] = useLazyQuery<IInviteResult, IInviteQueryParams>(GET_INVITE, {
    fetchPolicy: 'cache-and-network'
  });

  return { getInvite: query, ...data, ...rest };
};

export const useRemoveInvite = () => {
  const [mutation, rest] = useMutation<{ removeInvite: IInvite }, Identifiable>(REMOVE_INVITE, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) {
        const invites = readInvitesCache({ cache });

        if (invites) writeInvitesCache({ cache, invites: invites?.filter((v) => v._id !== data.removeInvite._id) });
      }
    }
  });

  const removeInvite = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { removeInvite, ...rest };
};

export const useResendInvite = () => {
  const [mutation, rest] = useMutation<{ resendInvite: IInvite }, Identifiable>(RESEND_INVITE, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) {
        const currInvites = readInvitesCache({ cache });
        if (currInvites) writeInvitesCache({ cache, invites: mergeCacheLists(currInvites, [data.resendInvite]) });
      }
    }
  });

  const resendInvite = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { resendInvite, ...rest };
};

export const useSendInvites = () => {
  const [mutation, rest] = useMutation<{ sendInvites: IInvite[] }>(SEND_INVITES, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) {
        const currInvites = readInvitesCache({ cache });

        const newInvites = [];
        if (currInvites) newInvites.push(...currInvites);
        newInvites.push(...data.sendInvites);

        if (currInvites) writeInvitesCache({ cache, invites: newInvites });
      }
    }
  });

  const sendInvites = useCallback(
    (variables: { department: string[]; emails: string[]; location: string[]; roles: string[] }) =>
      mutation({ variables }),
    [mutation]
  );

  return { sendInvites, ...rest };
};

export const useUpdateInvites = () => {
  const [mutation, rest] = useMutation<{ updateInvites: IInvite[] }, IUpdateMemberParams>(UPDATE_INVITES, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) {
        const currInvites = readInvitesCache({ cache });
        if (currInvites) writeInvitesCache({ cache, invites: mergeCacheLists(currInvites, data.updateInvites) });
      }
    }
  });

  const updateInvites = useCallback((variables: IUpdateMemberParams) => mutation({ variables }), [mutation]);

  return { updateInvites, ...rest };
};
