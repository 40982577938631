import { Disclosure } from '@headlessui/react';
import { Bars3Icon } from '@heroicons/react/20/solid';
import { PageHeaderMenuContent } from './page-header-menu-content.component';
import { useFetchCurrUser } from '../../../domains/users/user.service';
import { useCurrCompany } from '../../../domains/companies/company.service';
import { ScreenReaderText } from '../../typography';
import { useCompanyMembers } from '../../../domains/member/member.service';
import { ROUTE_PATHS } from '../../../../_routes';
import { COMPLETE_ONBOARDING_KEY } from '../../../../lib/const';
import { ROLE } from '../../../../lib/types';
import { CompleteOnboardingToast } from '../../../_pages/FirmProfilePage/complete-onboarding-toast';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import { REGISTER_COMPANY_STEPPER_ID } from '../../../domains/companies/company.const';

interface IPageHeaderMenuContainerProps {
  close: () => void;
  open: boolean;
}

export const PageHeaderMenuContainer = ({ close, open }: IPageHeaderMenuContainerProps) => {
  const { company } = useCurrCompany();
  const { members } = useCompanyMembers();
  const { user } = useFetchCurrUser();

  // If current company is not finished with onboarding, and the user is a company admin, show the onboarding toast
  useEffect(() => {
    if (
      company &&
      members &&
      !company.completedOnboarding &&
      !(location.pathname === ROUTE_PATHS.FIRM_PROFILE && location.search.includes(COMPLETE_ONBOARDING_KEY)) &&
      members.find((member) => member.user._id === user?._id && member.roles.includes(ROLE.COMPANY_ADMIN)) &&
      !document.querySelector(`#${REGISTER_COMPANY_STEPPER_ID}`)
    ) {
      toast(<CompleteOnboardingToast name={company.name} />, { id: COMPLETE_ONBOARDING_KEY, duration: 60000 });
    }
  }, [company, members, user?._id]);

  return (
    <>
      <div className="flex">
        <Disclosure.Button className="relative inline-flex items-center justify-center bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none">
          <ScreenReaderText>Open main menu</ScreenReaderText>
          <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
        </Disclosure.Button>
      </div>
      {open && <PageHeaderMenuContent close={close} company={company} user={user} />}
    </>
  );
};
