import { useMutation, useQuery } from '@apollo/client';

import { GET_CLIENTS, DELETE_CLIENT, RESTORE_CLIENT, UPDATE_CLIENT, GET_CLIENT } from './client.queries';
import { IClientsResults } from './client.types';
import { useCallback } from 'react';
import { IClient, Identifiable } from '../../../lib/types';
import { updateClientCache } from './client.utils';
import { IArchivableProps, IUpdateCompanyPermissionsVariantParams } from '../../../lib/query.types';

export const useClient = (variables: Identifiable) => {
  const { data, ...rest } = useQuery<{ client: IClient }>(GET_CLIENT, { fetchPolicy: 'cache-and-network', variables });
  return { ...rest, client: data?.client };
};

export const useClients = () => {
  const { data, ...rest } = useQuery<IClientsResults, IArchivableProps>(GET_CLIENTS, {
    fetchPolicy: 'cache-and-network',
    variables: { includeArchived: true }
  });

  return { ...rest, ...data };
};

export const useDeleteClient = () => {
  const [mutation, rest] = useMutation<{ deleteClient: IClient }, Identifiable>(DELETE_CLIENT, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) updateClientCache({ cache, clients: [data.deleteClient] });
    }
  });

  const deleteClient = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { deleteClient, ...rest };
};

export const useRestoreClient = () => {
  const [mutation, rest] = useMutation<{ restoreClient: IClient }, Identifiable>(RESTORE_CLIENT, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) updateClientCache({ cache, clients: [data.restoreClient] });
    }
  });

  const restoreClient = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { restoreClient, ...rest };
};

export const useUpdateClient = () => {
  const [mutation, rest] = useMutation<{ updateClient: IClient }, IUpdateCompanyPermissionsVariantParams>(
    UPDATE_CLIENT,
    {
      context: { serializationKey: 'MUTATION', tracked: true },
      update: (cache, { data }) => {
        if (data) updateClientCache({ cache, clients: [data.updateClient] });
      }
    }
  );

  const updateClient = useCallback(
    (variables: IUpdateCompanyPermissionsVariantParams) => mutation({ variables }),
    [mutation]
  );

  return { updateClient, ...rest };
};
