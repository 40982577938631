export enum QuestionType {
  TEXTAREA = 'textarea',
  TEXT = 'text',
  NUMBER = 'number',
  CURRENCY = 'currency',
  DATE = 'date',
  EMAIL = 'email',
  PHONE = 'phone'
}
export const QuestionTypeLabels: Record<QuestionType, string> = {
  [QuestionType.TEXTAREA]: 'multi',
  [QuestionType.TEXT]: 'txt',
  [QuestionType.NUMBER]: 'num',
  [QuestionType.CURRENCY]: '$',
  [QuestionType.DATE]: 'date',
  [QuestionType.EMAIL]: 'email',
  [QuestionType.PHONE]: 'phone'
};
export const QuestionTypes = Object.values(QuestionType);
