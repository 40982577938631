import { RefObject, useLayoutEffect, useState } from 'react';
import { useResizeObserver } from 'usehooks-ts';

export const useHasOverflow = <T extends HTMLElement, U extends HTMLElement>({
  ref,
  parentRef
}: {
  ref: RefObject<T>;
  parentRef: RefObject<U>;
}) => {
  // Determine if overflow is ever detected, can then trigger a component shrinking case
  // TODO: May want to reset this on ref resize, but leave as is until there are multiple consumption cases of this hook
  const [initialWidthOverflowDetected, setInitialWidthOverflowDetected] = useState(false);

  // Use ResizeObserver to detect any changes in ref width and then trigger initial overflow check
  const { width } = useResizeObserver({ ref });

  useLayoutEffect(() => {
    const { current } = ref;
    const { current: parent } = parentRef;

    if (current && parent && !initialWidthOverflowDetected) {
      const hasOverflow = current.scrollWidth > parent.clientWidth;

      setInitialWidthOverflowDetected(hasOverflow);
    }
  }, [initialWidthOverflowDetected, ref, parentRef, width]);

  return { initialOverflowDetected: initialWidthOverflowDetected };
};
