import { Loader } from '../../_core/loader.component';
import { ICompany, IUser } from '../../../lib/types';
import { Button } from '../../_core/button/button.component';
import { useState } from 'react';
import { RIDialog } from '../../_core/dialog/dialog.component';
import { RegisterCompanyStepper } from '../../domains/companies/register/register-company-stepper.component';
import { Tabs } from '../../_core/tabs.component';
import { DetailBox } from '../../_core/details/details-box.component';
import { DetailItem } from '../../_core/details/details-item.component';
import { activeStatus } from '../../../utils/wording.helpers';
import { TableWithActions } from '../../_core/table/table-with-actions.component';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../_routes';
import { FetchResult } from '@apollo/client';
import { ISetActiveCompanyProps } from '../../domains/users/user.types';
import { UpdateUserContainer } from '../../domains/users/update/update-user-form.container';
import { showError } from '../../../lib/utils';

interface IProfilePageProps {
  activeCompanyId?: string;
  companies?: ICompany[];
  loadingCompanies: boolean;

  user?: IUser;
  loadingUser: boolean;

  setActiveCompany: (_: string) => Promise<FetchResult<ISetActiveCompanyProps>>;
  settingActiveCompany: boolean;
}

export const ProfilePage: React.FC<IProfilePageProps> = ({
  activeCompanyId,
  companies,
  loadingCompanies,
  user,
  loadingUser,
  setActiveCompany,
  settingActiveCompany
}) => {
  const navigate = useNavigate();

  const [registerCompanyOpen, setRegisterCompanyOpen] = useState(false);
  const [updateUser, setUpdateUser] = useState(false);

  if (loadingUser || !user)
    return (
      <div className="mx-auto">
        <Loader />
      </div>
    );

  return (
    <>
      <h2 className="font-bold text-2xl tracking-tight w-full">Contact Information</h2>
      <DetailBox>
        <DetailItem label="Email" value={user.email} />
        <DetailItem label="Name" value={user.name} />
      </DetailBox>
      <div className="mt-4 flex flex-1 w-full">
        <DetailBox stretch>
          <Tabs
            tabs={[
              {
                title: 'Firms',
                content: (
                  <TableWithActions
                    loading={loadingCompanies}
                    headers={['Name', 'Status', '']}
                    values={
                      companies?.map(({ _id: rowId, name, deletedAt }) => ({
                        rowId,
                        values: [
                          { children: name },
                          { children: activeStatus(!deletedAt) },
                          {
                            children:
                              activeCompanyId === rowId ? null : (
                                <Button
                                  loading={settingActiveCompany}
                                  text="Select"
                                  size="large"
                                  onClick={() => {
                                    setActiveCompany(rowId)
                                      .then(() => navigate(ROUTE_PATHS.FIRM_PROFILE))
                                      .catch((err) => showError('Unable to change active company', err));
                                  }}
                                />
                              )
                          }
                        ]
                      })) ?? []
                    }
                  >
                    <Button onClick={() => setRegisterCompanyOpen(true)} text="Add Firm" size="large" />
                  </TableWithActions>
                )
              }
            ]}
          />
        </DetailBox>
      </div>
      <RIDialog open={registerCompanyOpen} setOpen={setRegisterCompanyOpen}>
        <RegisterCompanyStepper close={() => setRegisterCompanyOpen(false)} />
      </RIDialog>
      <RIDialog open={updateUser} setOpen={setUpdateUser}>
        <UpdateUserContainer name={user.name} />
      </RIDialog>
    </>
  );
};
