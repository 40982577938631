import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { FormLabel } from '../../../_core/form/form-label.component';
import { FormEditor } from '../../../_pages/FormBuilderPage/form-editor.component';
import EditorJS, { OutputBlockData } from '@editorjs/editorjs';
import { useRef } from 'react';

export interface ICreateTemplateFormValues {
  description?: string;
  title: string;
}

export interface ICreateTemplateFormProps extends IRIFormProps<ICreateTemplateFormValues> {
  data: OutputBlockData;
  version: string;
}

export const CreateTemplateForm = ({ data, onSubmit, version }: ICreateTemplateFormProps) => {
  const ref = useRef<EditorJS>();

  const validateForm = (values: ICreateTemplateFormValues) => {
    const errors: FormikErrors<ICreateTemplateFormValues> = {};

    if (!values.title.trim()) errors.title = 'Required';

    return errors;
  };

  return (
    <Formik
      initialValues={{ title: '' }}
      onSubmit={(values) => onSubmit({ description: values.description?.trim(), title: values.title.trim() })}
      validate={validateForm}
      validateOnBlur={false}
    >
      {(props: FormikProps<ICreateTemplateFormValues>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-6 mt-10">
          <FormInputWithLabel id="title" name="title" label="Title" value={props.values.title} />
          <FormInputWithLabel
            id="description"
            name="description"
            label="Description"
            value={props.values.description}
          />
          <div className="flex items-center justify-between w-full">
            <FormLabel label="Template" name="template" />
            <span className="text-xs">
              <b>Client View Only</b> - Changes made in this window will have no effect on template
            </span>
          </div>
          <FormEditor
            data={{ blocks: [data], version }}
            editorblock={'create-template-editorjs'}
            editorRef={ref}
            disabled
            onSave={async () => undefined}
            readOnly
            requestId=""
          />
          <FormButton loading={props.isSubmitting} text="Create" />
        </Form>
      )}
    </Formik>
  );
};
