import { gql } from '@apollo/client';
import { PLAN_FRAGMENT } from '../plan/plan.queries';

export const COMPANY_SETTINGS_FRAGMENT = gql`
  fragment CompanySettings on Company {
    settings {
      requestTypes
    }
  }
`;

export const COMPANY_FRAGMENT = gql`
  fragment CompanyDetails on Company {
    _id
    name
    deletedAt
    completedOnboarding
    plan {
      ...PlanDetails
    }
    ...CompanySettings
  }

  ${COMPANY_SETTINGS_FRAGMENT}
  ${PLAN_FRAGMENT}
`;

export const GET_CURR_COMPANY = gql`
  query CurrCompany {
    company {
      ...CompanyDetails
    }
  }

  ${COMPANY_FRAGMENT}
`;

export const GET_USER_COMPANIES = gql`
  query UserCompanies($includeArchived: Boolean) {
    companies(includeArchived: $includeArchived) {
      ...CompanyDetails
    }
  }

  ${COMPANY_FRAGMENT}
`;

export const EDIT_COMPANY_REQUEST_TYPES = gql`
  mutation EditRequestTypes($requestTypeIDs: [ID!]!) {
    setCompanyRequestTypes(requestTypeIDs: $requestTypeIDs) {
      ...CompanyDetails
    }
  }

  ${COMPANY_FRAGMENT}
`;

export const REGISTER_COMPANY = gql`
  mutation RegisterCompany($planName: PLAN_NAME!, $name: String) {
    registerCompany(planName: $planName, name: $name) {
      ...CompanyDetails
    }
  }

  ${COMPANY_FRAGMENT}
`;

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($active: Boolean, $completedOnboarding: Boolean) {
    updateCompany(active: $active, completedOnboarding: $completedOnboarding) {
      ...CompanyDetails
    }
  }

  ${COMPANY_FRAGMENT}
`;
