import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { COMPLETE_ONBOARDING_KEY } from '../../../lib/const';

export const useLogout = () => {
  const { logout } = useAuth0();
  const [loading, setLoading] = useState(false);

  const handleLogout = useCallback(
    async (onComplete?: () => void) => {
      setLoading(true);
      logout({ logoutParams: { returnTo: window.origin } }).finally(() => {
        setLoading(false);
        toast.dismiss(COMPLETE_ONBOARDING_KEY);
      });
      if (onComplete) onComplete();
    },
    [logout, setLoading]
  );

  return { handleLogout, loading };
};
