import { Disclosure } from '@headlessui/react';
import logo from '../../../../assets/imgs/logo.png';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../_routes';
import { useAuth0 } from '@auth0/auth0-react';
import { FirmSelector } from './firm-selector.component';
import { PageHeaderMenuContainer } from './menu/page-header-menu.container';
import { PageTitle } from '../typography';
import { ForceRegisterPromptsContainer } from './force-register-prompts/force-register-prompts.container';

interface IPageHeaderProps {
  unauthenticatedMode?: boolean;
}

export const PageHeader = ({ unauthenticatedMode }: IPageHeaderProps) => {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  return (
    <Disclosure as="div" className="bg-secondary">
      {({ open, close }) => (
        <>
          <div className="mx-auto sm:px-6 lg:px-8 flex justify-between">
            <div className="flex md:gap-24 sm:gap-8">
              <button
                className="flex h-16 items-center justify-between px-4 sm:px-0"
                onClick={() => navigate(isAuthenticated ? ROUTE_PATHS.DASHBOARD : ROUTE_PATHS.LANDING)}
                type="button"
              >
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img className="h-8 w-8" src={logo} alt="Request IQ Logo" />
                  </div>
                  <PageTitle>Request IQ</PageTitle>
                </div>
              </button>
              {!unauthenticatedMode && (
                <>
                  <FirmSelector />
                  <ForceRegisterPromptsContainer />
                </>
              )}
            </div>
            {isAuthenticated && <PageHeaderMenuContainer close={close} open={open} />}
          </div>
        </>
      )}
    </Disclosure>
  );
};
