import { Loader } from '../../_core/loader.component';
import { IEntity, IClientInvite, Identifiable, IEntityMember, IClient } from '../../../lib/types';
import { Button } from '../../_core/button/button.component';
import { Tabs } from '../../_core/tabs.component';
import { DetailBox } from '../../_core/details/details-box.component';
import { DetailItem } from '../../_core/details/details-item.component';
import { activeStatus } from '../../../utils/wording.helpers';
import { ContactsTableNestedContainer } from '../../domains/contacts/contacts-table/contacts-table.nested.container';
import { useMemo, useState } from 'react';
import { RIDialog } from '../../_core/dialog/dialog.component';
import { UpdateEntityContainer } from '../../domains/entities/update/update-entity-form.container';
import { IAdminProps, IPersonalCompanyProps } from '../../_core/core.types';

interface IEntityProfilePageProps extends IAdminProps, IPersonalCompanyProps {
  clients?: IClient[];
  deleteEntity: (_: Identifiable) => void;
  deleting: boolean;
  entity?: IEntity;
  invites?: IClientInvite[];
  members?: IEntityMember[];
  restoreEntity: (_: Identifiable) => void;
  restoring: boolean;
}

export const EntityProfilePage: React.FC<IEntityProfilePageProps> = ({
  clients,
  deleteEntity,
  deleting,
  entity,
  invites,
  isAdmin,
  isPersonalCompany,
  members,
  restoreEntity,
  restoring
}) => {
  const [showEdit, setShowEdit] = useState(false);

  const memberClients = useMemo(
    () =>
      members?.reduce((acc: IClient[], m) => {
        const found = clients?.find((c) => c.user._id === m.user._id);
        if (found) acc.push({ ...found, entityMemberId: m._id, entityDeletedAt: m.deletedAt });
        return acc;
      }, []),
    [clients, members]
  );

  if (!entity)
    return (
      <div className="mx-auto">
        <Loader />
      </div>
    );

  return (
    <>
      <h2 className="font-bold text-2xl tracking-tight w-full">Entity Information</h2>
      <DetailBox>
        <DetailItem label="Entity" value={entity.name} />
        <DetailItem label="Status" value={activeStatus(!entity.deletedAt)} />
        {!isPersonalCompany && (
          <>
            <DetailItem label="Departments" value={entity.department.map(({ name }) => name).join(', ')} />
            <DetailItem label="Locations" value={entity.location.map(({ name }) => name).join(', ')} />
          </>
        )}
      </DetailBox>
      <DetailBox endAligned>
        {isAdmin && (
          <>
            <Button size="xl" text="Edit Entity" onClick={() => setShowEdit(true)} />
            <Button
              size="xl"
              text={`${entity.deletedAt ? 'Una' : 'A'}rchive Entity`}
              onClick={() => {
                if (entity.deletedAt) restoreEntity(entity);
                else deleteEntity(entity);
              }}
              loading={entity.deletedAt ? restoring : deleting}
            />
          </>
        )}
      </DetailBox>
      <div className="mt-4 flex flex-1 w-full">
        <DetailBox>
          <Tabs
            tabs={[
              {
                title: 'Contacts',
                content:
                  !invites || !memberClients ? (
                    <Loader />
                  ) : (
                    <ContactsTableNestedContainer
                      isAdmin={isAdmin}
                      isPersonalCompany={isPersonalCompany}
                      invites={invites}
                      clients={memberClients}
                      entity={entity}
                    />
                  )
              }
            ]}
          />
        </DetailBox>
      </div>
      <RIDialog open={showEdit} setOpen={setShowEdit}>
        <UpdateEntityContainer {...entity} />
      </RIDialog>
    </>
  );
};
