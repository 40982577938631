import { useRegisterCompany } from '../company.service';
import { RegisterCompanyOption } from '../company.types';
import { logError } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';
import { Button } from '../../../_core/button/button.component';
import { IFormWithStepperProps } from '../../../_core/form/form.types';
import { PLAN_NAME } from '../../../../lib/types';

interface ISelectCompanyRegisterOption extends IFormWithStepperProps {
  continueWithOption: (_: RegisterCompanyOption) => void;
}

export const SelectCompanyRegisterOption = ({
  continueWithOption,
  onError,
  onSuccess
}: ISelectCompanyRegisterOption) => {
  const { registerCompany, loading: registering } = useRegisterCompany();

  const handleSelectOption = async (option: RegisterCompanyOption) => {
    if (option === RegisterCompanyOption.REGISTER_PERSONAL) {
      try {
        await resetFormMessages({ setSubmitError: onError, setSuccess: onSuccess });

        await registerCompany({ planName: PLAN_NAME.PERSONAL });
        onSuccess('Successfully created individual request account.');
        continueWithOption(option);
      } catch (err) {
        const errMsg = 'Unable to create individual request account.';
        logError(errMsg, (err as Error).message);
        onError(errMsg);
      }
    } else continueWithOption(option);
  };

  return (
    <div className="flex gap-8 flex-col md:flex-row">
      <Button
        className="h-16"
        loading={registering}
        onClick={() => handleSelectOption(RegisterCompanyOption.REGISTER_PERSONAL)}
      >
        Single User Account
      </Button>
      <Button
        className="h-16"
        onClick={() => handleSelectOption(RegisterCompanyOption.REGISTER_FIRM)}
        subtitle="Create a Firm"
      >
        Multiple User Account
      </Button>
      <Button
        className="h-16"
        onClick={() => handleSelectOption(RegisterCompanyOption.JOIN_WITH_CODE)}
        subtitle="Join an existing Firm already set up with Request IQ"
      >
        Join Firm
      </Button>
    </div>
  );
};
