import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { ISendInvitesProps } from '../invite.types';
import { FormInfo } from '../../../_core/form/form-info.component';
import { IDepartment, ILocation, ROLE, ROLE_LABELS } from '../../../../lib/types';
import { ROLE_OPTIONS } from '../../../../_configuration';
import { useMemo } from 'react';
import { isValidEmail } from '../../../../utils/validation.helpers';
import { IPersonalCompanyProps } from '../../../_core/core.types';
import { FormActionsRow } from '../../../_core/form/input/form-actions-row';

interface ISendInvitesFormProps extends IRIFormProps<ISendInvitesProps>, IPersonalCompanyProps {
  departments: IDepartment[];
  isClientEntityInvite: boolean;
  isClientInvite: boolean;
  locations: ILocation[];
}

export const SendInvitesForm = ({
  departments,
  isClientEntityInvite,
  isClientInvite,
  isPersonalCompany,
  locations,
  onBack,
  onSkip,
  onSubmit
}: ISendInvitesFormProps) => {
  const validateForm = (values: ISendInvitesProps) => {
    const errors: FormikErrors<ISendInvitesProps> = {};

    if (!values.emails.length) {
      errors.emails = 'At least one value required';
    }

    const invalidEmails = values.emails.filter((email) => !isValidEmail(email.trim()));
    if (invalidEmails.length) {
      errors.emails = 'Invalid email address: ' + invalidEmails.join(', ');
    }

    if (!isClientInvite && !values.roles?.length) {
      errors.roles = 'At least one value required';
    }

    return errors;
  };

  const options = useMemo(
    () => ({
      departments: departments.map((d) => ({ label: d.name, value: d._id })),
      locations: locations.map((l) => ({ label: l.name, value: l._id }))
    }),
    [departments, locations]
  );

  return (
    <Formik
      initialValues={
        {
          emails: [],
          department: [],
          location: [],
          roles: isClientInvite ? undefined : [{ label: ROLE_LABELS[ROLE.COMPANY_STAFF], value: ROLE.COMPANY_STAFF }]
        } as ISendInvitesProps
      }
      onSubmit={(values) => onSubmit({ ...values, emails: values.emails.filter((email) => email.trim()) })}
      validate={validateForm}
      validateOnBlur={false}
    >
      {(props: FormikProps<ISendInvitesProps>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-6 mt-10">
          <FormInfo
            title={isClientInvite ? 'Add Contacts:' : 'Add Staff:'}
            messages={
              isClientInvite
                ? [
                    isClientEntityInvite
                      ? 'Add some contacts for your client entity below.'
                      : 'Add some clients for your firm below.',
                    'You can also access the contacts list in your settings.'
                  ]
                : [
                    'If you would like to add some staff to your firm you can do so below.',
                    'You can also access the staff list in your settings.'
                  ]
            }
          />
          <FormInputWithLabel
            id="emails"
            name="emails"
            label="Emails"
            placeholder="Add email"
            value={props.values.emails}
            type="tags"
            tagsProps={{ previewLabelPrefix: 'Add email', inputType: 'email' }}
          />
          {!isClientInvite && (
            <FormInputWithLabel
              id="roles"
              name="roles"
              label="Roles"
              value={props.values.roles}
              type="multiselect"
              placeholder="Select Roles"
              multiSelectProps={{ options: ROLE_OPTIONS }}
            />
          )}
          {!isPersonalCompany && (
            <>
              <FormInputWithLabel
                id="location"
                name="location"
                label="Locations"
                value={props.values.roles}
                type="multiselect"
                placeholder="Select Locations"
                multiSelectProps={{ options: options.locations }}
              />
              <FormInputWithLabel
                id="department"
                name="department"
                label="Departments"
                value={props.values.roles}
                type="multiselect"
                placeholder="Select Departments"
                multiSelectProps={{ options: options.departments }}
              />
            </>
          )}
          <FormActionsRow>
            {!!onBack && <FormButton onPress={onBack} text="Back" type="button" variant="secondary" />}
            {!!onSkip && <FormButton onPress={onSkip} text="Skip" type="button" variant="secondary" />}
            <FormButton loading={props.isSubmitting} text="Send" />
          </FormActionsRow>
        </Form>
      )}
    </Formik>
  );
};
