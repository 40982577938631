import { Label, LabelValue } from '../typography';

interface IDetailItemProps {
  label: string;
  value: string;
}

export const DetailItem: React.FC<IDetailItemProps> = ({ label, value }) => (
  <div className="w-full max-w-lg flex xs:flex-col sm:flex-row">
    <Label>{label}</Label>
    <LabelValue>{value}</LabelValue>
  </div>
);
