import { EditDepartmentsForm } from './edit-departments-form.component';
import { IDepartment } from '../../../../lib/types';
import { ISelectedIDProps } from '../../../../lib/query.types';
import { useUpdateInvites } from '../../invite/invite.service';
import { useUpdateMembers } from '../../member/member.service';
import { logError } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';
import { useUpdateEntity } from '../../entities/entity.service';
import { IFormWithStepperProps } from '../../../_core/form/form.types';
import { ICompanyPermissionsEditActionProps } from '../../../../lib/action.types';
import { useUpdateClient } from '../../clients/client.service';
import { useUpdateClientInvite } from '../../client-invite/client-invite.service';

export interface IEditDepartmentsContainerProps extends ICompanyPermissionsEditActionProps {
  departments: IDepartment[];
  initialDepartments: IDepartment[];
}

interface IEditDepartmentsNestedContainerProps extends IEditDepartmentsContainerProps, IFormWithStepperProps {}

export const EditDepartmentsNestedContainer = ({
  departments,
  initialDepartments,
  onError,
  onSuccess,
  targetId,
  targetType,
  ...rest
}: IEditDepartmentsNestedContainerProps) => {
  const { updateClient } = useUpdateClient();
  const { updateClientInvite } = useUpdateClientInvite();
  const { updateEntity } = useUpdateEntity();
  const { updateInvites } = useUpdateInvites();
  const { updateMembers } = useUpdateMembers();

  const handleSubmit = async (props: ISelectedIDProps) => {
    try {
      await resetFormMessages({ setSubmitError: onError, setSuccess: onSuccess });

      if (targetType === 'client') await updateClient({ _id: targetId, department: props.ids });
      else if (targetType === 'client-invite') await updateClientInvite({ _id: targetId, department: props.ids });
      else if (targetType === 'invite') await updateInvites({ ids: [targetId], department: props.ids });
      else if (targetType === 'member') await updateMembers({ ids: [targetId], department: props.ids });
      else if (targetType === 'entity') await updateEntity({ _id: targetId, department: props.ids });
      else {
        onError('Unable to determine selected.');
        return;
      }

      onSuccess('Successfully edited departments.');
    } catch (err) {
      const errMsg = 'Unable to edit departments.';
      logError(errMsg, (err as Error).message);
      onError(errMsg);
    }
  };

  return (
    <EditDepartmentsForm departments={departments} selected={initialDepartments} onSubmit={handleSubmit} {...rest} />
  );
};
