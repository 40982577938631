import { showError } from '../../../../lib/utils';
import { useDeleteDepartment, useRestoreDepartment } from '../../../domains/departments/department.service';
import { DepartmentTable, IDepartmentTableProps } from './department-table.component';

export const DepartmentTableContainer: React.FC<IDepartmentTableProps> = (props) => {
  const { deleteDepartment, loading: deleting } = useDeleteDepartment();
  const { restoreDepartment, loading: restoring } = useRestoreDepartment();

  return (
    <DepartmentTable
      {...props}
      {...{
        deleteDepartment: (props) =>
          deleteDepartment(props).catch((err) => showError('Failed to delete department', err)),
        deleting,
        restoreDepartment: (props) =>
          restoreDepartment(props).catch((err) => showError('Failed to restore department', err)),
        restoring
      }}
    />
  );
};
