import { PropsWithChildren } from 'react';

interface IDetailsBoxProps extends PropsWithChildren {
  endAligned?: boolean;
  stretch?: boolean;
}

export const DetailBox: React.FC<IDetailsBoxProps> = ({ children, endAligned, stretch }) => (
  <div
    className={`border border-gray-400 flex flex-wrap gap-2 p-4 w-full overflow-x-auto ${
      endAligned ? 'justify-end' : ''
    } ${stretch ? ' flex-1 flex-col' : ''}`}
  >
    {children}
  </div>
);
