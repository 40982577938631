import { Button } from '../../../../_core/button/button.component';
import { useDeleteContactsAction } from './contacts-table-delete-action';
import { IContactsTableMultiselectActionProps } from './contacts-table-multiselect-action-button.types';
import { useResendInviteAction } from './contacts-table-resend-invite-action';

interface IContactsTableMultiselectActionButtonProps extends IContactsTableMultiselectActionProps {
  selected: boolean[];
}

export const ContactsTableMultiselectActionButton: React.FC<IContactsTableMultiselectActionButtonProps> = ({
  selected,
  ...actionProps
}) => {
  const deleteContactsAction = useDeleteContactsAction(actionProps);
  const resendInviteAction = useResendInviteAction(actionProps);

  return (
    <Button
      disabled={!selected.some((s) => s)}
      dropdown={{ options: [resendInviteAction, deleteContactsAction] }}
      text="ACTION"
      size="large"
    />
  );
};
