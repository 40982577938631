import { BlockToolConstructorOptions } from '@editorjs/editorjs/types/tools';
import { inputClasses, inputClassesLineHeightStr } from './editor.const';
import { IRequiredBlockData, RequiredBlockTool } from './required-block';
import { ICreateTemplateBlockConfig } from './create-template-block';

export interface ILabelBlockData extends IRequiredBlockData {
  label?: string;
}

export class LabelBlockTool<T extends ILabelBlockData, U extends ICreateTemplateBlockConfig> extends RequiredBlockTool<
  T,
  U
> {
  constructor(props: BlockToolConstructorOptions<T>) {
    super({ ...props, data: { ...props.data, label: props.data.label ?? '' } });
  }

  static get isReadOnlySupported(): boolean {
    return true;
  }

  static get sanitize() {
    // disallow HTML
    return { label: false };
  }

  protected renderWithLabel(): HTMLDivElement {
    super.render();
    if (!this.wrapper) this.wrapper = document.createElement('div');

    if (this.api.readOnly.isEnabled) {
      const questionLabel = document.createElement('span');
      questionLabel.classList.add(
        'label',
        'self-center',
        ...inputClassesLineHeightStr.split(' '),
        'sm:max-w-60',
        'md:max-w-96',
        'w-full'
      );
      questionLabel.textContent = this.data.label ?? '';
      this.wrapper.prepend(questionLabel);
    } else {
      // Render label input
      const labelInput = document.createElement('input');
      labelInput.classList.add('label', ...inputClasses);
      labelInput.placeholder = '< Enter label >';
      labelInput.value = this.data.label ?? '';
      this.wrapper.prepend(labelInput);
    }

    return this.wrapper;
  }

  async save(blockContent: HTMLElement): Promise<T> {
    const label = blockContent.querySelector('.label') as HTMLInputElement | undefined;

    const data = await super.save(blockContent);
    return { ...data, label: label?.value ?? data.label };
  }

  validate({ label }: ILabelBlockData) {
    return !!label;
  }
}
