import { IEntity } from '../../../../lib/types';
import React, { PropsWithChildren } from 'react';
import { TableWithActions } from '../../../_core/table/table-with-actions.component';
import { ITableRowValues } from '../../../_core/table/table.component';
import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';

interface IEntityTableProps extends PropsWithChildren, IAdminProps, IPersonalCompanyProps {
  onSelect: (_index: number, _selected?: boolean) => void;
  onSelectAll: (_selected: boolean) => void;
  selected?: boolean[];
  entities: IEntityTableRowValues[];
  editingDisabled?: boolean;
}

export interface IEntityTableRowValues extends ITableRowValues {
  entity: IEntity;
}

export const EntityTable: React.FC<IEntityTableProps> = ({
  children,
  isAdmin,
  isPersonalCompany,
  onSelect,
  onSelectAll,
  selected,
  entities,
  editingDisabled
}) => {
  const headers = ['Name', 'Status', 'Main Contact'];
  if (!isPersonalCompany) headers.push('Departments', 'Locations');
  if (!editingDisabled) headers.push('');
  if (isAdmin) headers.push('');

  return (
    <>
      <TableWithActions
        selectable
        loading={!entities}
        headers={headers}
        values={entities}
        selected={selected}
        onRowClick={onSelect}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
      >
        {children}
      </TableWithActions>
    </>
  );
};
