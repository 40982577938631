import { useState } from 'react';
import { FormContainer } from '../../../_core/form/form-container.component';
import { ISendInvitesContainerProps, SendInvitesNestedContainer } from './send-invites-form-nested.container';

export const SendInvitesContainer = ({ isClientInvite, isPersonalCompany, entity }: ISendInvitesContainerProps) => {
  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  return (
    <FormContainer
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      title="Send Invites"
    >
      <SendInvitesNestedContainer
        onError={setSubmitError}
        onSuccess={setSuccess}
        isClientInvite={isClientInvite}
        isPersonalCompany={isPersonalCompany}
        entity={entity}
      />
    </FormContainer>
  );
};
