import { useCallback, useState } from 'react';
import { CreateEntityContainer } from './create-entity-form.container';
import { FormStepper } from '../../../_core/form/stepper/form-stepper.component';
import { SendInvitesNestedContainer } from '../../invite/send/send-invites-form-nested.container';
import { IFormFinishProps, IFormWithStepperProps } from '../../../_core/form/form.types';
import { EditLocationsNestedContainer } from '../../locations/edit/edit-locations-form-nested.container';
import { EditDepartmentsNestedContainer } from '../../departments/edit/edit-departments-form-nested.container';
import { useCompanyDepartments } from '../../departments/department.service';
import { useCompanyLocations } from '../../locations/location.service';
import { Loader } from '../../../_core/loader.component';
import { IEntity } from '../../../../lib/types';
import { ICloseableDialogProps, IPersonalCompanyProps } from '../../../_core/core.types';
import { sleep } from '../../../../lib/utils';
import { Button } from '../../../_core/button/button.component';

interface ICreateEntitySteps
  extends IFormWithStepperProps,
    IFormFinishProps,
    IPersonalCompanyProps,
    ICloseableDialogProps {
  contentRef: React.RefObject<HTMLDivElement>;
}

export const CreateEntitySteps = ({ contentRef, isPersonalCompany, onSuccess, onError, close }: ICreateEntitySteps) => {
  const { departments } = useCompanyDepartments();
  const { locations } = useCompanyLocations();

  const [activeStep, setActiveStep] = useState(0);
  const [entity, setEntity] = useState<IEntity | null>(null);

  const handleSuccess = useCallback(
    (msg: string | null, nextStep: number) => {
      onError(null);
      onSuccess(msg);
      setActiveStep(nextStep);
      sleep(500).then(() => onSuccess(null));
    },
    [onError, onSuccess, setActiveStep]
  );

  const steps = ['Entity Details'];
  if (!isPersonalCompany) steps.push('Locations', 'Departments');
  steps.push('Contacts');

  if (
    (activeStep > 0 && !entity) ||
    (isPersonalCompany && ((activeStep === 1 && !locations) || (activeStep === 2 && !departments)))
  )
    return <Loader />;

  /*
   * Steps 1-3 are all actions on an already created entity.
   * So once we are there then we should not be able to backtrack beyond that range.
   */
  return (
    <>
      <FormStepper containerRef={contentRef} steps={steps} activeStep={activeStep} />
      {activeStep === 0 && (
        <CreateEntityContainer
          onError={onError}
          onSuccess={(msg) => handleSuccess(msg, isPersonalCompany ? 3 : 1)}
          setEntity={setEntity}
        />
      )}
      {activeStep === 1 && !!entity && (
        <EditLocationsNestedContainer
          targetId={entity._id}
          targetType="entity"
          initialLocations={entity.location}
          locations={locations ?? []}
          onError={onError}
          onSkip={() => setActiveStep(2)}
          onSuccess={(msg) => handleSuccess(msg, 2)}
        />
      )}
      {activeStep === 2 && !!entity && (
        <EditDepartmentsNestedContainer
          targetId={entity._id}
          targetType="entity"
          initialDepartments={entity.department}
          departments={departments ?? []}
          onBack={() => setActiveStep(1)}
          onError={onError}
          onSkip={() => setActiveStep(3)}
          onSuccess={(msg) => handleSuccess(msg, 3)}
        />
      )}
      {activeStep === 3 && !!entity && (
        <SendInvitesNestedContainer
          isClientInvite
          isPersonalCompany={isPersonalCompany}
          entity={entity}
          onBack={isPersonalCompany ? undefined : () => setActiveStep(2)}
          onError={onError}
          onSkip={() => {
            onSuccess('Completed entity setup!');
            setActiveStep(4);
          }}
          onSuccess={(msg) => handleSuccess(msg, 4)}
        />
      )}
      {activeStep > 3 && (
        <div className="min-h-48 flex items-center justify-center">
          <Button onClick={close} size="large">
            Done
          </Button>
        </div>
      )}
    </>
  );
};
