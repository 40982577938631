import { gql } from '@apollo/client';

export const ASSET_FRAGMENT = gql`
  fragment AssetDetails on Asset {
    _id
    file
    name
    url
    deletedAt
  }
`;

export const REMOVE_ASSET = gql`
  mutation RemoveAsset($_id: ID!) {
    removeAsset(_id: $_id) {
      success
    }
  }
`;

export const REMOVE_ASSET_WITH_TOKEN = gql`
  mutation RemoveAssetWithToken($_id: ID!, $requestId: ID!, $requestToken: String!) {
    removeAssetWithToken(_id: $_id, requestId: $requestId, requestToken: $requestToken) {
      success
    }
  }
`;
