import { gql } from '@apollo/client';
import { DEPARTMENT_FRAGMENT } from '../departments/department.queries';
import { LOCATION_FRAGMENT } from '../locations/location.queries';
import { USER_FRAGMENT } from '../users/user.queries';
import { COMPANY_FRAGMENT } from '../companies/company.queries';

export const CLIENT_FRAGMENT = gql`
  fragment ClientDetails on Client {
    _id
    user {
      ...UserDetails
    }
    deletedAt
    department {
      ...DepartmentDetails
    }
    location {
      ...LocationDetails
    }
    company {
      ...CompanyDetails
    }
  }

  ${COMPANY_FRAGMENT}
  ${DEPARTMENT_FRAGMENT}
  ${LOCATION_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const GET_CLIENTS = gql`
  query GetClients($includeArchived: Boolean) {
    clients(includeArchived: $includeArchived) {
      ...ClientDetails
    }
  }

  ${CLIENT_FRAGMENT}
`;

export const GET_CLIENT = gql`
  query Client($_id: ID!) {
    client(_id: $_id) {
      ...ClientDetails
    }
  }

  ${CLIENT_FRAGMENT}
`;

export const DELETE_CLIENT = gql`
  mutation DeleteClient($_id: ID!) {
    deleteClient(_id: $_id) {
      ...ClientDetails
    }
  }

  ${CLIENT_FRAGMENT}
`;

export const RESTORE_CLIENT = gql`
  mutation RestoreClient($_id: ID!) {
    restoreClient(_id: $_id) {
      ...ClientDetails
    }
  }

  ${CLIENT_FRAGMENT}
`;

export const UPDATE_CLIENT = gql`
  mutation UpdateClient($department: [ID!], $_id: ID!, $location: [ID!]) {
    updateClient(department: $department, _id: $_id, location: $location) {
      ...ClientDetails
    }
  }

  ${CLIENT_FRAGMENT}
`;
