import EditorJs from '@editorjs/editorjs';
import { MutableRefObject, useCallback, useEffect } from 'react';
import { useUpdateRequest, useUpdateRequestAsClient } from '../../../domains/request/request.service';
import { IRequest, IRequestBlock } from '../../../../lib/types';
import { IEditorRefProps } from '../../../_pages/FormBuilderPage/form-editor.types';
import { showError } from '../../../../lib/utils';
import { createRoot } from 'react-dom/client';
import { Button } from '../../../_core/button/button.component';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

// Util hooks
export const useFocusedBlockTracking = ({ editorRef, editorblock }: IEditorRefProps) => {
  // TODO: Add focused block tracking for possible side panel

  useEffect(() => {
    const editorElement = document.getElementById(editorblock);
    const onClick = (e: MouseEvent) => {
      // Prevent bubble up of click event to parent section blocks
      e.stopImmediatePropagation();

      if (editorElement && editorRef.current && e.type === 'click') {
        const blockIndex = editorRef.current.blocks.getCurrentBlockIndex();
        if (blockIndex >= 0) {
          const block = editorRef.current.blocks.getBlockByIndex(blockIndex);
          document.querySelector('.focused-block')?.classList.remove('focused-block');
          editorElement.querySelector(`div[data-id="${block?.id}"]`)?.classList.add('focused-block');
          console.log(editorblock, 'Selected block index', blockIndex);
        }
      }
    };

    if (editorElement) {
      editorElement.addEventListener('click', onClick);
    }

    return () => {
      editorElement?.removeEventListener('click', onClick);
    };
  }, [editorRef, editorblock]);
};

export const useSaveEditor = ({
  ref,
  request,
  token
}: {
  ref?: MutableRefObject<EditorJs | undefined>;
  request: IRequest;
  token?: string;
}) => {
  const { updateRequestAsClient, loading: updatingAsClient } = useUpdateRequestAsClient({
    requestId: request._id,
    token: token ?? ''
  });
  const { updateRequest, loading: updating } = useUpdateRequest({ _id: request._id });

  const onSave = useCallback(async () => {
    try {
      const data = await ref?.current?.save(true);
      if (data && request) {
        const blocks = data.blocks as IRequestBlock[];

        if (token) updateRequestAsClient(blocks);
        else updateRequest({ blocks });
      }

      return data;
    } catch (err) {
      showError('Failed to save request', err as Error);
    }
  }, [ref, request, token, updateRequest, updateRequestAsClient]);

  return { onSave, saving: updating || updatingAsClient };
};

// Util functions
export const renderWithBlockFocusWrapper = (content: HTMLDivElement): HTMLDivElement => {
  return content;
};

export interface IRenderOpenToggleParams {
  additionalClass?: string;
  onOpenToggle?: () => void;
  open: boolean;
  openToggleId: string;
  parent?: HTMLDivElement;
  replaceId?: string;
}

export const renderOpenToggle = ({
  additionalClass,
  onOpenToggle,
  open,
  openToggleId,
  parent,
  replaceId
}: IRenderOpenToggleParams) => {
  const openToggle = document.createElement('div');
  openToggle.id = openToggleId;
  if (additionalClass) openToggle.classList.add(additionalClass);

  const openRoot = createRoot(openToggle);
  openRoot.render(
    <Button
      icon={
        open ? (
          <ChevronDownIcon height={20} width={20} className="m-auto" />
        ) : (
          <ChevronRightIcon height={20} width={20} className="m-auto" />
        )
      }
      className="ce-toolbar__button"
      onClick={onOpenToggle ? () => onOpenToggle() : undefined}
      type="button"
      variant="outline"
    />
  );

  if (replaceId) {
    const wrapper = document.getElementById(replaceId);
    wrapper?.querySelector('#' + openToggleId)?.remove();
    wrapper?.prepend(openToggle);
  } else parent?.prepend(openToggle);
};
