import { logError } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';
import { IFormWithStepperProps } from '../../../_core/form/form.types';
import { Loader } from '../../../_core/loader.component';
import {
  useCompanyRequestTypes,
  useCreateRequestTypes,
  useDefaultRequestTypes,
  useDeleteRequestTypes,
  useRestoreRequestType,
  useSelectedRequestTypes
} from '../../request-type/request-type.service';
import { useCurrCompany, useEditCompanyRequestTypes } from '../company.service';
import { IEditRequestTypesProps } from '../company.types';
import { EditRequestTypesForm } from './edit-request-types-form.component';

export const EditRequestTypesNestedContainer = ({ onError, onSuccess, ...rest }: IFormWithStepperProps) => {
  const { company } = useCurrCompany();
  const { companyRequestTypes, loading: loadingCompany, called: calledCompany } = useCompanyRequestTypes();
  const { defaultRequestTypes, loading: loadingDefault, called: calledDefault } = useDefaultRequestTypes();
  const { requestTypes, loading, called } = useSelectedRequestTypes();
  const { createRequestTypes } = useCreateRequestTypes();
  const { deleteRequestTypes } = useDeleteRequestTypes();
  const { editRequestTypes } = useEditCompanyRequestTypes();
  const { restoreRequestType } = useRestoreRequestType();

  const handleSubmit = async (props: IEditRequestTypesProps) => {
    await resetFormMessages({ setSubmitError: onError, setSuccess: onSuccess });

    const deletedTypes: string[] = [];
    const restoredCompanyTypes: string[] = [];
    const requestTypeIDs: string[] = [];
    const newTypes: string[] = [];

    // Separate new types from previously existing
    props.requestTypes.forEach(({ isNew, value }) => {
      if (isNew) newTypes.push(value);
      else requestTypeIDs.push(value);
    });

    // Determine which company-owned pre-existing types are being selected (which determines whether to delete previous actives, or restore previous inactives)
    companyRequestTypes?.forEach(({ _id, deletedAt }) => {
      const selectedRequestType = requestTypeIDs?.find((id) => _id === id);
      if (!deletedAt && !selectedRequestType) deletedTypes.push(_id);
      if (deletedAt && selectedRequestType) restoredCompanyTypes.push(_id);
    });

    try {
      if (newTypes.length) await createRequestTypes(newTypes);
    } catch (err) {
      const errMsg = 'Unable to create new request types.';
      logError(errMsg, (err as Error).message);
      onError(errMsg);
    }

    try {
      if (restoredCompanyTypes.length)
        await Promise.all(restoredCompanyTypes.map((_id) => restoreRequestType({ _id })));
    } catch (err) {
      const errMsg = 'Unable to reactivate request types.';
      logError(errMsg, (err as Error).message);
      onError(errMsg);
    }

    try {
      if (deletedTypes.length) await deleteRequestTypes(deletedTypes);
    } catch (err) {
      const errMsg = 'Unable to unselect request types.';
      logError(errMsg, (err as Error).message);
      onError(errMsg);
    }

    try {
      await editRequestTypes(requestTypeIDs);
    } catch (err) {
      const errMsg = 'Unable to set selected request types.';
      logError(errMsg, (err as Error).message);
      onError(errMsg);
    }

    onSuccess('Successfully edited request types');
  };

  if (
    loading ||
    !called ||
    !requestTypes ||
    loadingDefault ||
    !calledDefault ||
    !defaultRequestTypes ||
    loadingCompany ||
    !calledCompany ||
    !companyRequestTypes ||
    !company
  )
    return <Loader />;
  return (
    <EditRequestTypesForm
      requestTypes={[...defaultRequestTypes, ...companyRequestTypes]}
      selectedRequestTypeIds={requestTypes.map(({ _id }) => _id)}
      onSubmit={handleSubmit}
      {...rest}
    />
  );
};
