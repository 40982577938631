import { IDepartment, Identifiable } from '../../../../lib/types';
import React, { useState } from 'react';
import { RIDialog } from '../../../_core/dialog/dialog.component';
import { TableWithActions } from '../../../_core/table/table-with-actions.component';
import { CreateDepartmentsContainer } from '../../../domains/departments/create/create-departments-form.container';
import { Button } from '../../../_core/button/button.component';
import { activeStatus } from '../../../../utils/wording.helpers';
import { UpdateDepartmentContainer } from '../../../domains/departments/update/update-department-form.container';
import { ITableRowValues } from '../../../_core/table/table.component';
import { IAdminProps } from '../../../_core/core.types';

export interface IDepartmentTableProps extends IAdminProps {
  departments: IDepartment[];
}

interface IDepartmentTableActionProps {
  deleteDepartment: (_: Identifiable) => void;
  deleting: boolean;
  restoreDepartment: (_: Identifiable) => void;
  restoring: boolean;
}

export const DepartmentTable: React.FC<IDepartmentTableProps & IDepartmentTableActionProps> = ({
  departments,
  deleteDepartment,
  deleting,
  isAdmin,
  restoreDepartment,
  restoring
}) => {
  const [createDepartments, setCreateDepartments] = useState(false);
  const [updateDepartment, setUpdateDepartment] = useState<IDepartment | null>(null);

  const headers = ['Name', 'Status'];
  if (isAdmin) headers.push('');

  return (
    <>
      <TableWithActions
        headers={headers}
        values={
          departments.map(({ _id, name, deletedAt }, i) => {
            const row: ITableRowValues = { values: [{ children: name }, { children: activeStatus(!deletedAt) }] };

            if (isAdmin)
              row.values.push({
                children: (
                  <Button
                    loading={deleting || restoring}
                    size="large"
                    text="ACTION"
                    dropdown={{
                      options: [
                        deletedAt
                          ? { text: 'Reactivate', onClick: () => restoreDepartment({ _id }) }
                          : { text: 'Delete', onClick: () => deleteDepartment({ _id }) },
                        { text: 'Edit', onClick: () => setUpdateDepartment(departments[i]) }
                      ],
                      up: departments.length > 1 && i === departments.length - 1
                    }}
                  />
                )
              });

            return row;
          }) ?? []
        }
      >
        {isAdmin && <Button onClick={() => setCreateDepartments(true)} text="Add Departments" size="large" />}
      </TableWithActions>
      {isAdmin && (
        <>
          <RIDialog open={createDepartments} setOpen={setCreateDepartments}>
            <CreateDepartmentsContainer />
          </RIDialog>
          {!!updateDepartment && (
            <RIDialog open={!!updateDepartment} setOpen={(o) => setUpdateDepartment((l) => (o ? l : null))}>
              <UpdateDepartmentContainer {...updateDepartment} />
            </RIDialog>
          )}
        </>
      )}
    </>
  );
};
