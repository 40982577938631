import { PropsWithChildren } from 'react';

interface ITooltipProps extends PropsWithChildren {
  text: string;
}

// NOTE: No need for a typography usecase here yet, because text is so basic and custom and could be controlled with tailwind font customizations
export const Tooltip = ({ children, text }: ITooltipProps) => (
  <span className="group relative flex items-center">
    {children}
    <span className="absolute right-10 scale-0 rounded bg-gray-800 p-2 transition-all text-xs text-white group-hover:scale-100">
      {text}
    </span>
  </span>
);
