import { Field, FieldConfig } from 'formik';
import { useFormFieldMeta } from '../form-field-meta';
import { FormTagsInput, IFormTagsInputProps } from './form-tags-input.component';
import { FormMultiSelectInput, IFormMultiSelectInputProps } from './form-multiselect-input.component';
import { FormSelectInput, IFormSelectInputProps } from './form-select-input.component';

export interface IFormInputProps extends FieldConfig {
  disabled?: boolean;
  id?: string;
  placeholder?: string;
  type?: 'checkbox' | 'date' | 'email' | 'multiselect' | 'select' | 'tags' | 'text';

  // Input specific props
  multiSelectProps?: IFormMultiSelectInputProps;
  selectProps?: IFormSelectInputProps;
  tagsProps?: IFormTagsInputProps;
}

export const FormInput = ({ multiSelectProps, selectProps, tagsProps, type = 'text', ...props }: IFormInputProps) => {
  const { hasError, error } = useFormFieldMeta(props.name);

  const fieldStateClasses = hasError
    ? 'ring-red-300 placeholder:text-red-400'
    : 'ring-gray-300 placeholder:text-gray-400';

  let typeClasses = '';
  switch (type) {
    case 'checkbox':
      typeClasses = 'text-primary';
      break;
    default:
      typeClasses = 'w-full py-1.5 text-gray-900 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600';
  }

  const errorMessage = hasError ? <span className="my-1.5 text-red-500 text-sm">{error}</span> : undefined;
  const inputClasses = `${typeClasses} block rounded-md border-0 shadow-sm ring-1 sm:text-sm sm:leading-6 disabled:text-gray-400 ${fieldStateClasses}`;

  if (type === 'tags')
    return <FormTagsInput {...props} {...tagsProps} inputClasses={inputClasses} error={errorMessage} />;
  else if (type === 'multiselect')
    return <FormMultiSelectInput {...props} {...multiSelectProps} inputClasses={inputClasses} error={errorMessage} />;
  else if (type === 'select')
    return <FormSelectInput {...props} {...selectProps} inputClasses={inputClasses} error={errorMessage} />;

  const isCheckbox = type === 'checkbox';
  return (
    <div className={isCheckbox ? undefined : 'w-2/3'}>
      <Field
        fid={props.id}
        type={type}
        checked={isCheckbox ? !!props.value : undefined}
        {...props}
        className={inputClasses}
      />
      {errorMessage}
    </div>
  );
};
