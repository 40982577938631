import { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ITemplateBaseDetails, Identifiable, TEMPLATE_TYPE } from '../../../lib/types';
import { CREATE_TEMPLATE, DELETE_TEMPLATE, GET_TEMPLATE, GET_TEMPLATES, UPDATE_TEMPLATE } from './template.queries';
import { ICreateTemplateProps, IGetTemplatesParams, ITemplateResults, IUpdateTemplateProps } from './template.types';
import toast from 'react-hot-toast';
import { showError } from '../../../lib/utils';
import { updateTemplateInCache } from './template.utils';

export const useTemplate = (_id: string) => {
  const { data, ...rest } = useQuery<ITemplateResults, Identifiable>(GET_TEMPLATE, {
    fetchPolicy: 'cache-and-network',
    variables: { _id }
  });

  return { ...rest, ...data };
};

export const useTemplates = (type: TEMPLATE_TYPE) => {
  const { data, ...rest } = useQuery<ITemplateResults, IGetTemplatesParams>(GET_TEMPLATES, {
    fetchPolicy: 'cache-and-network',
    variables: { type }
  });

  return { ...rest, ...data };
};

export const useCreateTemplate = (type: TEMPLATE_TYPE) => {
  const [mutation, rest] = useMutation<{ createTemplate: ITemplateBaseDetails }>(CREATE_TEMPLATE, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) updateTemplateInCache({ cache, template: data.createTemplate, type });
    }
  });

  const createTemplate = useCallback(
    (variables: ICreateTemplateProps) => mutation({ variables: { ...variables, type } }),
    [mutation, type]
  );

  return { createTemplate, ...rest };
};

export const useDeleteTemplate = (type: TEMPLATE_TYPE) => {
  const [mutation, rest] = useMutation<{ deleteTemplate: ITemplateBaseDetails }>(DELETE_TEMPLATE, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) updateTemplateInCache({ cache, template: data.deleteTemplate, type });
    },
    onCompleted: () => toast.success('Template deleted successfully!'),
    onError: (error) => showError('Failed to delete template', error)
  });

  const updateTemplate = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { updateTemplate, ...rest };
};

export const useUpdateTemplate = (type: TEMPLATE_TYPE) => {
  const [mutation, rest] = useMutation<{ updateTemplate: ITemplateBaseDetails }>(UPDATE_TEMPLATE, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) updateTemplateInCache({ cache, template: data.updateTemplate, type });
    },
    onCompleted: () => toast.success('Template updated successfully!'),
    onError: (error) => showError('Failed to update template', error)
  });

  const updateTemplate = useCallback((variables: IUpdateTemplateProps) => mutation({ variables }), [mutation]);

  return { updateTemplate, ...rest };
};
