import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useReactiveVar } from '@apollo/client';
import axios, { AxiosProgressEvent } from 'axios';

import { companyIdVar } from '../companies/company.service';
import { BASE_API_URL } from '../../../lib/utils';
import { tokenVar } from '../../../providers/graphql.provider';
import { IAsset, Identifiable, IRequestTokenParams } from '../../../lib/types';
import { REMOVE_ASSET, REMOVE_ASSET_WITH_TOKEN } from './asset.queries';
import { ISuccessResponse } from '../../../lib/query.types';
import { UploadItemData } from '../../_editor/upload/upload.types';
import { uniqueId } from 'lodash';

interface IDownloadAssetParams extends IRequestTokenParams {
  Authorization: string | null;
  fileId: string;
  onDownloadProgress: (progressEvent: AxiosProgressEvent) => void;
}

const downloadAsset = async ({ Authorization, fileId, onDownloadProgress, requestToken }: IDownloadAssetParams) => {
  if (!requestToken && !Authorization) return Promise.reject('Unauthorized.');

  return axios<Blob>({
    baseURL: BASE_API_URL,
    method: 'GET',
    url: '/api/asset/' + fileId,
    headers: requestToken ? undefined : { Authorization },
    params: requestToken ? { requestToken } : undefined,
    responseType: 'blob',
    onDownloadProgress
  });
};

export const useDownloadAsset = ({ fileId }: UploadItemData, requestToken?: string) => {
  const Authorization = useReactiveVar(tokenVar);

  const handleDownloadAsset = useCallback(
    async (fileName: string) => {
      if (!requestToken && !Authorization) return Promise.reject('Unauthorized.');

      const baseMessage = 'Downloading ' + fileName;
      const toastId = 'download-' + fileName;
      toast(baseMessage, { id: toastId });
      return downloadAsset({
        Authorization,
        fileId,
        onDownloadProgress: (progressEvent) => {
          if (progressEvent.progress && progressEvent)
            toast.success(
              progressEvent.progress === 1
                ? fileName + ' finished downloading!'
                : baseMessage + ' ' + Math.round(progressEvent.progress * 100) + '%',
              {
                id: toastId
              }
            );
        },
        requestToken
      });
    },
    [Authorization, fileId, requestToken]
  );

  return { downloadAsset: handleDownloadAsset };
};

export const useDownloadAllAssets = (requestToken?: string) => {
  const Authorization = useReactiveVar(tokenVar);

  const handleDownloadAssets = useCallback(
    async ({ items }: { items: UploadItemData[] }) => {
      if (!requestToken && !Authorization) return Promise.reject('Unauthorized.');

      const baseMessage = `Downloading ${items.length} files`;
      const toastId = 'download-all-' + uniqueId();
      toast(baseMessage, { id: toastId });

      let finished = 0;
      const onDownloadProgress = (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.progress === 1) {
          finished += 1;
          toast.success(
            finished === items.length
              ? `Finished downloading ${items.length} files`
              : baseMessage + ' ' + Math.round((finished / items.length) * 100) + '%',
            {
              id: toastId
            }
          );
        }
      };

      const results = await Promise.all(
        items.map(({ fileId }) => downloadAsset({ Authorization, fileId, onDownloadProgress, requestToken }))
      );

      return results;
    },
    [Authorization, requestToken]
  );

  return { downloadAssets: handleDownloadAssets };
};

export const useRemoveAsset = (requestId: string, requestToken?: string) => {
  const [mutation, rest] = useMutation<{ removeAsset: ISuccessResponse }, Identifiable>(REMOVE_ASSET, {
    context: { serializationKey: 'MUTATION', tracked: true }
    // TODO: Update cache when we start reading assets from cache
  });

  const [mutationWithToken, restWithToken] = useMutation<
    { removeAssetWithToken: ISuccessResponse },
    Identifiable & IRequestTokenParams & { requestId: string }
  >(REMOVE_ASSET_WITH_TOKEN, {
    context: { serializationKey: 'MUTATION', tracked: true }
    // TODO: Update cache when we start reading assets from cache
  });

  const removeAsset = useCallback(
    (variables: Identifiable) =>
      requestToken
        ? mutationWithToken({ variables: { ...variables, requestToken, requestId } })
        : mutation({ variables }),
    [mutation, mutationWithToken, requestId, requestToken]
  );

  return { removeAsset, ...(requestToken ? restWithToken : rest) };
};

export const useUploadAsset = (requestToken?: string) => {
  const companyId = useReactiveVar(companyIdVar);
  const Authorization = useReactiveVar(tokenVar);

  const uploadAsset = useCallback(
    async (file: File) => {
      if (!requestToken && (!Authorization || !companyId)) return Promise.reject('Unauthorized.');

      const formData = new FormData();
      formData.append('file', file);
      if (requestToken) formData.append('requestToken', requestToken);
      else if (companyId) formData.append('companyId', companyId);

      const baseMessage = 'Uploading ' + file.name;
      toast(baseMessage, { id: 'upload-' + file.name });
      return axios<{ asset: IAsset }>({
        baseURL: BASE_API_URL,
        method: 'POST',
        url: '/api/asset',
        data: formData,
        headers: requestToken ? undefined : { Authorization },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.progress)
            toast.success(
              progressEvent.progress === 1
                ? file.name + ' finished uploading!'
                : baseMessage + ' ' + Math.round(progressEvent.progress * 100) + '%',
              {
                id: 'upload-' + file.name
              }
            );
        }
      });
    },
    [Authorization, companyId, requestToken]
  );

  return { uploadAsset };
};
