import { IApolloCacheParams } from '../../../lib/cache.types';
import { IArchivableProps } from '../../../lib/query.types';
import { mergeCacheLists } from '../../../lib/utils';
import { GET_ENTITIES } from './entity.queries';
import { IEntitiesResults } from './entity.types';

export const updateEntityCache = ({ cache, entities }: IApolloCacheParams & IEntitiesResults) => {
  const currMembers = cache.readQuery<IEntitiesResults, IArchivableProps>({
    query: GET_ENTITIES,
    variables: { includeArchived: true }
  })?.entities;

  if (currMembers)
    cache.writeQuery<IEntitiesResults, IArchivableProps>({
      query: GET_ENTITIES,
      data: { entities: mergeCacheLists(currMembers, entities) },
      variables: { includeArchived: true }
    });
};
