import { RefObject, useRef } from 'react';
import { FormStep } from './form-step.component';
import { useHasOverflow } from '../../utils/screen';

interface IFormStepperProps {
  containerRef: RefObject<HTMLDivElement>;
  steps: string[];
  activeStep: number;
}

export const FormStepper = ({ containerRef, steps, activeStep }: IFormStepperProps) => {
  const ref = useRef<HTMLOListElement>(null);
  const { initialOverflowDetected } = useHasOverflow({ ref, parentRef: containerRef });

  return (
    <ol
      ref={ref}
      className="mx-auto items-center w-full space-y-4 sm:flex sm:space-x-8 sm:space-y-0 rtl:space-x-reverse"
    >
      <div className="mx-auto flex gap-8">
        {steps.map((step, index) => (
          <FormStep
            key={'step-' + step}
            label={step}
            index={index + 1}
            small={initialOverflowDetected}
            state={index === activeStep ? 'active' : index < activeStep ? 'completed' : 'unvisited'}
          />
        ))}
      </div>
    </ol>
  );
};
