import { TableCell } from './table-cell.component';
import { TableCheckbox } from './table-checkbox.component';
import { ITableRowProps } from './table.types';

export const TableHeader = ({ onSelect, selected, selectable, values }: ITableRowProps) => {
  return (
    <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
      <tr>
        {selectable && <TableCheckbox checked={selected} onChange={onSelect} />}
        {values.map(({ children, key }, i) => (
          <TableCell header key={key ?? `table-header-${i}-` + children?.toLocaleString()}>
            {children}
          </TableCell>
        ))}
      </tr>
    </thead>
  );
};
