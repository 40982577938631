import { CreateEntityForm, ICreateEntityFormValues } from './create-entity-form.component';
import { IFormWithStepperProps } from '../../../_core/form/form.types';
import { logError } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';
import { useCreateEntity } from '../entity.service';
import { IEntity } from '../../../../lib/types';

interface ICreateEntityContainerProps extends IFormWithStepperProps {
  setEntity: (_: IEntity) => void;
}

export const CreateEntityContainer = ({ onError, onSuccess, setEntity }: ICreateEntityContainerProps) => {
  const { createEntity } = useCreateEntity();

  const handleCreate = async (props: ICreateEntityFormValues) => {
    try {
      await resetFormMessages({ setSubmitError: onError, setSuccess: onSuccess });

      const entity = await createEntity(props);
      if (entity.data) setEntity(entity.data?.createEntity);
      onSuccess('Successfully created firm');
    } catch (err) {
      const errMsg = 'Unable to create firm.';
      logError(errMsg, (err as Error).message);
      onError(errMsg);
    }
  };

  return <CreateEntityForm onSubmit={handleCreate} />;
};
