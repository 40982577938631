import { useState } from 'react';
import { useUpdateUser } from '../user.service';
import { UpdateUserForm } from './update-user-form.component';
import { FormContainer } from '../../../_core/form/form-container.component';
import { IUpdateUserParams } from '../user.types';
import { logError } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';

interface IUpdateUserContainerProps {
  name?: string | null;
}

export const UpdateUserContainer = ({ name }: IUpdateUserContainerProps) => {
  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const { updateUser } = useUpdateUser();

  const handleUpdate = async (props: IUpdateUserParams) => {
    try {
      await resetFormMessages({ setSubmitError, setSuccess });

      await updateUser(props);
      setSuccess('Successfully updated user');
    } catch (err) {
      const errMsg = 'Unable to update user.';
      logError(errMsg, (err as Error).message);
      setSubmitError(errMsg);
    }
  };

  return (
    <FormContainer
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      title="Update User"
    >
      <UpdateUserForm name={name} onSubmit={handleUpdate} />
    </FormContainer>
  );
};
