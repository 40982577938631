import { HighlightText } from '../typography';

interface IFormInfoProps {
  messages?: string[];
  title?: string;
}

export const FormInfo = ({ messages, title }: IFormInfoProps) => {
  return (
    <div>
      {!!title && <h4 className="text-lg font-bold text-secondary mt-8 mb-2">{title}</h4>}
      {messages?.map((message, index) => (
        <div key={message + index} className="ml-4">
          <HighlightText>{message}</HighlightText>
        </div>
      ))}
    </div>
  );
};
