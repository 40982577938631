import { useCallback } from 'react';
import { TrashIcon } from '@heroicons/react/20/solid';
import { showError } from '../../../lib/utils';
import { Button } from '../../_core/button/button.component';
import { useRemoveAsset } from '../../domains/assets/asset.service';
import { EditorApolloProvider } from '../_core/editor-apollo-provider';
import { OutputData } from '@editorjs/editorjs';
import { IRequestTokenParams } from '../../../lib/types';

interface IDeleteUploadButton extends IRequestTokenParams {
  assetId: string;
  onDeleteStart: () => void;
  onSave: () => Promise<OutputData | undefined>;
  requestId: string;
}

const DeleteUploadButton = ({ assetId, onDeleteStart, onSave, requestId, requestToken }: IDeleteUploadButton) => {
  const { removeAsset } = useRemoveAsset(requestId, requestToken);

  const handleDelete = useCallback(() => {
    removeAsset({ _id: assetId })
      .then(({ data }) => {
        if (data && ('removeAsset' in data ? data?.removeAsset.success : data.removeAssetWithToken.success)) {
          onDeleteStart();
          onSave();
        }
      })
      .catch((err) => showError('Failed to delete asset.', err));
  }, [assetId, onDeleteStart, onSave, removeAsset]);

  return <Button hideEndMargin icon={<TrashIcon height={16} width={16} />} variant="primary" onClick={handleDelete} />;
};

export const DeleteUploadButtonContainer = (props: IDeleteUploadButton) => {
  return (
    <EditorApolloProvider>
      <DeleteUploadButton {...props} />
    </EditorApolloProvider>
  );
};
