import { gql } from '@apollo/client';
import { COMPANY_FRAGMENT } from '../companies/company.queries';

export const REQUEST_TYPE_FRAGMENT = gql`
  fragment RequestTypeDetails on RequestType {
    _id
    company {
      ...CompanyDetails
    }
    deletedAt
    type
  }

  ${COMPANY_FRAGMENT}
`;

export const GET_COMPANY_REQUEST_TYPES = gql`
  query GetCompanyRequestTypes {
    companyRequestTypes {
      ...RequestTypeDetails
    }
  }

  ${REQUEST_TYPE_FRAGMENT}
`;

export const GET_DEFAULT_REQUEST_TYPES = gql`
  query GetDefaultRequestTypes {
    defaultRequestTypes {
      ...RequestTypeDetails
    }
  }

  ${REQUEST_TYPE_FRAGMENT}
`;

export const GET_SELECTED_REQUEST_TYPES = gql`
  query GetSelectedRequestTypes {
    requestTypes {
      ...RequestTypeDetails
    }
  }

  ${REQUEST_TYPE_FRAGMENT}
`;

export const CREATE_REQUEST_TYPES = gql`
  mutation CreateRequestTypes($types: [String!]!) {
    createRequestTypes(types: $types) {
      ...RequestTypeDetails
    }
  }

  ${REQUEST_TYPE_FRAGMENT}
`;

export const DELETE_REQUEST_TYPES = gql`
  mutation DeleteRequestTypes($requestTypeIDs: [ID!]!) {
    deleteRequestTypes(requestTypeIDs: $requestTypeIDs) {
      ...RequestTypeDetails
    }
  }

  ${REQUEST_TYPE_FRAGMENT}
`;

export const RESTORE_REQUEST_TYPE = gql`
  mutation RestoreRequestType($_id: ID!) {
    restoreRequestType(_id: $_id) {
      ...RequestTypeDetails
    }
  }

  ${REQUEST_TYPE_FRAGMENT}
`;

export const UPDATE_REQUEST_TYPE = gql`
  mutation UpdateRequestType($_id: ID!, $type: String!) {
    updateRequestType(_id: $_id, type: $type) {
      ...RequestTypeDetails
    }
  }

  ${REQUEST_TYPE_FRAGMENT}
`;
