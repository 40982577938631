import { EditLocationsForm } from './edit-locations-form.component';
import { ILocation } from '../../../../lib/types';
import { ISelectedIDProps } from '../../../../lib/query.types';
import { useUpdateInvites } from '../../invite/invite.service';
import { useUpdateMembers } from '../../member/member.service';
import { logError } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';
import { useUpdateEntity } from '../../entities/entity.service';
import { IFormWithStepperProps } from '../../../_core/form/form.types';
import { ICompanyPermissionsEditActionProps } from '../../../../lib/action.types';
import { useUpdateClient } from '../../clients/client.service';
import { useUpdateClientInvite } from '../../client-invite/client-invite.service';

export interface IEditLocationsContainerProps extends ICompanyPermissionsEditActionProps {
  initialLocations: ILocation[];
  locations: ILocation[];
}

interface IEditLocationsNestedContainerProps extends IEditLocationsContainerProps, IFormWithStepperProps {}

export const EditLocationsNestedContainer = ({
  initialLocations,
  locations,
  onError,
  onSuccess,
  onSkip,
  targetId,
  targetType
}: IEditLocationsNestedContainerProps) => {
  const { updateClient } = useUpdateClient();
  const { updateClientInvite } = useUpdateClientInvite();
  const { updateEntity } = useUpdateEntity();
  const { updateInvites } = useUpdateInvites();
  const { updateMembers } = useUpdateMembers();

  const handleSubmit = async (props: ISelectedIDProps) => {
    try {
      await resetFormMessages({ setSubmitError: onError, setSuccess: onSuccess });

      if (targetType === 'client') await updateClient({ _id: targetId, location: props.ids });
      else if (targetType === 'client-invite') await updateClientInvite({ _id: targetId, location: props.ids });
      else if (targetType === 'invite') await updateInvites({ ids: [targetId], location: props.ids });
      else if (targetType === 'member') await updateMembers({ ids: [targetId], location: props.ids });
      else if (targetType === 'entity') await updateEntity({ _id: targetId, location: props.ids });

      onSuccess('Successfully edited locations.');
    } catch (err) {
      const errMsg = 'Unable to edit locations.';
      logError(errMsg, (err as Error).message);
      onError(errMsg);
    }
  };

  return (
    <EditLocationsForm locations={locations} selected={initialLocations} onSubmit={handleSubmit} onSkip={onSkip} />
  );
};
