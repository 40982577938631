import { DetailBox } from '../details/details-box.component';
import { Loader } from '../loader.component';
import { ITableProps, Table } from './table.component';

interface ITableWithActionsProps extends ITableProps, React.PropsWithChildren {
  loading?: boolean;
}

export const TableWithActions = ({ children, loading, ...tableProps }: ITableWithActionsProps) => {
  return (
    <DetailBox stretch>
      <div className="w-full flex justify-end pt-4 gap-4">{children}</div>
      {loading ? <Loader /> : <Table {...tableProps} />}
    </DetailBox>
  );
};
