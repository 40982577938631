import { IRequestType } from '../../../lib/types';
import { IApolloCacheParams } from '../../../lib/cache.types';
import { GET_COMPANY_REQUEST_TYPES } from './request-type.queries';
import { ICurrCompanyRequestTypesResults } from './request-type.types';
import { mergeCacheLists } from '../../../lib/utils';

export const updateRequestTypeInCache = ({ cache, type }: IApolloCacheParams & { type: IRequestType }) => {
  const currCompanyRequestTypes = cache.readQuery<ICurrCompanyRequestTypesResults>({
    query: GET_COMPANY_REQUEST_TYPES
  })?.companyRequestTypes;

  cache.writeQuery({
    query: GET_COMPANY_REQUEST_TYPES,
    data: { companyRequestTypes: mergeCacheLists(currCompanyRequestTypes ?? [], [type]) }
  });
};
