import { useRef, useState } from 'react';
import { FormContainer } from '../../../_core/form/form-container.component';
import { CreateEntitySteps } from './create-entity-steps.component';
import { ICloseableDialogProps, IPersonalCompanyProps } from '../../../_core/core.types';

interface ICreateEntityStepperProps extends IPersonalCompanyProps, ICloseableDialogProps {}

export const CreateEntityStepper = (props: ICreateEntityStepperProps) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  return (
    <>
      <FormContainer
        contentRef={contentRef}
        error={submitError}
        setError={setSubmitError}
        success={success}
        setSuccess={setSuccess}
        title={'Create Entity'}
      >
        <CreateEntitySteps contentRef={contentRef} onError={setSubmitError} onSuccess={setSuccess} {...props} />
      </FormContainer>
    </>
  );
};
