import { IButtonDropdownOptionProps } from '../../../../_core/button/button-dropdown-option-component';
import { IContactsTableMultiselectActionProps } from './contacts-table-multiselect-action-button.types';
import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { showError } from '../../../../../lib/utils';
import { useResendClientInvite } from '../../../client-invite/client-invite.service';

export const useResendInviteAction = ({
  getSelectedModels,
  setSelected
}: IContactsTableMultiselectActionProps): IButtonDropdownOptionProps => {
  const { resendClientInvite } = useResendClientInvite();

  const resendInvites = useCallback(async () => {
    const models = getSelectedModels();
    if (!models?.selectedInvites.length) toast.error('No invites selected');
    else {
      try {
        const results = await Promise.all(models.selectedInvites.map(resendClientInvite));
        if (results.some((r) => !r.data?.resendClientInvite.sentAt)) toast.error('Unable to deliver some invites');
        else toast.success(`Resent ${models.selectedInvites.length} invites`);

        setSelected((s) => {
          if (s) return s.map(() => false);
          return null;
        });
      } catch (err) {
        showError('Unable to resend invites', err as Error);
      }
    }
  }, [getSelectedModels, resendClientInvite, setSelected]);

  return { onClick: resendInvites, text: 'Resend Invites' };
};
