import { IEntity } from '../../../../lib/types';
import { logError } from '../../../../lib/utils';
import { IPersonalCompanyProps } from '../../../_core/core.types';
import { extractFormOptionValue, resetFormMessages } from '../../../_core/form/form.helpers';
import { IFormWithStepperProps } from '../../../_core/form/form.types';
import { Loader } from '../../../_core/loader.component';
import { useSendClientInvite } from '../../client-invite/client-invite.service';
import { useCompanyDepartments } from '../../departments/department.service';
import { useCompanyLocations } from '../../locations/location.service';
import { useSendInvites } from '../invite.service';
import { ISendInvitesProps } from '../invite.types';
import { SendInvitesForm } from './send-invites-form.component';

export interface ISendInvitesContainerProps extends IPersonalCompanyProps {
  isClientInvite?: boolean;
  entity?: IEntity;
}

interface ISendInvitesNestedContainerProps extends IFormWithStepperProps, ISendInvitesContainerProps {}

export const SendInvitesNestedContainer = ({
  entity,
  isClientInvite,
  isPersonalCompany,
  onError,
  onSuccess,
  ...rest
}: ISendInvitesNestedContainerProps) => {
  const { departments, loading: loadingDepartments, called: calledDepartments } = useCompanyDepartments();
  const { locations, loading: loadingLocations, called: calledLocations } = useCompanyLocations();
  const { sendInvites } = useSendInvites();
  const { sendClientInvite } = useSendClientInvite();

  const handleSubmit = async ({ emails, roles, location, department }: ISendInvitesProps) => {
    try {
      await resetFormMessages({ setSubmitError: onError, setSuccess: onSuccess });

      if (isClientInvite) {
        const results = await Promise.all(
          emails.map((email) =>
            sendClientInvite({
              email,
              entityId: entity?._id,
              location: location.map(extractFormOptionValue),
              department: department.map(extractFormOptionValue)
            })
          )
        );

        if (results.some((r) => !r.data?.sendClientInvite.sentAt)) {
          onError('Unable to deliver some invites.');
          return;
        }
      } else {
        const results = await sendInvites({
          emails,
          roles: roles?.map(extractFormOptionValue) ?? [],
          location: location.map(extractFormOptionValue),
          department: department.map(extractFormOptionValue)
        });

        if (results.data?.sendInvites.some((r) => !r.sentAt)) {
          onError('Unable to deliver some invites.');
          return;
        }
      }
      onSuccess('Successfully sent invites');
    } catch (err) {
      const errMsg = 'Unable to send invites.';
      logError(errMsg, (err as Error).message);
      onError(errMsg);
    }
  };

  if (loadingDepartments || loadingLocations || !calledDepartments || !calledLocations || !departments || !locations)
    return <Loader />;

  return (
    <SendInvitesForm
      departments={departments}
      locations={locations}
      onSubmit={handleSubmit}
      isClientInvite={!!isClientInvite}
      isClientEntityInvite={!!entity}
      isPersonalCompany={isPersonalCompany}
      {...rest}
    />
  );
};
