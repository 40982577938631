import { gql } from '@apollo/client';
import { LOCATION_FRAGMENT } from '../locations/location.queries';
import { DEPARTMENT_FRAGMENT } from '../departments/department.queries';
import { USER_FRAGMENT } from '../users/user.queries';
import { COMPANY_FRAGMENT } from '../companies/company.queries';

export const ENTITY_FRAGMENT = gql`
  fragment EntityDetails on Entity {
    _id
    company {
      ...CompanyDetails
    }
    deletedAt
    department {
      ...DepartmentDetails
    }
    location {
      ...LocationDetails
    }
    mainContact {
      ...UserDetails
    }
    name
    status
  }

  ${COMPANY_FRAGMENT}
  ${DEPARTMENT_FRAGMENT}
  ${LOCATION_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const CREATE_ENTITY = gql`
  mutation CreateEntity($name: String!) {
    createEntity(name: $name) {
      ...EntityDetails
    }
  }

  ${ENTITY_FRAGMENT}
`;

export const DELETE_ENTITY = gql`
  mutation DeleteEntity($_id: ID!) {
    deleteEntity(_id: $_id) {
      ...EntityDetails
    }
  }

  ${ENTITY_FRAGMENT}
`;

export const GET_ENTITIES = gql`
  query Entities($includeArchived: Boolean) {
    entities(includeArchived: $includeArchived) {
      ...EntityDetails
    }
  }

  ${ENTITY_FRAGMENT}
`;

export const GET_ENTITY = gql`
  query Entity($_id: ID!) {
    entity(_id: $_id) {
      ...EntityDetails
    }
  }

  ${ENTITY_FRAGMENT}
`;

export const RESTORE_ENTITY = gql`
  mutation RestoreEntity($_id: ID!) {
    restoreEntity(_id: $_id) {
      ...EntityDetails
    }
  }

  ${ENTITY_FRAGMENT}
`;

export const UPDATE_ENTITY = gql`
  mutation UpdateEntity($department: [ID!], $_id: ID!, $location: [ID!], $name: String) {
    updateEntity(department: $department, _id: $_id, location: $location, name: $name) {
      ...EntityDetails
    }
  }

  ${ENTITY_FRAGMENT}
`;
