import { useCallback, useMemo } from 'react';
import toast from 'react-hot-toast';
import { Button } from '../../../_core/button/button.component';
import { IButtonDropdownOptionProps } from '../../../_core/button/button-dropdown-option-component';
import { IDepartment, IEntity, ILocation } from '../../../../lib/types';
import { showError } from '../../../../lib/utils';
import { useDeleteEntity, useRestoreEntity } from '../../../domains/entities/entity.service';
import { IPersonalCompanyProps } from '../../../_core/core.types';

type NullableEntity = IEntity | null;

export interface IEntityActionProps {
  entity: IEntity;
}

export interface IEntityActionButtonProps extends IEntityActionProps, IPersonalCompanyProps {
  departments?: IDepartment[];
  locations?: ILocation[];
  setShowEditDepartmentsModal: React.Dispatch<React.SetStateAction<NullableEntity>>;
  setShowEditLocationsModal: React.Dispatch<React.SetStateAction<NullableEntity>>;
}

export const EntityActionButton: React.FC<IEntityActionButtonProps> = ({
  departments,
  entity,
  isPersonalCompany,
  locations,
  setShowEditDepartmentsModal,
  setShowEditLocationsModal
}) => {
  const { deleteEntity, loading: deletingEntity } = useDeleteEntity();
  const { restoreEntity, loading: restoringEntity } = useRestoreEntity();

  const editEntityDepartmentsAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      disabled: !departments?.length,
      onClick: () => setShowEditDepartmentsModal(entity),
      text: 'Edit Departments'
    }),
    [departments?.length, entity, setShowEditDepartmentsModal]
  );

  const editEntityLocationsAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      disabled: !locations?.length,
      onClick: () => setShowEditLocationsModal(entity),
      text: 'Edit Locations'
    }),
    [entity, locations?.length, setShowEditLocationsModal]
  );

  const onRestoreEntity = useCallback(async () => {
    if (entity.deletedAt)
      try {
        await restoreEntity(entity);
        toast.success(`Restored Entity`);
      } catch (err) {
        showError('Unable to restore entity', err as Error);
      }
  }, [entity, restoreEntity]);

  const restoreEntityAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: onRestoreEntity, text: 'Restore' }),
    [onRestoreEntity]
  );

  const onDeleteEntity = useCallback(async () => {
    try {
      await deleteEntity(entity);
      toast.success(`Deleted Entity`);
    } catch (err) {
      showError('Unable to delete entity', err as Error);
    }
  }, [deleteEntity, entity]);

  const deleteEntityAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: onDeleteEntity, text: 'Delete' }),
    [onDeleteEntity]
  );

  const options = useMemo(() => {
    const newOptions: IButtonDropdownOptionProps[] = isPersonalCompany
      ? []
      : [editEntityDepartmentsAction, editEntityLocationsAction];

    newOptions.push(entity.deletedAt ? restoreEntityAction : deleteEntityAction);

    return newOptions;
  }, [
    deleteEntityAction,
    editEntityDepartmentsAction,
    editEntityLocationsAction,
    entity.deletedAt,
    isPersonalCompany,
    restoreEntityAction
  ]);

  return <Button dropdown={{ options }} text="ACTION" size="xl" loading={deletingEntity || restoringEntity} />;
};
