import { useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { IDepartment, Identifiable } from '../../../lib/types';
import { ICompanyDepartmentsResult, ICreateDepartmentProps, IUpdateDepartmentProps } from './department.types';
import {
  CREATE_DEPARTMENTS,
  DELETE_DEPARTMENT,
  GET_COMPANY_DEPARTMENTS,
  RESTORE_DEPARTMENT,
  UPDATE_DEPARTMENT
} from './department.queries';
import { updateDepartmentInCache } from './department.utils';
import { IArchivableProps } from '../../../lib/query.types';

export const useCompanyDepartments = () => {
  const { data, ...rest } = useQuery<ICompanyDepartmentsResult, IArchivableProps>(GET_COMPANY_DEPARTMENTS, {
    fetchPolicy: 'cache-and-network',
    variables: { includeArchived: true }
  });

  return { ...rest, ...data };
};

export const useCreateDepartments = () => {
  const [mutation, rest] = useMutation<{ createDepartments: IDepartment[] }>(CREATE_DEPARTMENTS, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) {
        // Update curr company departments
        const currCompanyDepartments = cache.readQuery<ICompanyDepartmentsResult, IArchivableProps>({
          query: GET_COMPANY_DEPARTMENTS,
          variables: { includeArchived: true }
        })?.departments;

        const newCurrCompanyDepartments = [];
        if (currCompanyDepartments) newCurrCompanyDepartments.push(...currCompanyDepartments);
        newCurrCompanyDepartments.push(...data.createDepartments);

        cache.writeQuery<ICompanyDepartmentsResult, IArchivableProps>({
          query: GET_COMPANY_DEPARTMENTS,
          data: { departments: newCurrCompanyDepartments },
          variables: { includeArchived: true }
        });
      }
    }
  });

  const createDepartments = useCallback((variables: ICreateDepartmentProps) => mutation({ variables }), [mutation]);

  return { createDepartments, ...rest };
};

export const useDeleteDepartment = () => {
  const [mutation, rest] = useMutation<{ deleteDepartment: IDepartment }>(DELETE_DEPARTMENT, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.deleteDepartment) updateDepartmentInCache({ cache, department: data.deleteDepartment });
    }
  });

  const deleteDepartment = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { deleteDepartment, ...rest };
};

export const useRestoreDepartment = () => {
  const [mutation, rest] = useMutation<{ restoreDepartment: IDepartment }>(RESTORE_DEPARTMENT, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.restoreDepartment) updateDepartmentInCache({ cache, department: data.restoreDepartment });
    }
  });

  const restoreDepartment = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { restoreDepartment, ...rest };
};

export const useUpdateDepartment = () => {
  const [mutation, rest] = useMutation<{ updateDepartment: IDepartment }>(UPDATE_DEPARTMENT, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.updateDepartment) updateDepartmentInCache({ cache, department: data.updateDepartment });
    }
  });

  const updateDepartment = useCallback((variables: IUpdateDepartmentProps) => mutation({ variables }), [mutation]);

  return { updateDepartment, ...rest };
};
