import { IInvite, INVITE_STATUS } from '../../../lib/types';
import { IFormMultiSelectOption } from '../../_core/form/input/form-multiselect-input.component';

export interface ISendInvitesProps {
  department: IFormMultiSelectOption[];
  emails: string[];
  location: IFormMultiSelectOption[];
  roles?: IFormMultiSelectOption[];
}

export interface ICompanyInviteResult {
  invites: IInvite[];
}

export interface ICompanyInviteQueryParams {
  status?: string;
}

export interface IInviteResult {
  invite: IInvite;
}

export interface IInviteQueryParams {
  code: string;
  status?: INVITE_STATUS;
}

export enum FetchInviteErrors {
  COMPANY_DELETED = 'Company has been deleted.',
  DOES_NOT_EXIST = 'Invite does not exist.',
  EXPIRED = 'Invite has expired.'
}
