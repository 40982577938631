import { Form, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { IClient, IEntity, IMember, IRequestType } from '../../../../lib/types';
import { PropsWithChildren, useMemo } from 'react';
import { ICreateRequestProps } from '../request.types';
import { useEntityMembers } from '../../entity-members/entity-members.service';

export interface IEditRequestFormFieldProps {
  clients: IClient[];
  entities: IEntity[];
  requestTypes: IRequestType[];
  staff: IMember[];
}

export const EditRequestFormFields = ({
  clients,
  entities,
  requestTypes,
  staff,
  createdById,

  // Formik props
  children,
  values
}: IEditRequestFormFieldProps &
  FormikProps<ICreateRequestProps> &
  PropsWithChildren & {
    createdById: string;
  }) => {
  const { entityMembers, loading: loadingEntityMembers } = useEntityMembers({
    entityId: values.entity?.length ? values.entity[0].value : undefined
  });

  const activeEntityMembers = useMemo(() => entityMembers?.filter((em) => !em.deletedAt), [entityMembers]);

  const clientOptions = useMemo(() => {
    return clients
      .filter(
        (c) => !c.deletedAt && (!values.entity?.length || activeEntityMembers?.find((em) => em.user._id === c.user._id))
      )
      .map((d) => ({ label: d.user.name ?? d.user.email, value: d._id }));
  }, [clients, activeEntityMembers, values.entity]);

  const staticOptions = useMemo(
    () => ({
      entities: entities.filter((e) => !e.deletedAt).map((e) => ({ label: e.name, value: e._id })),
      requestTypes: requestTypes.filter((rt) => !rt.deletedAt).map((l) => ({ label: l.type, value: l._id })),
      staff: staff
        .filter((s) => !s.deletedAt && s.user._id !== createdById)
        .map((d) => ({ label: d.user.name, value: d._id }))
    }),
    [createdById, entities, requestTypes, staff]
  );

  return (
    <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-6 mt-10">
      <FormInputWithLabel id="name" name="name" label="Name" placeholder="Request Name" value={values.name} />
      <FormInputWithLabel
        id="entity"
        name="entity"
        label="Entity"
        value={values.entity}
        type="multiselect"
        placeholder="Assign to entity"
        multiSelectProps={{ options: staticOptions.entities, singleSelect: true }}
      />
      <FormInputWithLabel
        id="assignedTo"
        name="assignedTo"
        label="Contact"
        placeholder="Primary contact"
        value={values.assignedTo}
        type="multiselect"
        multiSelectProps={{ options: clientOptions, singleSelect: true }}
        disabled={values.entity && (!entityMembers || loadingEntityMembers)}
      />
      <FormInputWithLabel
        id="ccClients"
        name="ccClients"
        label="Clients CC'd"
        value={values.ccClients}
        type="multiselect"
        placeholder="CC Clients"
        multiSelectProps={{ options: clientOptions }}
        disabled={values.entity && (!entityMembers || loadingEntityMembers)}
      />
      <FormInputWithLabel
        id="staff"
        name="staff"
        label="Staff CC'd"
        value={values.staff}
        type="multiselect"
        placeholder="CC Staff"
        multiSelectProps={{ options: staticOptions.staff }}
      />
      <FormInputWithLabel
        id="requestType"
        name="requestType"
        label="Request Type"
        value={values.requestType}
        type="multiselect"
        placeholder="Select request type"
        multiSelectProps={{ options: staticOptions.requestTypes, singleSelect: true }}
      />
      <FormInputWithLabel
        id="deadline"
        name="deadline"
        label="Deadline"
        placeholder="Enter deadline date"
        value={values.deadline}
        type="date"
      />
      <FormInputWithLabel id="notes" name="notes" label="Notes" placeholder="Optional notes" value={values.notes} />
      {children}
    </Form>
  );
};
