import { useState } from 'react';
import { Combobox } from '@headlessui/react';
import { ITemplate, TEMPLATE_TYPE } from '../../../lib/types';
import { useTemplates } from '../../domains/template/template.service';
import { inputClassesStr } from '../_core/editor.const';
import { EditorApolloProvider } from '../_core/editor-apollo-provider';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { getSelectOptionsClasses } from '../../_core/form/input/form-utils';

interface ISelectTemplateInput {
  onSelectTemplate: (_: ITemplate) => void;
  type: TEMPLATE_TYPE;
}

const SelectTemplateInput = ({ onSelectTemplate, type }: ISelectTemplateInput) => {
  const { templates } = useTemplates(type);

  const [query, setQuery] = useState('');
  const [selected, setSelected] = useState<ITemplate | null>(null);

  const onSelect = (t: ITemplate | null) => {
    setSelected(t);
    if (t) onSelectTemplate(t);
  };

  const filteredTemplates =
    query === ''
      ? templates
      : templates?.filter((t) => {
          return (t.title + ' ' + t.description).toLowerCase().includes(query.toLowerCase());
        });

  return (
    <>
      <label htmlFor="ids" className="block text-sm font-medium leading-6 text-gray-900">
        Select a Template to add to form:
      </label>
      <div className="w-2/3">
        <div className="relative">
          <Combobox value={selected} onChange={onSelect}>
            {({ open }) => (
              <>
                <Combobox.Input
                  aria-label="Assignee"
                  displayValue={(v) => (v as ITemplate | undefined)?.title ?? ''}
                  onChange={(event) => {
                    setQuery(event.target.value);
                    onSelect(null);
                  }}
                  placeholder="Select a template"
                  className={`w-full border-none py-2 pl-3 pr-10 text-sm leading-6 text-gray-900 focus:ring-0 ${inputClassesStr}`}
                />
                <Combobox.Button className="group absolute inset-y-0 right-0 px-2.5">
                  <ChevronDownIcon className="size-4 fill-white/60 group-data-[hover]:fill-white" />
                </Combobox.Button>
                {open && (
                  <div className="shadow top-100 bg-white z-40 w-full left-0 rounded absolute mt-2 ring-1">
                    <Combobox.Options
                      static
                      as="ul"
                      className={`pl-0 max-h-24 overflow-y-auto scrollbar scrollbar-track-rounded-full scrollbar-thumb-rounded-full my-1`}
                    >
                      {filteredTemplates?.length ? (
                        filteredTemplates?.map((o) => (
                          <Combobox.Option as="div" key={o._id} value={o} className={getSelectOptionsClasses}>
                            <div>
                              <b>{o.title}</b>
                            </div>
                            {!!o.description && <div className="text-xs leading-xs">{o.description}</div>}
                          </Combobox.Option>
                        ))
                      ) : (
                        <div className="py-2 pl-10 pr-4 text-gray-900">No options available...</div>
                      )}
                    </Combobox.Options>
                  </div>
                )}
              </>
            )}
          </Combobox>
        </div>
      </div>
    </>
  );
};

export const SelectTemplateInputContainer = (props: ISelectTemplateInput) => {
  return (
    <EditorApolloProvider>
      <SelectTemplateInput {...props} />
    </EditorApolloProvider>
  );
};
