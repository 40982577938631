import { useState } from 'react';
import { FormContainer } from '../../../_core/form/form-container.component';
import { useUpdateDepartment } from '../department.service';
import { UpdateDepartmentForm } from './update-department-form.component';
import { IDepartment } from '../../../../lib/types';
import { logError } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';

export const UpdateDepartmentContainer = ({ _id, name }: IDepartment) => {
  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const { updateDepartment } = useUpdateDepartment();

  const handleUpdate = async (props: { name: string }) => {
    try {
      await resetFormMessages({ setSubmitError, setSuccess });

      await updateDepartment({ ...props, _id });
      setSuccess('Successfully updated department!');
    } catch (err) {
      const errMsg = 'Unable to update department.';
      logError(errMsg, (err as Error).message);
      setSubmitError(errMsg);
    }
  };

  return (
    <FormContainer
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      title="Update Department"
    >
      <UpdateDepartmentForm name={name} onSubmit={handleUpdate} />
    </FormContainer>
  );
};
