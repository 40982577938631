import React, { useEffect } from 'react';
import { ScreenReaderText } from '../typography';

interface IFormMessageProps extends React.PropsWithChildren {
  onClose: () => void;
  type: 'error' | 'success';
}

export const FormMessage = ({ children, onClose, type }: IFormMessageProps) => {
  useEffect(() => {
    if (children && type === 'success') {
      const timeout = setTimeout(() => {
        onClose();
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [children, onClose, type]);

  const colorClasses = {
    error: {
      container: 'text-red-500 bg-red-50',
      button: 'bg-red-50 text-red-500 ring-red-400 bg-red-200'
    },
    success: {
      container: 'text-green-500 bg-green-50',
      button: 'bg-green-50 text-green-500 ring-green-400 bg-green-200'
    }
  };

  return (
    <div className={`flex items-center p-4 my-4 rounded-lg ${colorClasses[type].container}`} role="alert">
      <svg
        className="flex-shrink-0 w-4 h-4 mr-2"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
      </svg>
      <ScreenReaderText>Form Info</ScreenReaderText>
      {children}
      <button
        type="button"
        className={`ms-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 p-1.5 inline-flex items-center justify-center h-8 w-8 ${colorClasses[type].button}`}
        data-dismiss-target="#alert-2"
        aria-label="Close"
        onClick={onClose}
      >
        <ScreenReaderText>Close</ScreenReaderText>
        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
      </button>
    </div>
  );
};
