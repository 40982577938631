// Components
import { RIDialog } from '../../dialog/dialog.component';
import { useEffect, useState } from 'react';
import RegisterUserContainer from '../../../domains/users/register/register-user-form.container';
import { User } from '@auth0/auth0-react';
import { RegisterCompanyStepper } from '../../../domains/companies/register/register-company-stepper.component';
import { ICompany } from '../../../../lib/types';
import { IPersonalCompanyProps } from '../../core.types';

interface IDashboardPage extends IPersonalCompanyProps {
  authUser: User;
  company?: ICompany | null;
  hasCompany: boolean;
  hideRegisterCompany?: boolean;
  companiesError: boolean;
  companiesLoading: boolean;
  isRegistered: boolean;
}

export const ForceRegisterPrompts: React.FC<IDashboardPage> = ({
  authUser,
  company,
  hasCompany,
  hideRegisterCompany,
  companiesError,
  companiesLoading,
  isPersonalCompany,
  isRegistered
}) => {
  const [justRegisteredUser, setJustRegisteredUser] = useState(false);
  const [registerUserOpen, setRegisterUserOpen] = useState(false);
  const [registerCompanyOpen, setRegisterCompanyOpen] = useState(false);

  useEffect(() => {
    setRegisterUserOpen(!isRegistered);
  }, [isRegistered]);

  // Open UX-blocking register company form if no active company is found or if user just finished auto creating a personal company during initial registration
  // and hasn't been presented the onboarding wizard yet (ie. this will not force block the user on subsequent loads of the site)
  useEffect(() => {
    if (
      isRegistered &&
      !companiesLoading &&
      (!hasCompany || (isPersonalCompany && !company?.completedOnboarding && justRegisteredUser))
    )
      setRegisterCompanyOpen(true);
  }, [companiesLoading, company, hasCompany, isPersonalCompany, isRegistered, justRegisteredUser]);

  if (!!authUser?.sub && !!authUser.email && !isRegistered)
    return (
      <RIDialog locked open={registerUserOpen} setOpen={setRegisterUserOpen}>
        <RegisterUserContainer
          authId={authUser.sub}
          email={authUser.email}
          hideRegisterCompany={hideRegisterCompany}
          name={authUser.name || authUser.given_name}
          onSuccess={() => {
            setJustRegisteredUser(true);
            setRegisterUserOpen(false);
          }}
        />
      </RIDialog>
    );

  if (companiesLoading || hideRegisterCompany) return null;
  if (companiesError)
    return (
      <RIDialog locked open setOpen={() => {}}>
        <div className="h-48 flex flex-col gap-4 justify-center items-center">
          <div className="text-error">Failed to load companies. Please contact support.</div>
        </div>
      </RIDialog>
    );

  return (
    <RIDialog locked open={registerCompanyOpen} setOpen={setRegisterCompanyOpen}>
      <RegisterCompanyStepper
        initialData={isPersonalCompany && company ? { company } : undefined}
        onFinish={() => setTimeout(() => setRegisterCompanyOpen(false), 500)}
        close={() => setRegisterCompanyOpen(false)}
      />
    </RIDialog>
  );
};
