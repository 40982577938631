import { BlockToolConstructorOptions } from '@editorjs/editorjs/types/tools';
import { inputClasses, inputClassesLineHeightStr } from '../_core/editor.const';
import { LocalTitleBlockTool } from '../_core/local-title-block/local-title-block.component';
import { createRoot } from 'react-dom/client';
import { UploadInput } from './upload-input';
import { DeleteUploadButtonContainer } from './delete-upload-button';
import { renderWithBlockFocusWrapper } from '../_core/utils/editor.utils';
import { localTitleBlockExport, localTitleBlockImport } from '../_core/utils/block.utils';
import { UploadData, UploadItemData } from './upload.types';
import { DownloadAssetButtonContainer } from '../../domains/assets/download-asset-button';
import { ICreateTemplateBlockConfig } from '../_core/create-template-block';
import { onReadonlyInputKeydown } from '../_core/utils/input.utils';
import { IRequestTokenParams } from '../../../lib/types';

export interface IUploadBlockConfig extends ICreateTemplateBlockConfig, IRequestTokenParams {}

export class Upload extends LocalTitleBlockTool<UploadData, IUploadBlockConfig> {
  private filesContainerId: string;

  constructor(props: BlockToolConstructorOptions<UploadData>) {
    const { data, ...rest } = props;
    const newData = {
      ...data,
      uploads: [...(data.uploads ?? [])]
    };

    super({ ...rest, data: newData });

    this.filesContainerId = 'files-container-' + this.uid;
  }

  static get sanitize() {
    // disallow HTML
    return { ...super.sanitize, value: false };
  }

  static get toolbox() {
    return {
      title: 'Upload',
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13" /></svg>'
    };
  }

  render() {
    this.wrapper = document.createElement('div');
    this.wrapper.classList.add('flex', 'gap-4', 'overflow-x-auto');

    // Render upload input
    if (!this.config.disabled) {
      const uploadRoot = document.createElement('div');
      uploadRoot.classList.add('flex', 'items-center', 'justify-center', 'w-1/2');
      const root = createRoot(uploadRoot);
      root.render(
        <UploadInput
          disabled={this.config.disabled}
          onSave={this.config.onSave}
          onUploaded={(a) => this.renderUploadedFile(a._id, a.name)}
          uid={this.uid}
          requestToken={this.config.requestToken}
        />
      );

      this.wrapper.appendChild(uploadRoot);
    }

    // Render content with settings and local title content prepened
    const container = document.createElement('div');
    container.classList.add('flex', 'flex-col');
    container.appendChild(this.renderWithLocalTitle());

    // Render upload files container and current uploads
    const filesContainer = document.createElement('div');
    filesContainer.id = this.filesContainerId;
    filesContainer.classList.add('flex', 'flex-col');

    this.data.uploads.forEach((f) => filesContainer.appendChild(this.createUploadedFileComponent(f)));

    container.appendChild(filesContainer);

    // Add required UX components, if element is required
    const indicator = this.renderRequiredIndicator();
    if (indicator) this.wrapper.appendChild(indicator);

    const completedCheckbox = this.renderCompletedCheckbox();
    if (completedCheckbox) this.wrapper.appendChild(completedCheckbox);

    return renderWithBlockFocusWrapper(container);
  }

  // Remove file from uploads list and delete element from page
  private removeFile(fileId: string, fileContainerId: string) {
    // TODO: remove item on server
    this.data.uploads = this.data.uploads.filter((u) => u.fileId !== fileId);
    document.getElementById(fileContainerId)?.remove();

    // Update filled
    this.data.filled = !!this.data.uploads.length;
    this.toggleRequiredIndicator({ checked: this.data.filled });
  }

  private getLatestFileLabel(fileId: string) {
    const fileContainer = document.getElementById('file-' + fileId);
    const label = fileContainer?.querySelector('.file-label') as HTMLInputElement;
    return label.value;
  }

  private createUploadedFileComponent(fileData: UploadItemData) {
    const { fileId, fileName, label, notes } = fileData;

    const fileContainer = document.createElement('div');
    fileContainer.classList.add('file-container', 'flex', 'gap-4', 'overflow-x-auto', 'items-center', 'ml-8', 'mt-2');
    const fileContainerId = 'file-' + fileId;
    fileContainer.id = fileContainerId;

    const fileIdInput = document.createElement('input');
    fileIdInput.classList.add('hidden', 'file-id-input');
    fileIdInput.value = fileId;
    fileContainer.appendChild(fileIdInput);

    const fileNameLabel = document.createElement('span');
    fileNameLabel.classList.add('self-center', 'w-3/12', ...inputClassesLineHeightStr.split(' '));
    fileNameLabel.textContent = fileName ?? '';
    fileContainer.appendChild(fileNameLabel);

    let labelInput = document.createElement('input');
    labelInput.classList.add('file-label', 'w-3/12', ...inputClasses);
    labelInput.placeholder = '< Enter label >';
    labelInput.value = label ?? '';
    labelInput.disabled = this.config.disabled;

    if (this.config.readOnly) labelInput = onReadonlyInputKeydown(labelInput) as HTMLInputElement;
    fileContainer.appendChild(labelInput);

    let labelNotesInput = document.createElement('input');
    labelNotesInput.classList.add('file-notes', 'w-5/12', ...inputClasses);
    labelNotesInput.placeholder = '< Enter notes >';
    labelNotesInput.value = notes ?? '';
    labelNotesInput.disabled = this.config.disabled;

    if (this.config.readOnly) labelNotesInput = onReadonlyInputKeydown(labelNotesInput) as HTMLInputElement;
    fileContainer.appendChild(labelNotesInput);

    // Create button icon
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.setAttributeNS(null, 'stroke-linecap', 'round');
    path.setAttributeNS(null, 'stroke-linejoin', 'round');
    path.setAttributeNS(
      null,
      'd',
      'm14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0'
    );

    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttributeNS(null, 'viewBox', '0 0 24 24');
    svg.setAttributeNS(null, 'stroke-width', '1.5');
    svg.setAttributeNS(null, 'stroke', 'currentColor');
    svg.setAttributeNS(null, 'fill', 'none');
    svg.classList.add('w-6', 'h-6');
    svg.appendChild(path);

    // Create buttons
    const buttonContainers = document.createElement('div');
    buttonContainers.classList.add('flex', 'gap-1', 'w-1/12');

    // Create delete button
    if (!this.config.disabled) {
      const deleteButtonContainer = document.createElement('div');
      deleteButtonContainer.classList.add('flex', 'items-center');

      const deleteRoot = createRoot(deleteButtonContainer);
      deleteRoot.render(
        <DeleteUploadButtonContainer
          assetId={fileId}
          onDeleteStart={() => this.removeFile(fileId, fileContainerId)}
          onSave={this.config.onSave}
          requestId={this.config.requestId}
          requestToken={this.config.requestToken}
        />
      );

      buttonContainers.appendChild(deleteButtonContainer);
    }

    if (this.api.readOnly.isEnabled) {
      // Create download button
      const downloadButtonContainer = document.createElement('div');
      downloadButtonContainer.classList.add('flex', 'items-center');

      const downloadRoot = createRoot(downloadButtonContainer);
      downloadRoot.render(
        <DownloadAssetButtonContainer
          {...fileData}
          getLatestFileLabel={this.getLatestFileLabel}
          requestToken={this.config.requestToken}
        />
      );

      buttonContainers.appendChild(downloadButtonContainer);
    }

    fileContainer.appendChild(buttonContainers);

    return fileContainer;
  }

  private renderUploadedFile(fileId: string, fileName: string) {
    // Update data and render new file component
    this.data.uploads.push({ fileId, fileName, label: '', notes: '' });
    const fileContainer = this.createUploadedFileComponent({ fileId, fileName });
    document.getElementById(this.filesContainerId)?.appendChild(fileContainer);

    // Update filled
    this.data.filled = true;
    this.toggleRequiredIndicator({ checked: this.data.filled });
  }

  async save(blockContent: HTMLDivElement): Promise<UploadData> {
    const newData = await super.save(blockContent);

    const files = blockContent.querySelectorAll('.file-container');
    files.forEach((file) => {
      const label = file.querySelector('.file-label') as HTMLInputElement;
      const notes = file.querySelector('.file-notes') as HTMLInputElement;
      const fileIdInput = file.querySelector('.file-id-input') as HTMLInputElement;
      const index = this.data.uploads.findIndex((u) => u.fileId === fileIdInput.value);
      if (index !== -1) newData.uploads[index] = { ...newData.uploads[index], label: label.value, notes: notes.value };
    });

    return newData;
  }

  validate({ ...rest }: UploadData) {
    return super.validate(rest);
  }

  static get conversionConfig() {
    return { export: localTitleBlockExport, import: localTitleBlockImport };
  }
}
