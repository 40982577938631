import { API } from '@editorjs/editorjs';
import { BlockTool, BlockToolConstructorOptions, BlockToolData } from '@editorjs/editorjs/types/tools';
import { uniqueId } from 'lodash';
import { createRoot } from 'react-dom/client';

export const CUSTOM_BLOCK_WRAPPER_CLASS = 'custom-block-wrapper';
export const SHOW_BOTH_TOOLBAR_OPTIONS_ON_EMPTY_CLASS = 'toolbar-show-on-empty';

export class BaseBlockTool<T extends BlockToolData> implements BlockTool {
  protected api: API;
  protected uid: string;
  protected toolbarWrapperId: string;
  protected wrapper?: HTMLDivElement;
  protected wrapperId: string;

  constructor(props: BlockToolConstructorOptions) {
    this.api = props.api;
    this.uid = uniqueId();
    this.toolbarWrapperId = 'toolbar-indicator-wrapper-' + this.uid;
    this.wrapperId = 'wrapper-' + this.uid;
  }

  render(): HTMLElement {
    if (!this.wrapper) this.wrapper = document.createElement('div');
    this.wrapper.id = this.wrapperId;
    this.wrapper.classList.add(CUSTOM_BLOCK_WRAPPER_CLASS, SHOW_BOTH_TOOLBAR_OPTIONS_ON_EMPTY_CLASS);

    if (!this.api.readOnly.isEnabled) {
      const toolbar = (
        <div className="ce-toolbar__actions ce-toolbar__actions--opened ce-toolbar__actions-custom">
          <div className="ce-toolbar__plus bg-gray-300 text-white">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M11.828 2.25c-.916 0-1.699.663-1.85 1.567l-.091.549a.798.798 0 0 1-.517.608 7.45 7.45 0 0 0-.478.198.798.798 0 0 1-.796-.064l-.453-.324a1.875 1.875 0 0 0-2.416.2l-.243.243a1.875 1.875 0 0 0-.2 2.416l.324.453a.798.798 0 0 1 .064.796 7.448 7.448 0 0 0-.198.478.798.798 0 0 1-.608.517l-.55.092a1.875 1.875 0 0 0-1.566 1.849v.344c0 .916.663 1.699 1.567 1.85l.549.091c.281.047.508.25.608.517.06.162.127.321.198.478a.798.798 0 0 1-.064.796l-.324.453a1.875 1.875 0 0 0 .2 2.416l.243.243c.648.648 1.67.733 2.416.2l.453-.324a.798.798 0 0 1 .796-.064c.157.071.316.137.478.198.267.1.47.327.517.608l.092.55c.15.903.932 1.566 1.849 1.566h.344c.916 0 1.699-.663 1.85-1.567l.091-.549a.798.798 0 0 1 .517-.608 7.52 7.52 0 0 0 .478-.198.798.798 0 0 1 .796.064l.453.324a1.875 1.875 0 0 0 2.416-.2l.243-.243c.648-.648.733-1.67.2-2.416l-.324-.453a.798.798 0 0 1-.064-.796c.071-.157.137-.316.198-.478.1-.267.327-.47.608-.517l.55-.091a1.875 1.875 0 0 0 1.566-1.85v-.344c0-.916-.663-1.699-1.567-1.85l-.549-.091a.798.798 0 0 1-.608-.517 7.507 7.507 0 0 0-.198-.478.798.798 0 0 1 .064-.796l.324-.453a1.875 1.875 0 0 0-.2-2.416l-.243-.243a1.875 1.875 0 0 0-2.416-.2l-.453.324a.798.798 0 0 1-.796.064 7.462 7.462 0 0 0-.478-.198.798.798 0 0 1-.517-.608l-.091-.55a1.875 1.875 0 0 0-1.85-1.566h-.344ZM12 15.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <span className="ce-toolbar__settings-btn" draggable="true">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" strokeLinecap="round" strokeWidth="2.6" d="M9.40999 7.29999H9.4"></path>
              <path stroke="currentColor" strokeLinecap="round" strokeWidth="2.6" d="M14.6 7.29999H14.59"></path>
              <path stroke="currentColor" strokeLinecap="round" strokeWidth="2.6" d="M9.30999 12H9.3"></path>
              <path stroke="currentColor" strokeLinecap="round" strokeWidth="2.6" d="M14.6 12H14.59"></path>
              <path stroke="currentColor" strokeLinecap="round" strokeWidth="2.6" d="M9.40999 16.7H9.4"></path>
              <path stroke="currentColor" strokeLinecap="round" strokeWidth="2.6" d="M14.6 16.7H14.59"></path>
            </svg>
          </span>
        </div>
      );

      const toolbarWrapper = document.createElement('div');
      toolbarWrapper.id = this.toolbarWrapperId;
      toolbarWrapper.classList.add('toolbar-indicator');
      const root = createRoot(toolbarWrapper);
      root.render(toolbar);

      this.wrapper?.prepend(toolbarWrapper);
    }

    return this.wrapper;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async save(_blockContent: HTMLElement): Promise<T> {
    throw new Error('Unimplemented method');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(_blockData: T): boolean {
    throw new Error('Unimplemented method');
  }
}
