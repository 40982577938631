import React, { memo, useCallback, useMemo } from 'react';
import EditorJs, { API, BlockMutationEvent, OutputData } from '@editorjs/editorjs';
import DragDrop from 'editorjs-drag-drop';
import { ICreateTemplateBlockConfig, ICreateTemplateParams } from '../../_editor/_core/create-template-block';

import { FormEditorType, IEditorRefProps } from './form-editor.types';
import { useFocusedBlockTracking } from '../../_editor/_core/utils/editor.utils';

import './form-editor.css';
import '../../_editor/section/section.css';
import { getEditorTools } from './form-editor.utils';
import { Button } from '../../_core/button/button.component';
import { PlusIcon } from '@heroicons/react/20/solid';
import { usePrev } from '../../../lib/utils';

interface IEditor extends IEditorRefProps {
  data: OutputData;
  onChange?: (api: API, event: BlockMutationEvent | BlockMutationEvent[]) => void;
  onCreateTemplate?: (_: ICreateTemplateParams) => void;
  onReady?: () => void;
  onSave: () => Promise<OutputData | undefined>;
  disabled: boolean;
  nested?: boolean;
  readOnly: boolean;
  requestId: string;
  type?: FormEditorType;
  token?: string;
}

const Editor: React.FC<IEditor> = ({
  data,
  editorRef,
  onChange,
  onCreateTemplate,
  onReady,
  onSave,
  editorblock,
  disabled,
  nested,
  readOnly,
  requestId,
  type,
  token
}) => {
  // TODO: Why is this re-rendering despite memoized and containing component having no state or non-callbacks? need to troubleshoot with why-did-you-render
  // console.log('Why re-render?');

  const prevDisabled = usePrev(disabled);
  const prevReadOnly = usePrev(readOnly);

  useFocusedBlockTracking({ editorblock, editorRef });

  useMemo(() => {
    // Destroy editor and re-initialize when disabled status changes, so that editor components are re-initialized with disabled config values
    if (
      !editorRef.current ||
      (prevDisabled !== undefined && prevDisabled !== disabled) ||
      (prevReadOnly !== undefined && prevReadOnly !== readOnly)
    ) {
      if (editorRef.current?.destroy) {
        editorRef.current.destroy();
      } else {
        const config: ICreateTemplateBlockConfig = {
          disabled: !!disabled,
          onCreateTemplate,
          onSave,
          readOnly,
          requestId
        };

        const editor = new EditorJs({
          holder: editorblock,
          tools: getEditorTools(config, type, token),
          readOnly: readOnly || disabled,
          data,
          onChange,
          onReady: () => {
            new DragDrop(editor);
            onReady?.();
          },
          minHeight: readOnly || disabled ? 0 : 5,
          placeholder: 'Add content here!'
        });

        editorRef.current = editor;
      }
    }

    //Add a return function to handle cleanup
    return () => {
      if (editorRef.current && editorRef.current.destroy) {
        editorRef.current.destroy();
      }
    };
  }, [
    editorRef,
    prevDisabled,
    disabled,
    prevReadOnly,
    readOnly,
    onCreateTemplate,
    onSave,
    requestId,
    editorblock,
    type,
    token,
    data,
    onChange,
    onReady
  ]);

  // Quickly insert new blocks into the document
  const onQuickCreate = useCallback(() => {
    if (editorRef.current) {
      const blockCount = editorRef.current.blocks.getBlocksCount();
      const lastBlock = editorRef.current.blocks.getBlockByIndex(blockCount - 1);

      // If there are no blocks or last block is empty, then prompt user with block menu
      if (blockCount <= 1 && lastBlock?.isEmpty) {
        editorRef.current.focus(true);
        editorRef.current.toolbar.toggleToolbox(true);
      }
      // Otherwise create a new empty block matching the last one in the editor
      else editorRef.current.blocks.insert(lastBlock?.name, {}, lastBlock?.config, blockCount);
    }
  }, [editorRef]);

  return (
    <div className={nested ? undefined : 'p-2'}>
      <div
        id={editorblock}
        className={`editor-container ml-auto ${nested ? '' : 'prose'} ${readOnly ? 'read-only' : ''}`}
      />
      {!disabled && !readOnly && (
        <Button
          icon={<PlusIcon height={20} width={20} className="m-auto" />}
          className="ce-toolbar__button !p-0 bg-green-400 hover:bg-green-300 ml-1"
          onClick={onQuickCreate}
          variant="custom"
        />
      )}
    </div>
  );
};

export const FormEditor = memo(Editor);
