import { IInvite, IMember } from '../../../../lib/types';
import React, { PropsWithChildren } from 'react';
import { RIDialog } from '../../../_core/dialog/dialog.component';
import { SendInvitesContainer } from '../../../domains/invite/send/send-invites-form.container';
import { TableWithActions } from '../../../_core/table/table-with-actions.component';
import { ITableRowValues } from '../../../_core/table/table.component';
import { IAdminProps } from '../../../_core/core.types';

interface IStaffTableProps extends PropsWithChildren, IAdminProps {
  inviteStaff: boolean;
  onSelect: (_index: number, _selected?: boolean) => void;
  onSelectAll: (_selected: boolean) => void;
  selected?: boolean[];
  setInviteStaff: React.Dispatch<React.SetStateAction<boolean>>;
  staff: IStaffTableRowValues[];
}

export interface IStaffTableRowValues extends ITableRowValues {
  invite?: IInvite;
  member?: IMember;
}

export const StaffTable: React.FC<IStaffTableProps> = ({
  children,
  inviteStaff,
  isAdmin,
  onSelect,
  onSelectAll,
  selected,
  setInviteStaff,
  staff
}) => {
  const headers = ['Name', 'Email', 'Roles', 'Status', 'Departments', 'Locations'];
  if (isAdmin) headers.push('');

  return (
    <>
      <TableWithActions
        selectable
        loading={!staff}
        headers={headers}
        values={staff}
        selected={selected}
        onRowClick={onSelect}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
      >
        {children}
      </TableWithActions>
      <RIDialog open={inviteStaff} setOpen={setInviteStaff}>
        <SendInvitesContainer />
      </RIDialog>
    </>
  );
};
