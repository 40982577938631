import { gql } from '@apollo/client';
import { COMPANY_FRAGMENT } from '../companies/company.queries';
import { LOCATION_FRAGMENT } from '../locations/location.queries';
import { DEPARTMENT_FRAGMENT } from '../departments/department.queries';
import { USER_FRAGMENT } from '../users/user.queries';
import { ENTITY_FRAGMENT } from '../entities/entity.queries';
import { CLIENT_FRAGMENT } from '../clients/client.queries';
import { ENTITY_MEMBER_FRAGMENT } from '../entity-members/entity-members.queries';

const CLIENT_INVITE_FRAGMENT = gql`
  fragment ClientInviteDetails on ClientInvite {
    _id
    code
    company {
      ...CompanyDetails
    }
    department {
      ...DepartmentDetails
    }
    email
    entity {
      ...EntityDetails
    }
    expiresAt
    isExpired
    location {
      ...LocationDetails
    }
    sentAt
    sentBy {
      ...UserDetails
    }
    status
  }

  ${COMPANY_FRAGMENT}
  ${DEPARTMENT_FRAGMENT}
  ${ENTITY_FRAGMENT}
  ${LOCATION_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const ACCEPT_CLIENT_INVITE = gql`
  mutation AcceptClientInvite($code: String!) {
    acceptClientInvite(code: $code) {
      ...ClientInviteDetails
    }
  }

  ${CLIENT_INVITE_FRAGMENT}
`;

export const GET_CLIENT_INVITE = gql`
  query GetClientInvite($code: String!, $status: STATUS) {
    clientInvite(code: $code, status: $status) {
      ...ClientInviteDetails
    }
  }

  ${CLIENT_INVITE_FRAGMENT}
`;

export const GET_CLIENT_INVITES = gql`
  query GetClientInvites($status: STATUS) {
    clientInvites(status: $status) {
      ...ClientInviteDetails
    }
  }

  ${CLIENT_INVITE_FRAGMENT}
`;

export const REMOVE_CLIENT_INVITE = gql`
  mutation RemoveClientInvite($_id: ID!) {
    removeClientInvite(_id: $_id) {
      ...ClientInviteDetails
    }
  }

  ${CLIENT_INVITE_FRAGMENT}
`;

export const RESEND_CLIENT_INVITE = gql`
  mutation ResendClientInvite($_id: ID!) {
    resendClientInvite(_id: $_id) {
      ...ClientInviteDetails
    }
  }

  ${CLIENT_INVITE_FRAGMENT}
`;

export const SEND_CLIENT_INVITE = gql`
  mutation SendClientInvite($entityId: ID, $department: [ID!]!, $email: String!, $location: [ID!]!) {
    sendClientInvite(department: $department, email: $email, entityId: $entityId, location: $location) {
      client {
        ...ClientDetails
      }
      entityMember {
        ...EntityMemberDetails
      }
    }
  }

  ${CLIENT_FRAGMENT}
  ${ENTITY_MEMBER_FRAGMENT}
`;

export const UPDATE_CLIENT_INVITE = gql`
  mutation UpdateClientInvite($department: [ID!], $_id: ID!, $location: [ID!]) {
    updateClientInvite(department: $department, _id: $_id, location: $location) {
      ...ClientInviteDetails
    }
  }

  ${CLIENT_INVITE_FRAGMENT}
`;
