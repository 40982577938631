import { Button, ButtonVariant } from '../../button/button.component';

interface IFormSubmitButtonProps {
  disabled?: boolean;
  loading?: boolean;
  onPress?: () => void;
  text: string;
  type?: 'submit' | 'button';
  variant?: ButtonVariant;
}

export const FormButton = ({
  disabled,
  loading,
  onPress,
  text,
  type = 'submit',
  variant = 'primary'
}: IFormSubmitButtonProps) => {
  return (
    <div className="flex flex-row items-center justify-center">
      <div className="w-32">
        <Button disabled={disabled} loading={loading} onClick={onPress} text={text} type={type} variant={variant} />
      </div>
    </div>
  );
};
