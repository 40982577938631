interface IIconProps {
  open?: boolean;
}

interface IUpDownIconProps extends IIconProps {
  spacing?: 'smallLeft' | 'smallRight' | 'auto';
  color?: string;
}

export const UpDownIcon = ({ spacing = 'auto', color, open }: IUpDownIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke={color ?? 'currentColor'}
    className={`w-6 h-6 ${spacing === 'auto' ? 'mx-auto' : spacing === 'smallRight' ? 'mr-2' : 'ml-2'}`}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d={open ? 'm4.5 15.75 7.5-7.5 7.5 7.5' : 'm19.5 8.25-7.5 7.5-7.5-7.5'}
    />
  </svg>
);
