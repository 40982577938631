interface ITableCheckboxProps {
  checked?: boolean;
  onChange?: (_: boolean) => void;
}

export const TableCheckbox = ({ checked, onChange }: ITableCheckboxProps) => {
  return (
    <td className="w-4 p-4">
      <div className="flex items-center">
        <input
          id="checkbox-table-search-1"
          type="checkbox"
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
          checked={!!checked}
          onChange={onChange ? (e) => onChange(e.target.checked) : undefined}
        />
        <label htmlFor="checkbox-table-search-1" className="sr-only">
          checkbox
        </label>
      </div>
    </td>
  );
};
