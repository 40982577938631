import { IGetTemplatesParams, ITemplateResults } from './template.types';
import { GET_TEMPLATES } from './template.queries';
import { ITemplateBaseDetails, TEMPLATE_TYPE } from '../../../lib/types';
import { IApolloCacheParams } from '../../../lib/cache.types';
import { mergeCacheLists } from '../../../lib/utils';

export const updateTemplateInCache = ({
  cache,
  template,
  type
}: IApolloCacheParams & { template: ITemplateBaseDetails; type: TEMPLATE_TYPE }) => {
  const currCompanyTemplates = cache.readQuery<ITemplateResults, IGetTemplatesParams>({
    query: GET_TEMPLATES,
    variables: { type }
  })?.templates;

  cache.writeQuery<ITemplateResults, IGetTemplatesParams>({
    query: GET_TEMPLATES,
    data: { templates: mergeCacheLists(currCompanyTemplates ?? [], [template]) },
    variables: { type }
  });
};
