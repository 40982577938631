import { IRequest } from '../../../../lib/types';
import React, { PropsWithChildren } from 'react';
import { TableWithActions } from '../../../_core/table/table-with-actions.component';
import { ITableRowValues } from '../../../_core/table/table.component';
import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';

interface IRequestTableProps extends PropsWithChildren, IAdminProps, IPersonalCompanyProps {
  requests: IRequestTableRowValues[];
  editingDisabled?: boolean;
}

export interface IRequestTableRowValues extends ITableRowValues {
  request: IRequest;
}

export const RequestTable: React.FC<IRequestTableProps> = ({ children, requests }) => {
  const headers = ['Owner', 'Title', 'Last Update', 'Entity', 'Contact', 'Type', 'Deadline', 'Status', ''];

  return (
    <>
      <TableWithActions loading={!requests} headers={headers} values={requests}>
        {children}
      </TableWithActions>
    </>
  );
};
