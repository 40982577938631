import { useState } from 'react';
import { FormContainer } from '../../../_core/form/form-container.component';
import { ISelectedRolesProps } from '../../../../lib/query.types';
import { useUpdateInvites } from '../../invite/invite.service';
import { useUpdateMembers } from '../../member/member.service';
import { logError } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';
import { IStaffActionProps } from '../../../_pages/FirmProfilePage/staff-table/staff-action-button';
import { EditRolesForm } from './edit-roles-form.component';

export const EditRolesContainer = ({ invite, member }: IStaffActionProps) => {
  const { updateInvites } = useUpdateInvites();
  const { updateMembers } = useUpdateMembers();

  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const handleSubmit = async (props: ISelectedRolesProps) => {
    try {
      await resetFormMessages({ setSubmitError, setSuccess });

      if (invite) await updateInvites({ ids: [invite._id], roles: props.roles });
      else if (member) await updateMembers({ ids: [member._id], roles: props.roles });
      else {
        setSubmitError('Unable to determine selected.');
        return;
      }

      setSuccess('Successfully edited roles');
    } catch (err) {
      const errMsg = 'Unable to edit roles.';
      logError(errMsg, (err as Error).message);
      setSubmitError(errMsg);
    }
  };

  return (
    <FormContainer
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      title="Edit Roles"
    >
      <EditRolesForm onSubmit={handleSubmit} roles={invite?.roles ?? member?.roles} />
    </FormContainer>
  );
};
