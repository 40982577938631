import { DefaultPageLayout } from './default-page.layout';
import { NoMatchPageContainer } from '../_pages/404/404-page.container';

export const NoMatchPageLayout = () => {
  return (
    <DefaultPageLayout>
      <NoMatchPageContainer />
    </DefaultPageLayout>
  );
};
