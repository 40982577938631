import { ILabelBlockData } from '../label-block';
import { IBlockConversionData } from '../types';

export const convertBlockImportData = (importString: string): IBlockConversionData => {
  return JSON.parse(importString) as IBlockConversionData;
};

export const convertBlockImportToLocalTitleData = (importData: IBlockConversionData): Partial<ILabelBlockData> => {
  return { label: importData.label, required: importData.required ?? false };
};

export const localTitleBlockImport = (importString: string): Partial<ILabelBlockData> => {
  const importData = convertBlockImportData(importString);
  return convertBlockImportToLocalTitleData(importData);
};

export const localTitleBlockExport = (data: ILabelBlockData, toString = true): IBlockConversionData | string => {
  const convertedData = {
    label: data.label,
    required: data.required
  } as IBlockConversionData;

  if (toString) return JSON.stringify(convertedData);
  return convertedData;
};
