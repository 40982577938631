import { useCallback, useMemo } from 'react';
import toast from 'react-hot-toast';
import { Button } from '../../../_core/button/button.component';
import { IButtonDropdownOptionProps } from '../../../_core/button/button-dropdown-option-component';
import { useRemoveInvite, useResendInvite } from '../../../domains/invite/invite.service';
import { useRemoveMember, useRestoreMember } from '../../../domains/member/member.service';
import { IDepartment, IInvite, ILocation, IMember } from '../../../../lib/types';
import { showError } from '../../../../lib/utils';
import { NullableStaff } from './staff-table.types';

export interface IStaffActionProps {
  invite?: IInvite;
  member?: IMember;
}

export interface IStaffActionButtonProps extends IStaffActionProps {
  currUserId: string;
  departments?: IDepartment[];
  locations?: ILocation[];
  setShowEditDepartmentsModal: React.Dispatch<React.SetStateAction<NullableStaff>>;
  setShowEditLocationsModal: React.Dispatch<React.SetStateAction<NullableStaff>>;
  setShowEditRolesModal: React.Dispatch<React.SetStateAction<NullableStaff>>;
}

export const StaffActionButton: React.FC<IStaffActionButtonProps> = ({
  currUserId,
  departments,
  invite,
  locations,
  member,
  setShowEditDepartmentsModal,
  setShowEditLocationsModal,
  setShowEditRolesModal
}) => {
  const { removeInvite, loading: removingInvite } = useRemoveInvite();
  const { removeMember, loading: removingMember } = useRemoveMember();
  const { restoreMember, loading: restoringMember } = useRestoreMember();
  const { resendInvite, loading: resendingInvite } = useResendInvite();

  const editStaffDepartmentsAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      disabled: !departments?.length,
      onClick: () => setShowEditDepartmentsModal(invite ?? member ?? null),
      text: 'Edit Departments'
    }),
    [departments?.length, invite, member, setShowEditDepartmentsModal]
  );

  const editStaffLocationsAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      disabled: !locations?.length,
      onClick: () => setShowEditLocationsModal(invite ?? member ?? null),
      text: 'Edit Locations'
    }),
    [invite, locations?.length, member, setShowEditLocationsModal]
  );

  const editStaffRolesAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: () => setShowEditRolesModal(invite ?? member ?? null), text: 'Edit Roles' }),
    [invite, member, setShowEditRolesModal]
  );

  const onResendInvite = useCallback(async () => {
    if (invite)
      try {
        const result = await resendInvite(invite);

        if (!result.data?.resendInvite.sentAt) toast.error('Unable to deliver invite');
        else toast.success(`Resent invite`);
      } catch (err) {
        showError('Unable to resend invite', err as Error);
      }
  }, [invite, resendInvite]);

  const resendInviteAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: onResendInvite, text: 'Resend Invite' }),
    [onResendInvite]
  );

  const onRestoreMember = useCallback(async () => {
    if (member?.deletedAt)
      try {
        await restoreMember(member);
        toast.success(`Restored member`);
      } catch (err) {
        showError('Unable to restore member', err as Error);
      }
  }, [member, restoreMember]);

  const restoreMemberAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: onRestoreMember, text: 'Restore' }),
    [onRestoreMember]
  );

  const onDeleteStaff = useCallback(async () => {
    if (invite)
      try {
        await removeInvite(invite);
        toast.success(`Deleted invite`);
      } catch (err) {
        showError('Unable to delete invite', err as Error);
      }
    else if (member)
      try {
        await removeMember(member);
        toast.success(`Deleted member`);
      } catch (err) {
        showError('Unable to delete member', err as Error);
      }
  }, [invite, member, removeInvite, removeMember]);

  const deleteStaffAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: onDeleteStaff, text: 'Delete' }),
    [onDeleteStaff]
  );

  const options = useMemo(() => {
    const newOptions: IButtonDropdownOptionProps[] = [
      editStaffDepartmentsAction,
      editStaffLocationsAction,
      editStaffRolesAction
    ];

    if (invite) newOptions.push(resendInviteAction);
    if (member?.deletedAt) newOptions.push(restoreMemberAction);
    if (!member || (member.user._id !== currUserId && !member.deletedAt)) newOptions.push(deleteStaffAction);
    return newOptions;
  }, [
    currUserId,
    deleteStaffAction,
    editStaffDepartmentsAction,
    editStaffLocationsAction,
    editStaffRolesAction,
    invite,
    member,
    resendInviteAction,
    restoreMemberAction
  ]);

  return (
    <Button
      dropdown={{ options }}
      text="ACTION"
      size="xl"
      loading={removingMember || restoringMember || removingInvite || resendingInvite}
    />
  );
};
