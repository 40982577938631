import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../_core/button/button.component';
import { IButtonDropdownOptionProps } from '../../_core/button/button-dropdown-option-component';
import { IRequest, REQUEST_NOTIFY_OPTION, REQUEST_STATUS } from '../../../lib/types';
import { ROUTE_PATHS } from '../../../_routes';
import {
  useDeleteRequest,
  useRestoreRequest,
  useSendRequest,
  useSendRequestReminder,
  useUpdateRequest
} from './request.service';
import toast from 'react-hot-toast';
import { showError } from '../../../lib/utils';

export interface IRequestActionProps {
  hideViewAction?: boolean;
  last?: boolean;
  request: IRequest;
  togglePreview?: (_: boolean) => void;
}

export const RequestActionButton: React.FC<IRequestActionProps> = ({
  hideViewAction,
  last,
  request,
  togglePreview
}) => {
  const navigate = useNavigate();

  const { deleteRequest, loading: deletingRequest } = useDeleteRequest();
  const { restoreRequest, loading: restoringRequest } = useRestoreRequest();
  const { sendRequest, loading: sendingRequest } = useSendRequest({ _id: request._id });
  const { sendRequestReminder, loading: sendingRequestReminder } = useSendRequestReminder({ _id: request._id });
  const { updateRequest, loading: updatingRequest } = useUpdateRequest(request);

  const viewAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      onClick: () => navigate(ROUTE_PATHS.REQUEST + '/' + request._id),
      text: 'View Request'
    }),
    [navigate, request._id]
  );

  const updateStatusAction: IButtonDropdownOptionProps = useMemo(() => {
    const isClosed = request.status === REQUEST_STATUS.CLOSED;
    return {
      onClick: () => {
        togglePreview?.(!isClosed);
        updateRequest({ status: isClosed ? REQUEST_STATUS.SENT : REQUEST_STATUS.CLOSED });
      },
      text: isClosed ? 'Re-open Request' : 'Close Request'
    };
  }, [request.status, updateRequest, togglePreview]);

  const onRestoreRequest = useCallback(async () => {
    if (request.deletedAt)
      try {
        await restoreRequest(request);
        toast.success(`Restored Request`);
      } catch (err) {
        showError('Unable to restore request', err as Error);
      }
  }, [request, restoreRequest]);

  const restoreRequestAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: onRestoreRequest, text: 'Restore' }),
    [onRestoreRequest]
  );

  const onDeleteRequest = useCallback(async () => {
    try {
      await deleteRequest(request);
      toast.success(`Deleted Request`);
    } catch (err) {
      showError('Unable to delete request', err as Error);
    }
  }, [deleteRequest, request]);

  const deleteRequestAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: onDeleteRequest, text: 'Delete' }),
    [onDeleteRequest]
  );

  const remindAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: sendRequestReminder, text: 'Send Reminder' }),
    [sendRequestReminder]
  );

  const sendAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      onClick: () => sendRequest({ notify: [REQUEST_NOTIFY_OPTION.ALL] }),
      text: 'Send Request'
    }),
    [sendRequest]
  );

  const unsendAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      onClick: () => updateRequest({ status: REQUEST_STATUS.DRAFT }),
      text: 'Unsend Request'
    }),
    [updateRequest]
  );

  const options = useMemo(() => {
    const newOptions: IButtonDropdownOptionProps[] = [];
    if (!hideViewAction) newOptions.push(viewAction);
    if (!request.deletedAt) {
      if (request.status === REQUEST_STATUS.DRAFT) newOptions.push(sendAction);
      if (request.status === REQUEST_STATUS.SENT) newOptions.push(remindAction, unsendAction);

      if (request.status && [REQUEST_STATUS.SENT, REQUEST_STATUS.CLOSED].includes(request.status))
        newOptions.push(updateStatusAction);
    }

    newOptions.push(request.deletedAt ? restoreRequestAction : deleteRequestAction);

    return newOptions;
  }, [
    deleteRequestAction,
    hideViewAction,
    remindAction,
    request.deletedAt,
    request.status,
    restoreRequestAction,
    sendAction,
    unsendAction,
    updateStatusAction,
    viewAction
  ]);

  return (
    <Button
      dropdown={{ options, up: last }}
      text="ACTION"
      size="xl"
      loading={deletingRequest || restoringRequest || updatingRequest || sendingRequest || sendingRequestReminder}
    />
  );
};
