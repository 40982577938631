import { IFormWithStepperProps } from '../../../_core/form/form.types';
import { ICreateLocationsProps } from '../location.types';
import { useCreateLocations } from '../location.service';
import { CreateLocationsForm } from './create-locations-form.component';
import { logError } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';

interface ICreateLocationsNestedContainerProps extends IFormWithStepperProps {
  ignoreDefaults?: boolean;
}

export const CreateLocationsNestedContainer = ({
  ignoreDefaults,
  onError,
  onSuccess,
  ...rest
}: ICreateLocationsNestedContainerProps) => {
  const { createLocations } = useCreateLocations();

  const handleSubmit = async (props: ICreateLocationsProps) => {
    try {
      await resetFormMessages({ setSubmitError: onError, setSuccess: onSuccess });

      await createLocations(props);
      onSuccess('Successfully created locations');
    } catch (err) {
      const errMsg = 'Unable to create locations.';
      logError(errMsg, (err as Error).message);
      onError(errMsg);
    }
  };

  return <CreateLocationsForm ignoreDefaults={ignoreDefaults} onSubmit={handleSubmit} {...rest} />;
};
