import { IClientInvite, IMember } from '../../../../lib/types';
import React, { PropsWithChildren } from 'react';
import { TableWithActions } from '../../../_core/table/table-with-actions.component';
import { ITableRowValues } from '../../../_core/table/table.component';
import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';

interface IContactsTableProps extends PropsWithChildren, IAdminProps, IPersonalCompanyProps {
  onSelect: (_index: number, _selected?: boolean) => void;
  onSelectAll: (_selected: boolean) => void;
  selected?: boolean[];
  contacts: IContactsTableRowValues[];
  showEntity?: boolean;
}

export interface IContactsTableRowValues extends ITableRowValues {
  invite?: IClientInvite;
  member?: IMember;
}

export const ContactsTable: React.FC<IContactsTableProps> = ({
  children,
  isAdmin,
  isPersonalCompany,
  onSelect,
  onSelectAll,
  selected,
  contacts,
  showEntity
}) => {
  const headers = ['Name', 'Email', 'Status'];
  if (!isPersonalCompany) headers.push('Departments', 'Locations');
  if (showEntity) headers.push('Entity');
  if (isAdmin) headers.push('');
  headers.push('');

  return (
    <>
      <TableWithActions
        selectable
        loading={!contacts}
        headers={headers}
        values={contacts}
        selected={selected}
        onRowClick={onSelect}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
      >
        {children}
      </TableWithActions>
    </>
  );
};
