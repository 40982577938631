import { gql } from '@apollo/client';

const TEMPLATE_FRAGMENT = gql`
  fragment TemplateDetails on Template {
    _id
    blocks {
      data
      type
    }
    description
    title
    type
    version
  }
`;

const TEMPLATE_SUMMARY_FRAGMENT = gql`
  fragment TemplateSummary on Template {
    _id
    description
    title
    type
    version
  }
`;

export const GET_TEMPLATE = gql`
  query GetTemplate {
    template {
      ...TemplateDetails
    }
  }

  ${TEMPLATE_FRAGMENT}
`;

export const GET_TEMPLATES = gql`
  query GetTemplates($type: TemplateType!) {
    templates(type: $type) {
      ...TemplateDetails
    }
  }

  ${TEMPLATE_FRAGMENT}
`;

export const CREATE_TEMPLATE = gql`
  mutation CreateTemplate(
    $blocks: [RequestBlockTemplateInput!]!
    $version: String!
    $type: TemplateType!
    $title: String!
    $description: String
  ) {
    createTemplate(blocks: $blocks, version: $version, type: $type, title: $title, description: $description) {
      ...TemplateSummary
    }
  }

  ${TEMPLATE_SUMMARY_FRAGMENT}
`;

export const DELETE_TEMPLATE = gql`
  mutation DeleteTemplate($_id: ID!) {
    deleteTemplate(_id: $_id) {
      ...TemplateSummary
    }
  }

  ${TEMPLATE_SUMMARY_FRAGMENT}
`;

export const UPDATE_TEMPLATE = gql`
  mutation UpdateTemplate($_id: ID!, $title: String!, $description: String) {
    updateTemplate(_id: $_id, title: $title, description: $description) {
      ...TemplateSummary
    }
  }

  ${TEMPLATE_SUMMARY_FRAGMENT}
`;
