import { useFormFieldMeta } from './form-field-meta';

interface IFormLabelProps {
  label: string;
  name: string;
}

export const FormLabel = ({ label, name }: IFormLabelProps) => {
  const { hasError } = useFormFieldMeta(name);

  const fieldStateClasses = hasError ? 'text-red-600' : 'text-gray-900';

  return (
    <label htmlFor={name} className={`block text-sm font-medium leading-6 py-1.5 ${fieldStateClasses}`}>
      {label}
    </label>
  );
};
