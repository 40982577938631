import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../_routes';
import toast from 'react-hot-toast';
import { COMPLETE_ONBOARDING_KEY } from '../../../lib/const';

export const CompleteOnboardingToast = ({ name }: { name: string }) => {
  const navigate = useNavigate();

  return (
    <div
      className="cursor-pointer text-primary text-center"
      onClick={() => {
        navigate(ROUTE_PATHS.FIRM_PROFILE + `?${COMPLETE_ONBOARDING_KEY}=true`);
        toast.dismiss(COMPLETE_ONBOARDING_KEY);
      }}
    >
      Click to complete onboarding for {name}
    </div>
  );
};
