import { useEntities } from '../../../domains/entities/entity.service';
import { Loader } from '../../../_core/loader.component';
import { EntityTableNestedContainer } from './entity-table.nested.container';
import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';

export const EntityTableContainer: React.FC<IAdminProps & IPersonalCompanyProps> = (props) => {
  const { entities, loading: loadingEntities } = useEntities();

  if (loadingEntities) return <Loader />;

  return <EntityTableNestedContainer entities={entities} {...props} />;
};
