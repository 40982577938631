import { Loader } from '../../_core/loader.component';
import { IClient, IEntityMember, Identifiable } from '../../../lib/types';
import { Button } from '../../_core/button/button.component';
import { Tabs } from '../../_core/tabs.component';
import { DetailBox } from '../../_core/details/details-box.component';
import { DetailItem } from '../../_core/details/details-item.component';
import { activeStatus } from '../../../utils/wording.helpers';
import { EntityTableNestedContainer } from '../DashboardPage/entity-table/entity-table.nested.container';
import { IAdminProps, IPersonalCompanyProps } from '../../_core/core.types';

interface IClientProfilePageProps extends IAdminProps, IPersonalCompanyProps {
  deleteClient: (_: Identifiable) => void;
  deleting: boolean;
  client?: IClient;
  members?: IEntityMember[];
  restoreClient: (_: Identifiable) => void;
  restoring: boolean;
}

export const ClientProfilePage: React.FC<IClientProfilePageProps> = ({
  deleteClient,
  deleting,
  client,
  isAdmin,
  isPersonalCompany,
  members,
  restoreClient,
  restoring
}) => {
  if (!client)
    return (
      <div className="mx-auto">
        <Loader />
      </div>
    );

  return (
    <>
      <h2 className="font-bold text-2xl tracking-tight w-full">Client Information</h2>
      <DetailBox>
        <DetailItem label="Client" value={client.user.name} />
        <DetailItem label="Status" value={activeStatus(!client.deletedAt)} />
        {!isPersonalCompany && (
          <>
            <DetailItem label="Departments" value={client.department.map(({ name }) => name).join(', ')} />
            <DetailItem label="Locations" value={client.location.map(({ name }) => name).join(', ')} />
          </>
        )}
      </DetailBox>
      <DetailBox endAligned>
        {isAdmin && (
          <>
            <Button
              size="xl"
              text={`${client.deletedAt ? 'Una' : 'A'}rchive Client`}
              onClick={() => {
                if (client.deletedAt) restoreClient(client);
                else deleteClient(client);
              }}
              loading={client.deletedAt ? restoring : deleting}
            />
          </>
        )}
      </DetailBox>
      <div className="mt-4 flex flex-1 w-full">
        <DetailBox>
          <Tabs
            tabs={[
              {
                title: 'Entities',
                content: !members ? (
                  <Loader />
                ) : (
                  <EntityTableNestedContainer
                    editingDisabled
                    isAdmin={isAdmin}
                    entities={members.map(({ entity }) => entity)}
                  />
                )
              }
            ]}
          />
        </DetailBox>
      </div>
    </>
  );
};
