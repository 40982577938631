import { ToolConstructable, ToolSettings } from '@editorjs/editorjs/types/tools';
import { OutputData } from '@editorjs/editorjs';
import { ILabelBlockData } from '../_core/label-block';

export enum SectionType {
  QUESTION_GROUP = 'question-group',
  SECTION = 'section'
}

export interface ISectionData extends ILabelBlockData {
  outputData: OutputData;
  type: SectionType;
}

export interface IEditorToolsConfig {
  [toolName: string]: ToolConstructable | ToolSettings;
}
