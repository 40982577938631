import { gql } from '@apollo/client';

export const LOCATION_FRAGMENT = gql`
  fragment LocationDetails on Location {
    _id
    name
    deletedAt
    company {
      _id
    }
  }
`;

export const CREATE_LOCATIONS = gql`
  mutation CreateLocations($names: [String!]!) {
    createLocations(names: $names) {
      ...LocationDetails
    }
  }

  ${LOCATION_FRAGMENT}
`;

export const GET_COMPANY_LOCATIONS = gql`
  query GetCompanyLocations($includeArchived: Boolean) {
    locations(includeArchived: $includeArchived) {
      ...LocationDetails
    }
  }

  ${LOCATION_FRAGMENT}
`;

export const DELETE_LOCATION = gql`
  mutation DeleteLocation($_id: ID!) {
    deleteLocation(_id: $_id) {
      ...LocationDetails
    }
  }

  ${LOCATION_FRAGMENT}
`;

export const RESTORE_LOCATION = gql`
  mutation RestoreLocation($_id: ID!) {
    restoreLocation(_id: $_id) {
      ...LocationDetails
    }
  }

  ${LOCATION_FRAGMENT}
`;

export const UPDATE_LOCATION = gql`
  mutation UpdateLocation($_id: ID!, $name: String!) {
    updateLocation(_id: $_id, name: $name) {
      ...LocationDetails
    }
  }

  ${LOCATION_FRAGMENT}
`;
