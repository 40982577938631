import { PropsWithChildren } from 'react';
import { RIApolloProvider } from '../../../providers/graphql.provider';
import { RIAuth0Provider } from '../../../providers/auth.provider';

export const EditorApolloProvider = ({ children }: PropsWithChildren) => {
  return (
    <RIAuth0Provider>
      <RIApolloProvider>{children}</RIApolloProvider>
    </RIAuth0Provider>
  );
};
