import { IApolloCacheParams } from '../../../lib/cache.types';
import { mergeCacheLists } from '../../../lib/utils';
import { GET_ENTITY_MEMBERS } from './entity-members.queries';
import { IEntityMemberParams, IEntityMemberResults } from './entity-members.types';

export const updateEntityMemberCache = ({
  cache,
  entityMembers,
  variables
}: IApolloCacheParams & IEntityMemberResults & { variables: IEntityMemberParams }) => {
  const currMembers = cache.readQuery<IEntityMemberResults, IEntityMemberParams>({
    query: GET_ENTITY_MEMBERS,
    variables
  })?.entityMembers;

  if (currMembers)
    cache.writeQuery<IEntityMemberResults, IEntityMemberParams>({
      query: GET_ENTITY_MEMBERS,
      data: { entityMembers: mergeCacheLists(currMembers, entityMembers) },
      variables
    });
};
