import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { ICreateLocationsProps } from '../location.types';
import { FormInfo } from '../../../_core/form/form-info.component';
import { FormActionsRow } from '../../../_core/form/input/form-actions-row';

interface ICreateLocationsFormValues {
  names: string[];
}

interface ICreateLocationsFormProps extends IRIFormProps<ICreateLocationsProps> {
  ignoreDefaults?: boolean;
}

export const CreateLocationsForm = ({ ignoreDefaults, onBack, onSkip, onSubmit }: ICreateLocationsFormProps) => {
  const validateForm = (values: ICreateLocationsFormValues) => {
    const errors: FormikErrors<ICreateLocationsFormValues> = {};

    if (!values.names.filter((name) => !!name.trim().length).length) {
      errors.names = 'At least one value required';
    }

    return errors;
  };

  return (
    <Formik
      initialValues={{ names: ignoreDefaults ? [] : ['Head Office'] } as ICreateLocationsFormValues}
      onSubmit={(values) => onSubmit({ names: values.names.filter((name) => name.trim()) })}
      validate={validateForm}
    >
      {(props: FormikProps<ICreateLocationsFormValues>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-6 mt-10">
          <FormInfo
            title="Please set up locations:"
            messages={[
              'Locations allow you to organize your firm by individual offices.',
              'For example, Head Office or the name of the city your office operates from.',
              'If you are not sure how you want to organize things yet you can skip this step.'
            ]}
          />
          <FormInputWithLabel
            id="names"
            name="names"
            label="Location Names"
            placeholder="Add location name"
            value={props.values.names}
            type="tags"
            tagsProps={{ previewLabelPrefix: 'Add location' }}
          />
          <FormActionsRow>
            {!!onBack && <FormButton onPress={onBack} text="Back" type="button" variant="secondary" />}
            {!!onSkip && <FormButton onPress={onSkip} text="Skip" type="button" variant="secondary" />}
            <FormButton loading={props.isSubmitting} text="Next" />
          </FormActionsRow>
        </Form>
      )}
    </Formik>
  );
};
