import { BlockToolConstructorOptions } from '@editorjs/editorjs/types/tools';
import { createRoot } from 'react-dom/client';
import { QuestionType } from './question.types';
import { QuestionTypeSelect } from './question-type-select';
import { inputClasses, inputErrorClasses } from '../_core/editor.const';
import { ICreateTemplateBlockConfig } from '../_core/create-template-block';
import { renderWithBlockFocusWrapper } from '../_core/utils/editor.utils';
import { LocalTitleBlockTool } from '../_core/local-title-block/local-title-block.component';
import { localTitleBlockExport, localTitleBlockImport } from '../_core/utils/block.utils';
import { ILabelBlockData } from '../_core/label-block';
import { onReadonlyInputKeydown } from '../_core/utils/input.utils';

interface QuestionData extends ILabelBlockData {
  isValid: boolean;
  value: string;
  type: QuestionType;
}

export class Question extends LocalTitleBlockTool<QuestionData, ICreateTemplateBlockConfig> {
  private error = '';

  private answerId: string;
  private errorId: string;
  private typeSelectId: string;

  constructor(props: BlockToolConstructorOptions<QuestionData>) {
    const { data, ...rest } = props;
    const newData = {
      ...data,
      isValid: data.isValid ?? true,
      type: data.type ?? QuestionType.TEXT
    };

    super({ ...rest, data: newData });

    this.answerId = 'answer-' + this.uid;
    this.errorId = 'error-' + this.uid;
    this.typeSelectId = 'type-select-' + this.uid;
  }

  static get sanitize() {
    // disallow HTML
    return { ...super.sanitize, value: false };
  }

  static get toolbox() {
    return {
      title: 'Question',
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" /></svg>'
    };
  }

  render() {
    const container = document.createElement('div');

    this.wrapper = document.createElement('div');
    this.wrapper.classList.add('flex', 'gap-4', 'overflow-x-auto');
    this.wrapper.appendChild(this.renderAnswerInput());

    // Add required UX components, if element is required
    const indicator = this.renderRequiredIndicator();
    if (indicator) this.wrapper.appendChild(indicator);

    const completedCheckbox = this.renderCompletedCheckbox();
    if (completedCheckbox) this.wrapper.appendChild(completedCheckbox);

    const errorContent = document.createElement('div');
    const errorMessage = document.createElement('span');
    errorMessage.id = this.errorId;
    errorMessage.classList.add('my-1.5', 'text-red-500', 'text-sm', 'hidden');
    errorContent.appendChild(errorMessage);

    // Render content with settings and local title content prepened
    container.appendChild(this.renderWithLocalTitle());
    container.appendChild(errorContent);

    return renderWithBlockFocusWrapper(container);
  }

  private onAnswerChange({ target }: Event) {
    const targetE = target as HTMLInputElement | HTMLTextAreaElement;
    this.data.value = targetE.value.trim();
    this.data.filled = !!this.data.value;
    this.validate(this.data);
    this.toggleRequiredIndicator({ checked: this.data.filled });
  }

  // Render answer input, should always be editable because either client or staff can edit
  private renderAnswerInput() {
    const answerContainer = document.createElement('div');
    answerContainer.classList.add('answer-container', 'flex', 'w-full');

    let answerInput: HTMLInputElement | HTMLTextAreaElement;
    if (this.data.type === QuestionType.TEXTAREA) {
      answerInput = document.createElement('textarea');
      answerInput.classList.add('resize');
    } else {
      answerInput = document.createElement('input');
      if (this.data.type === QuestionType.CURRENCY) {
        answerInput.type = 'number';
        if (this.api.readOnly.isEnabled) {
          answerInput.classList.add('rounded-l-none');

          const prefix = document.createElement('span');
          prefix.classList.add(
            'inline-flex',
            'items-center',
            'px-3',
            'text-sm',
            'text-gray-900',
            'bg-gray-200',
            'border',
            'rounded-s-md',
            'border-gray-300'
          );
          prefix.textContent = '$';
          answerContainer.appendChild(prefix);
        }
      } else if (this.data.type === QuestionType.DATE) {
        answerInput.type = 'date';
      } else if (this.data.type === QuestionType.EMAIL) {
        answerInput.type = 'email';
      } else if (this.data.type === QuestionType.NUMBER) {
        answerInput.type = 'number';
      } else if (this.data.type === QuestionType.PHONE) {
        answerInput.type = 'tel';
      }
    }

    if (!this.api.readOnly.isEnabled) {
      answerInput.classList.add('rounded-l-none');

      const prefix = document.createElement('div');
      // prefix.classList.add('relative');
      prefix.id = this.typeSelectId;

      const root = createRoot(prefix);
      root.render(
        <QuestionTypeSelect
          type={this.data.type}
          onChange={(selected) => {
            this.data.type = selected;
            this.wrapper?.replaceChild(
              this.renderAnswerInput(),
              this.wrapper.querySelector('.answer-container') as Node
            );
            this.validate(this.data);
          }}
        />
      );

      answerContainer.prepend(prefix);
    }

    answerInput.id = this.answerId;
    answerInput.classList.add('answer-input', 'w-full', ...inputClasses);
    answerInput.placeholder = '< Enter answer >';
    answerInput.value = this.data.value ?? '';
    answerInput.disabled = this.config.disabled;

    answerInput.oninput = (e) => this.onAnswerChange(e);
    answerInput.onchange = (e) => this.onAnswerChange(e);

    if (this.config.readOnly) answerInput = onReadonlyInputKeydown(answerInput);

    answerContainer.appendChild(answerInput);

    return answerContainer;
  }

  async save(blockContent: HTMLDivElement): Promise<QuestionData> {
    const answer = blockContent.querySelector('.answer-input') as HTMLInputElement | HTMLTextAreaElement;

    // Prep base block data
    const newData = await super.save(blockContent);

    // Determine question type
    const typeSelectContainer = blockContent.querySelector('#' + this.typeSelectId) as HTMLDivElement;
    if (typeSelectContainer) {
      const typeSelect = typeSelectContainer.querySelector('input') as HTMLInputElement;
      newData.type = (typeSelect?.value as QuestionType) ?? newData.type;
    }

    return {
      ...newData,
      filled: !!answer.value,
      value: answer.value
    };
  }

  // TODO: Need to determine this on first render so we can determine if we need to show the error message after new validation is configured on client
  private toggleError() {
    const answerInput = document.getElementById(this.answerId) as HTMLInputElement | HTMLTextAreaElement | null;
    const errorMessage = document.getElementById(this.errorId) as HTMLSpanElement | null;
    if (!this.data.isValid && this.error) {
      if (errorMessage) {
        errorMessage.textContent = this.error;
        errorMessage.classList.remove('hidden');
      }
      answerInput?.classList.add(...inputErrorClasses);
    } else {
      const errorMessage = document.getElementById(this.errorId);
      errorMessage?.classList.add('hidden');
      answerInput?.classList.remove(...inputErrorClasses);
    }
  }

  validate({ type, value, ...rest }: QuestionData) {
    if (!super.validate(rest)) return false;
    let result = false;

    if (type) {
      if (!value) result = true;

      if (!result)
        switch (type) {
          case QuestionType.PHONE:
          case QuestionType.TEXT:
          case QuestionType.TEXTAREA:
            result = type !== QuestionType.PHONE || !!value.match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/);
            if (!result) this.error = 'Invalid phone number';
            break;
          case QuestionType.NUMBER:
          case QuestionType.CURRENCY:
            result = !!value.match(/^-?\d+(.\d*)*$/);
            if (!result) this.error = 'Invalid number';
            break;
          case QuestionType.EMAIL:
            result = !!value.match(
              /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
            );
            if (!result) this.error = 'Invalid email';
            break;
          case QuestionType.DATE:
            result = !!value.match(/^\d{4}-\d{2}-\d{2}$/);
            if (!result) this.error = 'Invalid date';
            break;
          default:
            // Unknown type, this should never happen so assume invalid
            result = false;
            this.error = 'Unknown question type';
            break;
        }

      this.data.isValid = result;
      this.toggleError();
      return true;
    }

    return false;
  }

  static get conversionConfig() {
    return { export: localTitleBlockExport, import: localTitleBlockImport };
  }
}
