import { Button } from '../button/button.component';
import { Bars3Icon } from '@heroicons/react/20/solid';
import { useLogout } from '../../domains/auth/auth.service';

interface ILockedDialogActionsProps {
  locked?: boolean;
}

/*
 * We want to allow certain actions to be taken at all times.
 * For locked dialogs the user is unable to interact with the rest of the application,
 * so we provide a way to perform those critical actions directly from the dialog.
 */
export const LockedDialogActions = ({ locked }: ILockedDialogActionsProps) => {
  const { handleLogout, loading } = useLogout();

  if (!locked) return null;
  return (
    <div className="mt-2">
      <Button
        dropdown={{ hideIcon: true, options: [{ text: 'Sign out', onClick: () => handleLogout() }] }}
        fit="right"
        icon={<Bars3Icon height={20} width={20} />}
        loading={loading}
        size="medium"
        variant="secondary"
      />
    </div>
  );
};
