import { useAuth0 } from '@auth0/auth0-react';

// Components
import { useFetchCurrUser } from '../../domains/users/user.service';
import { DashboardPage } from './dashboard-page.component';
import { useCurrMember } from '../../domains/member/member.service';
import { useCurrCompany } from '../../domains/companies/company.service';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../_core/loader.component';

export const DashboardPageContainer = () => {
  const { user: authUser } = useAuth0();
  const navigate = useNavigate();

  const { isPersonal } = useCurrCompany();
  const { isAdmin } = useCurrMember();
  const { user, loading: loadingUser } = useFetchCurrUser();

  if (!authUser || (loadingUser && !user)) return <Loader />;

  return <DashboardPage isAdmin={isAdmin} isPersonalCompany={isPersonal} navigate={navigate} />;
};
