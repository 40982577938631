import { useState } from 'react';
import { FormContainer } from '../../../_core/form/form-container.component';
import { SelectLocationsForm } from './select-locations-form.component';
import { ILocation } from '../../../../lib/types';
import { ISelectedIDProps } from '../../../../lib/query.types';
import { useUpdateInvites } from '../../invite/invite.service';
import { useUpdateMembers } from '../../member/member.service';
import { IStaffTableMultiselectActionProps } from '../../../_pages/FirmProfilePage/staff-table/staff-table-multiselect-action-button/staff-table-multiselect-action-button.types';
import { logError } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';

interface ISelectLocationsContainerProps extends IStaffTableMultiselectActionProps {
  locations: ILocation[];
}

export const SelectLocationsContainer = ({
  locations,
  getSelectedModels,
  setSelected
}: ISelectLocationsContainerProps) => {
  const { updateInvites } = useUpdateInvites();
  const { updateMembers } = useUpdateMembers();

  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const handleSubmit = async (props: ISelectedIDProps) => {
    try {
      await resetFormMessages({ setSubmitError, setSuccess });

      const selected = getSelectedModels();
      if (!selected) setSubmitError('Unable to determine selected');
      else {
        await Promise.all([
          updateInvites({ ids: selected.selectedInvites.map(({ _id }) => _id), location: props.ids }),
          updateMembers({ ids: selected.selectedMembers.map(({ _id }) => _id), location: props.ids })
        ]);
        setSuccess('Successfully selected locations');
        setSelected((s) => (s ? s.map(() => false) : null));
      }
    } catch (err) {
      const errMsg = 'Unable to assign selected locations.';
      logError(errMsg, (err as Error).message);
      setSubmitError(errMsg);
    }
  };

  return (
    <FormContainer
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      title="Select Locations"
    >
      <SelectLocationsForm locations={locations} onSubmit={handleSubmit} />
    </FormContainer>
  );
};
