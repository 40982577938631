import { useCallback, useState } from 'react';
import { RegisterCompanyContainer } from './register-company-form.container';
import { FormStepper } from '../../../_core/form/stepper/form-stepper.component';
import { CreateLocationsNestedContainer } from '../../locations/create/create-locations-form-nested.container';
import { CreateDepartmentsNestedContainer } from '../../departments/create/create-departments-form-nested.container';
import { SendInvitesNestedContainer } from '../../invite/send/send-invites-form-nested.container';
import { EditRequestTypesNestedContainer } from '../edit-request-types/edit-request-types-form-nested.container';
import { IFormFinishProps, IFormWithStepperProps } from '../../../_core/form/form.types';
import { sleep } from '../../../../lib/utils';
import { ICloseableDialogProps } from '../../../_core/core.types';
import { Button } from '../../../_core/button/button.component';

const steps = ['Firm Details', 'Locations', 'Departments', 'Types', 'Staff'];

interface IRegisterCompanySteps extends IFormWithStepperProps, IFormFinishProps, ICloseableDialogProps {
  contentRef: React.RefObject<HTMLDivElement>;
  initialStep?: number;
  completeOnboarding: () => void;
}

export const RegisterCompanySteps = ({
  contentRef,
  initialStep = 0,
  onBack: firstStepOnBack,
  onFinish,
  onSuccess,
  onError,
  completeOnboarding,
  close
}: IRegisterCompanySteps) => {
  const [activeStep, setActiveStep] = useState(initialStep);

  const updateActiveStep = useCallback(
    (nextStep: number) => {
      setActiveStep(nextStep);
      if (onFinish && nextStep === steps.length) onFinish();
    },
    [onFinish]
  );

  const handleSuccess = useCallback(
    (msg: string | null, nextStep: number) => {
      onError(null);
      onSuccess(msg);
      updateActiveStep(nextStep);
      sleep(500).then(() => onSuccess(null));
    },
    [onError, onSuccess, updateActiveStep]
  );

  /*
   * Can go back from create company step to the initial stepper select create/join option.
   * But steps 1-4 are all actions on an already created firm.
   * So once we are there then we should not be able to backtrack beyond that range.
   */
  return (
    <>
      <FormStepper containerRef={contentRef} steps={steps} activeStep={activeStep} />
      {activeStep === 0 && (
        <RegisterCompanyContainer
          onBack={firstStepOnBack}
          onError={onError}
          onSuccess={(msg) => handleSuccess(msg, 1)}
        />
      )}
      {activeStep === 1 && (
        <CreateLocationsNestedContainer
          onError={onError}
          onSkip={() => updateActiveStep(2)}
          onSuccess={(msg) => handleSuccess(msg, 2)}
        />
      )}
      {activeStep === 2 && (
        <CreateDepartmentsNestedContainer
          onBack={() => setActiveStep(1)}
          onError={onError}
          onSkip={() => updateActiveStep(3)}
          onSuccess={(msg) => handleSuccess(msg, 3)}
        />
      )}
      {activeStep === 3 && (
        <EditRequestTypesNestedContainer
          onBack={() => setActiveStep(2)}
          onError={onError}
          onSkip={() => updateActiveStep(4)}
          onSuccess={(msg) => handleSuccess(msg, 4)}
        />
      )}
      {activeStep === 4 && (
        <SendInvitesNestedContainer
          onBack={() => setActiveStep(3)}
          onError={onError}
          onSkip={() => {
            completeOnboarding();
            onSuccess('Completed firm registration!');
            updateActiveStep(5);
          }}
          onSuccess={(msg) => {
            completeOnboarding();
            handleSuccess(msg, 5);
          }}
        />
      )}
      {activeStep > 4 && (
        <div className="min-h-48 flex items-center justify-center">
          <Button onClick={close} size="large">
            Done
          </Button>
        </div>
      )}
    </>
  );
};
