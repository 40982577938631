import { IRequestType } from '../../../../lib/types';
import React, { useState } from 'react';
import { RIDialog } from '../../../_core/dialog/dialog.component';
import { TableWithActions } from '../../../_core/table/table-with-actions.component';
import { Button } from '../../../_core/button/button.component';
import { activeStatus } from '../../../../utils/wording.helpers';
import { EditRequestTypesContainer } from '../../../domains/companies/edit-request-types/edit-request-types-form.container';
import { UpdateRequestTypeContainer } from '../../../domains/request-type/update/update-request-type-form.container';
import { ITableRowValues } from '../../../_core/table/table.component';
import { IAdminProps } from '../../../_core/core.types';

export interface IRequestTypeTableProps extends IAdminProps {
  requestTypes: IRequestType[];
}

export const RequestTypeTable: React.FC<IRequestTypeTableProps> = ({ isAdmin, requestTypes }) => {
  const [editRequestTypes, setEditRequestTypes] = useState(false);
  const [updateRequestType, setUpdateRequestType] = useState<IRequestType | null>(null);

  const headers = ['Name', 'Status'];
  if (isAdmin) headers.push('');

  return (
    <>
      <TableWithActions
        headers={headers}
        values={
          requestTypes.map(({ company, type, deletedAt }, i) => {
            const row: ITableRowValues = {
              values: [{ children: type }, { children: activeStatus(!deletedAt) }]
            };

            if (isAdmin)
              row.values.push({
                children: company ? (
                  <Button size="large" text="Edit" onClick={() => setUpdateRequestType(requestTypes[i])} />
                ) : null
              });

            return row;
          }) ?? []
        }
      >
        {isAdmin && <Button onClick={() => setEditRequestTypes(true)} text="Edit Request Types" size="large" />}
      </TableWithActions>
      {isAdmin && (
        <>
          <RIDialog open={editRequestTypes} setOpen={setEditRequestTypes}>
            <EditRequestTypesContainer />
          </RIDialog>
          {!!updateRequestType && (
            <RIDialog open={!!updateRequestType} setOpen={(o) => setUpdateRequestType((v) => (o ? v : null))}>
              <UpdateRequestTypeContainer {...updateRequestType} />
            </RIDialog>
          )}
        </>
      )}
    </>
  );
};
