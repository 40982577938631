import { InternalRefetchQueriesInclude } from '@apollo/client';
import { GET_CURR_COMPANY, GET_USER_COMPANIES } from './company.queries';
import { GET_COMPANY_DEPARTMENTS } from '../departments/department.queries';
import { GET_COMPANY_INVITES } from '../invite/invite.queries';
import { GET_COMPANY_LOCATIONS } from '../locations/location.queries';
import { GET_COMPANY_REQUEST_TYPES, GET_SELECTED_REQUEST_TYPES } from '../request-type/request-type.queries';
import { GET_MEMBERS } from '../member/member.queries';
import { INVITE_STATUS } from '../../../lib/types';
import { GET_ENTITIES } from '../entities/entity.queries';
import { GET_CLIENTS } from '../clients/client.queries';
import { GET_CLIENT_INVITES } from '../client-invite/client-invite.queries';
import { GET_REQUEST } from '../request/request.queries';

export const REGISTER_COMPANY_STEPPER_ID = 'register-company-stepper';

export const CURR_COMPANY_REFETCH_QUERIES: InternalRefetchQueriesInclude = [
  { query: GET_CURR_COMPANY },
  { query: GET_COMPANY_DEPARTMENTS, variables: { includeArchived: true } },
  { query: GET_COMPANY_INVITES, variables: { status: INVITE_STATUS.PENDING } },
  { query: GET_COMPANY_LOCATIONS, variables: { includeArchived: true } },
  { query: GET_COMPANY_REQUEST_TYPES },
  { query: GET_SELECTED_REQUEST_TYPES },
  { query: GET_MEMBERS },
  { query: GET_ENTITIES, variables: { includeArchived: true } },
  { query: GET_CLIENTS, variables: { includeArchived: true } },
  { query: GET_CLIENT_INVITES, variables: { status: INVITE_STATUS.PENDING } },
  { query: GET_REQUEST }
];

export const USER_COMPANIES_REFETCH_QUERIES: InternalRefetchQueriesInclude = [
  ...CURR_COMPANY_REFETCH_QUERIES,
  { query: GET_USER_COMPANIES, variables: { includeArchived: true } }
];
