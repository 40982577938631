import { useState } from 'react';
import { Button } from '../../../../_core/button/button.component';
import { useCompanyDepartments } from '../../../../domains/departments/department.service';
import { useDeleteStaffAction } from './staff-table-delete-action';
import { IStaffTableMultiselectActionProps } from './staff-table-multiselect-action-button.types';
import { IButtonDropdownOptionProps } from '../../../../_core/button/button-dropdown-option-component';
import { RIDialog } from '../../../../_core/dialog/dialog.component';
import { SelectDepartmentsContainer } from '../../../../domains/departments/select/select-departments-form.container';
import { useCompanyLocations } from '../../../../domains/locations/location.service';
import { SelectLocationsContainer } from '../../../../domains/locations/select/select-locations-form.container';
import { SelectRolesContainer } from '../../../../domains/staff/select-roles/select-roles-form.container';
import { useResendInviteAction } from './staff-table-resend-invite-action';

interface IStaffTableMultiselectActionButtonProps extends IStaffTableMultiselectActionProps {
  selected: boolean[];
}

export const StaffTableMultiselectActionButton: React.FC<IStaffTableMultiselectActionButtonProps> = ({
  selected,
  ...actionProps
}) => {
  const [showEditDepartmentsModal, setShowEditDepartmentsModal] = useState(false);
  const [showEditLocationsModal, setShowEditLocationsModal] = useState(false);
  const [showEditRolesModal, setShowEditRolesModal] = useState(false);

  const { departments } = useCompanyDepartments();
  const { locations } = useCompanyLocations();

  const deleteStaffAction = useDeleteStaffAction(actionProps);
  const resendInviteAction = useResendInviteAction(actionProps);

  const editStaffDepartmentsAction: IButtonDropdownOptionProps = {
    disabled: !departments?.length,
    onClick: () => setShowEditDepartmentsModal(true),
    text: 'Edit Departments'
  };

  const editStaffLocationsAction: IButtonDropdownOptionProps = {
    disabled: !locations?.length,
    onClick: () => setShowEditLocationsModal(true),
    text: 'Edit Locations'
  };

  const editStaffRolesAction: IButtonDropdownOptionProps = {
    onClick: () => setShowEditRolesModal(true),
    text: 'Edit Roles'
  };

  return (
    <>
      <Button
        disabled={!selected.some((s) => s)}
        dropdown={{
          options: [
            editStaffDepartmentsAction,
            editStaffLocationsAction,
            editStaffRolesAction,
            resendInviteAction,
            deleteStaffAction
          ]
        }}
        text="ACTION"
        size="large"
      />
      {!!departments && (
        <RIDialog open={showEditDepartmentsModal} setOpen={setShowEditDepartmentsModal}>
          <SelectDepartmentsContainer departments={departments} {...actionProps} />
        </RIDialog>
      )}
      {!!locations && (
        <RIDialog open={showEditLocationsModal} setOpen={setShowEditLocationsModal}>
          <SelectLocationsContainer locations={locations} {...actionProps} />
        </RIDialog>
      )}
      <RIDialog open={showEditRolesModal} setOpen={setShowEditRolesModal}>
        <SelectRolesContainer {...actionProps} />
      </RIDialog>
    </>
  );
};
