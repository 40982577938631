import { logError } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';
import { IFormWithStepperProps } from '../../../_core/form/form.types';
import { useCreateDepartments } from '../department.service';
import { ICreateDepartmentProps } from '../department.types';
import { CreateDepartmentsForm } from './create-departments-form.component';

interface ICreateDepartmentsNestedContainerProps extends IFormWithStepperProps {
  ignoreDefaults?: boolean;
}

export const CreateDepartmentsNestedContainer = ({
  ignoreDefaults,
  onError,
  onSuccess,
  ...rest
}: ICreateDepartmentsNestedContainerProps) => {
  const { createDepartments } = useCreateDepartments();

  const handleSubmit = async (props: ICreateDepartmentProps) => {
    try {
      await resetFormMessages({ setSubmitError: onError, setSuccess: onSuccess });

      await createDepartments(props);
      onSuccess('Successfully created departments');
    } catch (err) {
      const errMsg = 'Unable to create departments.';
      logError(errMsg, (err as Error).message);
      onError(errMsg);
    }
  };

  return <CreateDepartmentsForm ignoreDefaults={ignoreDefaults} onSubmit={handleSubmit} {...rest} />;
};
