import { gql } from '@apollo/client';
import { USER_FRAGMENT } from '../users/user.queries';
import { ENTITY_FRAGMENT } from '../entities/entity.queries';

export const ENTITY_MEMBER_FRAGMENT = gql`
  fragment EntityMemberDetails on EntityMember {
    _id
    deletedAt
    entity {
      ...EntityDetails
    }
    user {
      ...UserDetails
    }
  }

  ${ENTITY_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const DELETE_ENTITY_MEMBER = gql`
  mutation DeleteEntityMember($_id: ID!) {
    deleteEntityMember(_id: $_id) {
      ...EntityMemberDetails
    }
  }

  ${ENTITY_MEMBER_FRAGMENT}
`;

export const GET_ENTITY_MEMBERS = gql`
  query EntityMembers($entityId: ID, $userId: ID) {
    entityMembers(entityId: $entityId, userId: $userId) {
      ...EntityMemberDetails
    }
  }

  ${ENTITY_MEMBER_FRAGMENT}
`;

export const RESTORE_ENTITY_MEMBER = gql`
  mutation RestoreEntityMember($_id: ID!) {
    restoreEntityMember(_id: $_id) {
      ...EntityMemberDetails
    }
  }

  ${ENTITY_MEMBER_FRAGMENT}
`;
