import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';

interface IUpdateRequestTypeFormValues {
  type: string;
}

interface IUpdateRequestTypeFormProps extends IRIFormProps<IUpdateRequestTypeFormValues> {
  type: string;
}

export const UpdateRequestTypeForm = ({ type, onSubmit }: IUpdateRequestTypeFormProps) => {
  const validateForm = (values: IUpdateRequestTypeFormValues) => {
    const errors: FormikErrors<IUpdateRequestTypeFormValues> = {};

    if (!values.type.trim()) {
      errors.type = 'Required';
    }

    return errors;
  };

  return (
    <Formik
      initialValues={{ type } as IUpdateRequestTypeFormValues}
      onSubmit={(values) => onSubmit({ type: values.type.trim() })}
      validate={validateForm}
    >
      {(props: FormikProps<IUpdateRequestTypeFormValues>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-6 mt-10">
          <FormInputWithLabel
            id="type"
            name="type"
            label="Type"
            placeholder="Edit request type"
            value={props.values.type}
          />
          <FormButton loading={props.isSubmitting} text="Update" />
        </Form>
      )}
    </Formik>
  );
};
