export const onReadonlyInputKeydown = (input: HTMLInputElement | HTMLTextAreaElement) => {
  input.onkeydown = (e) => {
    let handled = false;
    if ((e.ctrlKey || e.metaKey) && e.target) {
      if (e.key === 'a') {
        let target: HTMLInputElement | HTMLTextAreaElement;
        if (e.target instanceof HTMLInputElement) target = e.target as HTMLInputElement;
        else target = e.target as HTMLTextAreaElement;

        target.select();
        handled = true;
      }
    }

    if (handled) {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
    }
  };
  return input;
};
