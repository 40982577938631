import { useCallback } from 'react';
import download from 'downloadjs';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { showError } from '../../../lib/utils';
import { Button } from '../../_core/button/button.component';
import { useDownloadAsset } from './asset.service';
import { EditorApolloProvider } from '../../_editor/_core/editor-apollo-provider';
import { UploadItemData } from '../../_editor/upload/upload.types';
import { IRequestTokenParams } from '../../../lib/types';

interface IDownloadAssetButtonProps extends UploadItemData, IRequestTokenParams {
  getLatestFileLabel: (_: string) => string | null;
}

const DownloadAssetButton = ({ getLatestFileLabel, requestToken, ...itemData }: IDownloadAssetButtonProps) => {
  const { downloadAsset } = useDownloadAsset(itemData, requestToken);

  const handleDownload = useCallback(
    ({ useOriginalFileName }: { useOriginalFileName?: boolean }) => {
      const fileName = useOriginalFileName
        ? itemData.fileName
        : getLatestFileLabel(itemData.fileId) || itemData.fileName;

      downloadAsset(fileName)
        .then(({ data }) => download(data, fileName))
        .catch((err) => showError('Failed to download ' + fileName, err));
    },
    [downloadAsset, getLatestFileLabel, itemData.fileId, itemData.fileName]
  );

  return (
    <Button
      fit="right"
      hideEndMargin
      icon={<ArrowDownTrayIcon height={16} width={16} />}
      variant="secondary"
      dropdown={{
        options: [
          { text: 'With original filenames', onClick: () => handleDownload({ useOriginalFileName: true }) },
          { text: 'With labels as file names', onClick: () => handleDownload({}) }
        ],
        hideIcon: true,
        hidePadding: true,
        ignoreRelativeContainer: true,
        maxWidth: 'max-w-52'
      }}
    />
  );
};

export const DownloadAssetButtonContainer = (props: IDownloadAssetButtonProps) => {
  return (
    <EditorApolloProvider>
      <DownloadAssetButton {...props} />
    </EditorApolloProvider>
  );
};
