import { LIST_REQUESTS_AS_STAFF } from './request.queries';
import { IApolloCacheParams } from '../../../lib/cache.types';
import { IListRequestAsStaffResults } from './request.types';

export const readRequestsCache = ({ cache }: IApolloCacheParams) =>
  cache.readQuery<IListRequestAsStaffResults>({ query: LIST_REQUESTS_AS_STAFF })?.listRequestsAsStaff;

export const writeRequestsCache = ({ cache, listRequestsAsStaff }: IApolloCacheParams & IListRequestAsStaffResults) => {
  cache.writeQuery({ query: LIST_REQUESTS_AS_STAFF, data: { listRequestsAsStaff } });
};
