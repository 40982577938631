import { Outlet } from 'react-router-dom';
import { IUxVariantProps } from '../_core/core.types';
import { PageHeader } from '../_core/page-header/page-header.component';

export interface IDefaultPageLayoutProps extends React.PropsWithChildren, IUxVariantProps {
  unauthenticatedMode?: boolean;
}

export const DefaultPageLayout = ({ children, dark, unauthenticatedMode }: IDefaultPageLayoutProps) => {
  return (
    <div id="default-layout" className={`flex flex-1 flex-col ${dark ? 'bg-secondary' : ''}`}>
      <PageHeader unauthenticatedMode={unauthenticatedMode} />

      {children}
    </div>
  );
};

export const DefaultPageLayoutWithOutlet = (props: IDefaultPageLayoutProps) => {
  return (
    <DefaultPageLayout {...props}>
      <Outlet />
    </DefaultPageLayout>
  );
};
