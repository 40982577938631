import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { useFormFieldMeta } from '../form-field-meta';
import { IFormInputProps } from './form-input.component';
import { Tag } from '../../tag.component';
import { useOnClickOutside } from '../../utils/click';
import { UpDownIcon } from '../../icon/icon.components';

export interface IFormMultiSelectOption {
  isNew?: boolean;
  label?: string;
  value: string;
}

export interface IFormMultiSelectInputProps {
  canAdd?: boolean;
  options?: IFormMultiSelectOption[];
  singleSelect?: boolean;
}

interface IFormMultiSelectInputHiddenProps {
  error?: React.ReactNode;
  inputClasses: string;
}

const SELECT_ALL = 'Select All';

export const FormMultiSelectInput = ({
  canAdd,
  error,
  inputClasses,
  name,
  options = [],
  placeholder = 'Select one or more options',
  singleSelect
}: IFormMultiSelectInputProps & IFormInputProps & IFormMultiSelectInputHiddenProps) => {
  const {
    value: formValue,
    helpers: { setTouched, setValue: setFormValue }
  } = useFormFieldMeta<IFormMultiSelectOption[]>(name);

  const [hasBeenTouched, setHasBeenTouched] = useState(false);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const handleClose = () => {
    setOpen(false);
    setSearch('');
  };

  // On click outside of the component, close the dropdown
  const ref = useRef(null);
  const handleClickOutside = useCallback(() => {
    if (hasBeenTouched) {
      setTouched(true);
      handleClose();
    }
  }, [hasBeenTouched, setTouched]);

  useOnClickOutside(ref, handleClickOutside);

  // Add select all option, and reduce shown options to only those that match the search string
  const optionsWithAll = useMemo(
    () => (options.length && !singleSelect ? [{ value: 'Select All' }, ...options] : options),
    [options, singleSelect]
  );
  const filteredOptions = useMemo(() => {
    const filtered = optionsWithAll.filter((option) => {
      const textToMatch = option.label ?? option.value;
      if (textToMatch.toLowerCase().includes(search.toLowerCase())) {
        return true;
      }

      return false;
    });

    if (canAdd && search) return [{ isNew: true, label: `Add "${search}"`, value: search }, ...filtered];
    return filtered;
  }, [canAdd, search, optionsWithAll]);

  const handleSelect = useCallback(
    (initialSelected: IFormMultiSelectOption | IFormMultiSelectOption[]) => {
      if (singleSelect) {
        // Handle single select
        const selected = initialSelected as IFormMultiSelectOption;
        setFormValue(formValue.length && formValue[0] === selected ? [] : [selected]);
        handleClose();
      } else {
        // Handle multiple select
        const selected = initialSelected as IFormMultiSelectOption[];
        let selectedWithoutDuplicates: IFormMultiSelectOption[] = [];
        for (let i = 0; i < selected.length; i++) {
          const option = selected[i];
          if (!selectedWithoutDuplicates.find(({ value }) => value === option.value))
            selectedWithoutDuplicates.push(option);
          else {
            selectedWithoutDuplicates = selectedWithoutDuplicates.filter(({ value }) => value !== option.value);
          }
        }

        // Handle selecting all options if the select all option was selected
        if (selectedWithoutDuplicates.find(({ value }) => value === SELECT_ALL)) {
          const newSelected = selectedWithoutDuplicates.filter(({ value }) => value !== SELECT_ALL);
          options.forEach((option) => {
            if (!newSelected.find(({ value }) => value === option.value)) newSelected.push(option);
          });
          setFormValue(newSelected);
          handleClose();
        } else {
          setFormValue(selectedWithoutDuplicates);
          if (filteredOptions.length === 1) handleClose();
        }
      }
    },
    [singleSelect, setFormValue, formValue, options, filteredOptions.length]
  );

  const handleRemove = useCallback(
    (tag: IFormMultiSelectOption) => {
      setFormValue(formValue.filter((t) => t !== tag));
    },
    [formValue, setFormValue]
  );

  // Show the dropdown if the input is opened manually or if there is a search string
  const showOptions = open || search.length > 0;

  const renderComboBoxContents = () => (
    <div className="relative mt-1">
      <div className="flex w-full cursor-default overflow-hidden">
        {/* Single select input value is not shown until a form interaction, even though the value is properly set */}
        <Combobox.Input
          className={`w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 ${inputClasses}`}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={placeholder}
          type="text"
          value={search ? search : singleSelect && formValue.length ? formValue[0].label ?? formValue[0].value : ''}
        />
        <Combobox.Button
          className="absolute inset-y-0 right-0 flex items-center pr-2"
          onClick={() => setOpen((o) => !o)}
        >
          <UpDownIcon open={showOptions} />
        </Combobox.Button>
      </div>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        afterLeave={() => setSearch('')}
        show={showOptions}
      >
        <Combobox.Options
          static
          className={`shadow top-100 bg-white z-40 w-full lef-0 rounded max-h-24 overflow-y-auto absolute mt-2`}
        >
          {!filteredOptions.length && !!search ? (
            <div className="relative cursor-default select-none px-4 py-2 text-gray-700">Nothing found.</div>
          ) : (
            filteredOptions.map((option, i) => (
              <Combobox.Option
                key={option.value + i}
                value={option}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-teal-600 text-white' : 'text-gray-900'
                  }`
                }
              >
                {({ active }) => {
                  const selected = !!formValue.find(({ value }) => value === option.value);
                  return (
                    <>
                      {/* NOTE: No need for a typography usecase here yet, because text is so basic and custom and could be controlled with tailwind font customizations */}
                      <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                        {option.label ?? option.value}
                      </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                            active ? 'text-white' : 'text-teal-600'
                          }`}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  );
                }}
              </Combobox.Option>
            ))
          )}
        </Combobox.Options>
      </Transition>
    </div>
  );

  return (
    <div className="w-2/3" onClick={() => setHasBeenTouched(true)}>
      <div className="relative">
        {singleSelect ? (
          <Combobox value={formValue} onChange={handleSelect} ref={ref}>
            {renderComboBoxContents()}
          </Combobox>
        ) : (
          <Combobox value={formValue} onChange={handleSelect} multiple ref={ref}>
            {renderComboBoxContents()}
          </Combobox>
        )}
        {error}
        {!singleSelect && (
          <div className="z-1">
            {formValue.map((tag, i) => (
              <Tag
                key={'tag-' + tag + i}
                onRemove={() => handleRemove(tag)}
                tag={tag.isNew ? tag.value : tag.label ?? tag.value}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
