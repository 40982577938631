export const NoMatchPage: React.FC = () => {
  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center">
        <div className="px-4 md:px-0 sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-2 mb-2 text-center text-xl font-bold leading-9 tracking-tight text-slate-800">
            No matching page found
          </h2>
        </div>
      </div>
    </>
  );
};
