import { useCallback } from 'react';
import { IOnClickProps } from '../click.types';

export interface IButtonDropdownOptionProps extends IOnClickProps {
  disabled?: boolean;
  text: string;
}

export const ButtonDropdownOption: React.FC<
  IButtonDropdownOptionProps & { setDropdownOpen: React.Dispatch<React.SetStateAction<boolean>> }
> = ({ disabled, onClick, setDropdownOpen, text }) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      if (!disabled && onClick) {
        setDropdownOpen(false);
        onClick(e);
        e.stopPropagation();
      }
    },
    [disabled, onClick, setDropdownOpen]
  );

  const additionalClasses = disabled
    ? 'bg-gray-300 text-white'
    : 'hover:bg-secondary hover:bg-opacity-80 hover:text-white cursor-pointer';

  return (
    <>
      <li onClick={handleClick} className={`px-4 py-2 ${additionalClasses}`}>
        {text}
      </li>
    </>
  );
};
