import { useCallback, useMemo } from 'react';
import download from 'downloadjs';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { showError } from '../../../lib/utils';
import { Button } from '../../_core/button/button.component';
import { useDownloadAllAssets } from '../assets/asset.service';
import { EditorApolloProvider } from '../../_editor/_core/editor-apollo-provider';
import { UploadData, UploadItemData } from '../../_editor/upload/upload.types';
import { IRequest, IRequestBlock, IRequestTokenParams } from '../../../lib/types';
import { OutputData } from '@editorjs/editorjs';

interface IDownloadRequestAssetsButton extends IRequestTokenParams {
  hasFiles: boolean;
  onSave: () => Promise<OutputData | undefined>;
}

const DownloadRequestAssetsButton = ({ hasFiles, onSave, requestToken }: IDownloadRequestAssetsButton) => {
  const { downloadAssets } = useDownloadAllAssets(requestToken);

  const findFilesToDownload = (blocks?: IRequestBlock[]) => {
    const foundFiles: UploadItemData[] = [];

    const updateFoundFiles = (data: object) => {
      Object.entries(data).forEach(([key, value]) => {
        if (key === 'uploads') {
          foundFiles.push(...(data as UploadData).uploads);
        } else if (typeof value === 'object') {
          updateFoundFiles(value);
        }
      });
    };
    blocks?.forEach(({ data }) => updateFoundFiles(data));

    return foundFiles;
  };

  const handleDownload = useCallback(
    ({ useOriginalFileName }: { useOriginalFileName?: boolean }) => {
      onSave()
        .then((savedData) => {
          if (savedData) {
            const items = findFilesToDownload(savedData.blocks as IRequestBlock[]);
            downloadAssets({ items })
              .then((results) =>
                results.forEach(({ data }, i) =>
                  download(data, useOriginalFileName ? items[i].fileName : items[i].label || items[i].fileName)
                )
              )
              .catch((err) => showError('Failed to download files', err));
          }
        })
        .catch((err) => showError('Failed to download files', err));
    },
    [downloadAssets, onSave]
  );

  return (
    <Button
      hideEndMargin
      fit="right"
      icon={<ArrowDownTrayIcon height={24} width={24} />}
      variant="secondary"
      dropdown={{
        options: [
          { text: 'With original filenames', onClick: () => handleDownload({ useOriginalFileName: true }) },
          { text: 'With labels as file names', onClick: () => handleDownload({}) }
        ],
        hideIcon: true,
        ignoreRelativeContainer: true,
        maxWidth: 'max-w-52'
      }}
      disabled={!hasFiles}
    />
  );
};

interface IDownloadRequestAssetsButtonContainer extends IRequestTokenParams {
  onSave: () => Promise<OutputData | undefined>;
  request: IRequest;
}

export const DownloadRequestAssetsButtonContainer = ({
  onSave,
  request,
  requestToken
}: IDownloadRequestAssetsButtonContainer) => {
  const hasFiles = useMemo(() => {
    const findFileToDownload = (data: object): boolean => {
      return Object.entries(data).some(([key, value]) => {
        if (key === 'uploads') {
          return !!(data as UploadData).uploads.length;
        } else if (typeof value === 'object') {
          return findFileToDownload(value);
        }

        return false;
      });
    };
    return !!request.blocks?.some(({ data }) => findFileToDownload(data));
  }, [request]);

  return (
    <EditorApolloProvider>
      <DownloadRequestAssetsButton hasFiles={hasFiles} onSave={onSave} requestToken={requestToken} />
    </EditorApolloProvider>
  );
};
