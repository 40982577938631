import { useState } from 'react';
import { FormContainer } from '../../../_core/form/form-container.component';
import {
  EditDepartmentsNestedContainer,
  IEditDepartmentsContainerProps
} from './edit-departments-form-nested.container';

export const EditDepartmentsContainer = (props: IEditDepartmentsContainerProps) => {
  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  return (
    <FormContainer
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      title="Edit Departments"
    >
      <EditDepartmentsNestedContainer {...props} onError={setSubmitError} onSuccess={setSuccess} />
    </FormContainer>
  );
};
