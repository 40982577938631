import { IBlockConversionData } from '../_core/types';
import {
  convertBlockImportData,
  convertBlockImportToLocalTitleData,
  localTitleBlockExport
} from '../_core/utils/block.utils';
import { RadioData } from './radio';

export const radioBlockExport = (data: RadioData): string => {
  const convertedData = { ...(localTitleBlockExport(data, false) as IBlockConversionData), options: data.options };
  return JSON.stringify(convertedData);
};

export const radioBlockImport = (importString: string): Partial<RadioData> => {
  const importData = convertBlockImportData(importString);
  return { ...convertBlockImportToLocalTitleData(importData), options: importData.options };
};
