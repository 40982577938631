import { IClientInviteQueryParams, IClientInvitesResult } from './client-invite.types';
import { GET_CLIENT_INVITES } from './client-invite.queries';
import { IClientInvite, INVITE_STATUS } from '../../../lib/types';
import { IApolloCacheParams } from '../../../lib/cache.types';

export const readClientInvitesCache = ({ cache }: IApolloCacheParams) =>
  cache.readQuery<IClientInvitesResult, IClientInviteQueryParams>({
    query: GET_CLIENT_INVITES,
    variables: { status: INVITE_STATUS.PENDING }
  })?.clientInvites;

export const writeClientInvitesCache = ({
  cache,
  clientInvites
}: IApolloCacheParams & { clientInvites: IClientInvite[] }) => {
  cache.writeQuery({
    query: GET_CLIENT_INVITES,
    data: { clientInvites },
    variables: { status: INVITE_STATUS.PENDING }
  });
};
