import { IFormSelectOption } from '../../../_core/form/input/form-select-input.component';

export enum InviteType {
  CLIENT = 'Client',
  STAFF = 'Staff'
}

export interface IGetInviteByCodeFormParams {
  code: string;
  type: InviteType;
}

export interface IGetInviteByCodeFormValues {
  code: string;
  type: IFormSelectOption;
}
