import { useCallback, useState } from 'react';
import { FormContainer } from '../../../_core/form/form-container.component';
import { useClients } from '../../clients/client.service';
import { EditRequestForm } from './edit-request.component';
import { useSelectedRequestTypes } from '../../request-type/request-type.service';
import { Loader } from '../../../_core/loader.component';
import { ICreateRequestCallParams, ICreateRequestProps } from '../request.types';
import { useCreateRequest, useUpdateRequest } from '../request.service';
import { useCompanyMembers } from '../../member/member.service';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../../_routes';
import { IRequest } from '../../../../lib/types';
import { ICloseableDialogProps } from '../../../_core/core.types';
import { useEntities } from '../../entities/entity.service';
import { showError } from '../../../../lib/utils';
import { useFetchCurrUser } from '../../users/user.service';

interface IEditRequestContainer extends Partial<ICloseableDialogProps> {
  request?: IRequest;
}

export const EditRequestContainer = ({ close, request }: IEditRequestContainer) => {
  const navigate = useNavigate();

  const [submitError, setSubmitError] = useState<string | null>(null);

  const { user } = useFetchCurrUser();

  const { clients, loading: loadingClients } = useClients();
  const { entities, loading: loadingEntities } = useEntities();
  const { requestTypes, loading: loadingTypes } = useSelectedRequestTypes();
  const { members, loading: loadingStaff } = useCompanyMembers();

  const { createRequest } = useCreateRequest();
  const { updateRequest } = useUpdateRequest({ _id: request?._id ?? '' });

  const handleSubmit = useCallback(
    async (props: ICreateRequestProps) => {
      const params: ICreateRequestCallParams = {
        ...props,
        assignedTo: props.assignedTo?.length ? props.assignedTo[0].value : undefined,
        ccClients: props.ccClients?.map(({ value }) => value),
        entityId: props.entity?.length ? props.entity[0].value : undefined,
        deadline: props.deadline ? new Date(props.deadline).toISOString() : undefined,
        requestType: props.requestType?.length ? props.requestType[0].value : undefined,
        staff: props.staff?.map(({ value }) => value)
      };

      if (request) {
        const result = await updateRequest(params);
        if (result.data && close) close();
      } else {
        try {
          const result = await createRequest(params);
          if (result.data) navigate(ROUTE_PATHS.REQUEST + '/' + result.data?.createRequest._id);
          else setSubmitError('Failed to create request');
        } catch (err) {
          showError('Failed to create request', err as Error);
        }
      }
    },
    [close, createRequest, navigate, request, updateRequest]
  );

  return (
    <FormContainer error={submitError} setError={setSubmitError} title={`${request ? 'Edit' : 'New'} Request`}>
      {loadingClients ||
      loadingEntities ||
      loadingTypes ||
      loadingStaff ||
      !user ||
      !clients ||
      !entities ||
      !requestTypes ||
      !members ? (
        <Loader />
      ) : (
        <EditRequestForm
          clients={clients}
          entities={entities}
          request={request}
          requestTypes={requestTypes}
          staff={members}
          onSubmit={handleSubmit}
          currUserId={user._id}
        />
      )}
    </FormContainer>
  );
};
