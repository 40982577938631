import { ROLE, ROLE_LABELS } from './lib/types';

export const APP_TITLE = 'Request IQ';
export const SUPPORT_EMAIL = 'support@requestiq.ca';

export const ROLE_OPTIONS = Object.entries(ROLE_LABELS).map(([value, label]) => ({ label, value }));

export const ROUTE_ROLES = {
  PUBLIC: 'PUBLIC',
  AUTHORIZED: 'AUTHORIZED',
  ...Object.entries(ROLE)
};
