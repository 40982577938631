import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Button } from '../button/button.component';

export interface IConfirmModalDetailsProps {
  center?: boolean;
  onConfirm: () => void;
  subtitle?: string;
  title?: string;
}

interface IConfirmModalProps extends IConfirmModalDetailsProps {
  open?: boolean;
  onClose: () => void;
}

export const ConfirmModal = ({
  center,
  open,
  onClose,
  onConfirm,
  subtitle,
  title = 'Are you sure?'
}: IConfirmModalProps) => {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className={`text-lg font-medium leading-6 text-gray-900 ${center ? 'text-center' : ''}`}
                >
                  {title}
                </Dialog.Title>
                {!!subtitle && (
                  <div className="mt-2">
                    <p className={`text-sm text-gray-500 ${center ? 'text-center' : ''}`}>{subtitle}</p>
                  </div>
                )}

                <div className="flex gap-4 mt-4 justify-center">
                  <Button variant="secondary" text="Cancel" onClick={onClose} />
                  <Button
                    text="Confirm"
                    onClick={() => {
                      onClose();
                      onConfirm();
                    }}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
