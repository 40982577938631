import { useCallback, useState } from 'react';
import { QuestionType, QuestionTypeLabels, QuestionTypes } from './question.types';
import { Listbox } from '@headlessui/react';
import { getSelectOptionsClasses } from '../../_core/form/input/form-utils';

interface IQuestionTypeSelectProps {
  onChange: (type: QuestionType) => void;
  type: QuestionType;
}

export const QuestionTypeSelect = ({ onChange, type }: IQuestionTypeSelectProps) => {
  const [selected, setSelected] = useState(type);

  const handleChange = useCallback(
    (v: QuestionType) => {
      setSelected(v);
      onChange(v);
    },
    [onChange]
  );

  return (
    <Listbox value={selected} onChange={handleChange}>
      <input type="text" value={selected} readOnly className="hidden" />
      <Listbox.Button className="inline-flex items-center justify-center px-3 text-sm text-gray-900 bg-gray-200 hover:bg-gray-100 border rounded-s-md border-gray-300 w-16 h-full">
        {QuestionTypeLabels[selected]}
      </Listbox.Button>
      <Listbox.Options as="ul" className={`pl-0 shadow top-100 bg-white z-40 w-16 rounded absolute mt-2`}>
        {QuestionTypes.map((qt) => (
          <Listbox.Option
            as="div"
            key={qt}
            value={qt}
            className={(p) => `${getSelectOptionsClasses({ ...p, noPadding: true })} text-center`}
          >
            <span>{QuestionTypeLabels[qt]}</span>
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Listbox>
  );
};
