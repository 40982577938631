import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { PLAN_NAME } from '../../../../lib/types';
import { IRIFormProps } from '../../../_core/form/form.types';
import { IRegisterCompanyProps } from '../company.types';
import { FormActionsRow } from '../../../_core/form/input/form-actions-row';

interface IRegisterCompanyFormValues {
  name: string;
}

export const RegisterCompanyForm = ({ onBack, onSubmit }: IRIFormProps<IRegisterCompanyProps>) => {
  const validateForm = (values: IRegisterCompanyFormValues) => {
    const errors: FormikErrors<IRegisterCompanyFormValues> = {};

    if (!values.name.trim()) {
      errors.name = 'Required';
    }

    return errors;
  };

  return (
    <Formik
      initialValues={{ name: '' }}
      onSubmit={(values) => onSubmit({ name: values.name.trim(), planName: PLAN_NAME.FIRM })}
      validate={validateForm}
    >
      {(props: FormikProps<IRegisterCompanyFormValues>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-6 mt-10">
          <FormInputWithLabel id="name" name="name" label="Firm Name" value={props.values.name} />
          <FormActionsRow>
            {!!onBack && <FormButton onPress={onBack} text="Back" type="button" variant="secondary" />}
            <FormButton loading={props.isSubmitting} text="Next" />
          </FormActionsRow>
        </Form>
      )}
    </Formik>
  );
};
