import { API, PopoverItemParams } from '@editorjs/editorjs';
import {
  BlockTool,
  BlockToolConstructorOptions,
  ToolConfig,
  TunesMenuConfigDefaultItem
} from '@editorjs/editorjs/types/tools';
import { renderWithBlockFocusWrapper } from '../_core/utils/editor.utils';

type SimpleImageData = {
  caption?: string;
  stretched: boolean;
  url?: string;
  withBackground: boolean;
  withBorder: boolean;
};

export class SimpleImage implements BlockTool {
  private api: API;
  private config: ToolConfig;
  private data: SimpleImageData;
  private wrapper?: HTMLDivElement;

  private settings: TunesMenuConfigDefaultItem[] = [
    {
      name: 'withBorder',
      label: 'Border',
      icon: `<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15.8 10.592v2.043h2.35v2.138H15.8v2.232h-2.25v-2.232h-2.4v-2.138h2.4v-2.28h2.25v.237h1.15-1.15zM1.9 8.455v-3.42c0-1.154.985-2.09 2.2-2.09h4.2v2.137H4.15v3.373H1.9zm0 2.137h2.25v3.325H8.3v2.138H4.1c-1.215 0-2.2-.936-2.2-2.09v-3.373zm15.05-2.137H14.7V5.082h-4.15V2.945h4.2c1.215 0 2.2.936 2.2 2.09v3.42z"/></svg>`,
      onActivate: (p: PopoverItemParams) => this.toggleTune(p),
      toggle: true
    },
    {
      name: 'stretched',
      label: 'Stretch',
      icon: `<svg width="17" height="10" viewBox="0 0 17 10" xmlns="http://www.w3.org/2000/svg"><path d="M13.568 5.925H4.056l1.703 1.703a1.125 1.125 0 0 1-1.59 1.591L.962 6.014A1.069 1.069 0 0 1 .588 4.26L4.38.469a1.069 1.069 0 0 1 1.512 1.511L4.084 3.787h9.606l-1.85-1.85a1.069 1.069 0 1 1 1.512-1.51l3.792 3.791a1.069 1.069 0 0 1-.475 1.788L13.514 9.16a1.125 1.125 0 0 1-1.59-1.591l1.644-1.644z"/></svg>`,
      onActivate: (p: PopoverItemParams) => this.toggleTune(p),
      toggle: true
    },
    {
      name: 'withBackground',
      label: 'Background',
      icon: `<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.043 8.265l3.183-3.183h-2.924L4.75 10.636v2.923l4.15-4.15v2.351l-2.158 2.159H8.9v2.137H4.7c-1.215 0-2.2-.936-2.2-2.09v-8.93c0-1.154.985-2.09 2.2-2.09h10.663l.033-.033.034.034c1.178.04 2.12.96 2.12 2.089v3.23H15.3V5.359l-2.906 2.906h-2.35zM7.951 5.082H4.75v3.201l3.201-3.2zm5.099 7.078v3.04h4.15v-3.04h-4.15zm-1.1-2.137h6.35c.635 0 1.15.489 1.15 1.092v5.13c0 .603-.515 1.092-1.15 1.092h-6.35c-.635 0-1.15-.489-1.15-1.092v-5.13c0-.603.515-1.092 1.15-1.092z"/></svg>`,

      onActivate: (p: PopoverItemParams) => this.toggleTune(p),
      toggle: true
    }
  ];

  constructor({ api, config, data }: BlockToolConstructorOptions<SimpleImageData>) {
    this.api = api;
    this.config = config;
    this.data = {
      url: data.url || '',
      caption: data.caption || '',
      withBorder: data.withBorder !== undefined ? data.withBorder : false,
      withBackground: data.withBackground !== undefined ? data.withBackground : false,
      stretched: data.stretched !== undefined ? data.stretched : false
    };
  }

  static get isReadOnlySupported(): boolean {
    return true;
  }

  static get sanitize() {
    return {
      url: false, // disallow HTML
      caption: {} // only tags from Inline Toolbar
    };
  }

  static get toolbox() {
    return {
      title: 'Image',
      icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>'
    };
  }

  private createImage(url: string, captionText?: string) {
    const image = document.createElement('img');
    const caption = document.createElement('div');

    image.src = url;
    image.classList.add('w-full', 'mb-2');

    caption.contentEditable = 'true';
    caption.innerHTML = captionText || '';

    if (this.wrapper) {
      this.wrapper.innerHTML = '';
      this.wrapper.appendChild(image);
      this.wrapper.appendChild(caption);
    }

    this.acceptTuneView();
  }

  render() {
    this.wrapper = document.createElement('div');
    const input = document.createElement('input');

    this.wrapper.classList.add('pv-4');
    this.wrapper.appendChild(input);

    if (this.data && this.data.url) {
      this.createImage(this.data.url, this.data.caption);
      return this.wrapper;
    }

    input.placeholder = this.config.placeholder || 'Paste an image URL...';
    input.value = this.data && (this.data.url ?? '');
    input.classList.add('w-full', 'p-2', 'border', 'border-gray-300', 'rounded-md');

    input.addEventListener('paste', (event) => {
      this.createImage(event.clipboardData?.getData('text') ?? '');
    });

    return renderWithBlockFocusWrapper(this.wrapper);
  }

  save(blockContent: HTMLDivElement): SimpleImageData {
    const image = blockContent.querySelector('img');
    const caption = blockContent.querySelector('[contenteditable]');

    return { ...this.data, url: image?.src, caption: caption?.innerHTML };
  }

  validate(savedData: SimpleImageData) {
    if (!savedData.url?.trim()) {
      return false;
    }

    return true;
  }

  // Block Settings
  private acceptTuneView() {
    this.settings.forEach(({ name }) => {
      if (name === 'stretched') {
        this.api.blocks.stretchBlock(this.api.blocks.getCurrentBlockIndex(), !!this.data.stretched);
      }
      if (name === 'withBackground') {
        this.wrapper?.classList.toggle('bg-gray-400', this.data.withBackground);
        this.wrapper?.classList.toggle('p-4', this.data.withBackground);
      }
      if (name === 'withBorder') {
        this.wrapper?.classList.toggle('border', this.data.withBorder);
        this.wrapper?.classList.toggle('border-grey', this.data.withBorder);
      }
    });
  }

  renderSettings() {
    if (this.data.withBorder) this.settings[0].isActive = true;
    if (this.data.stretched) this.settings[1].isActive = true;
    if (this.data.withBackground) this.settings[2].isActive = true;
    return this.settings;
  }

  private toggleTune(params: PopoverItemParams) {
    if (params.type === 'default') {
      const { name } = params;
      // @ts-expect-error - This is a makeshift save operation
      this.data[name] = !this.data[name];
      this.acceptTuneView();
    }
  }
}
