import { FormContainer } from '../../../_core/form/form-container.component';
import { useState } from 'react';
import { AcceptInviteNestedContainer } from './accept-invite-form-nested.container';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../../_routes';
import { sleep } from '../../../../lib/utils';
import { InviteType } from '../get-by-code/get-invite-by-code-form.types';

interface IAcceptInviteContainer {
  code?: string;
  type?: InviteType;
}

export const AcceptInviteContainer = ({ code, type }: IAcceptInviteContainer) => {
  const navigate = useNavigate();

  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  return (
    <FormContainer
      hideLogo
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      title="Accept Invite"
    >
      <AcceptInviteNestedContainer
        code={code}
        type={type}
        onError={setSubmitError}
        onSuccess={async (msg) => {
          setSuccess(msg);
          if (msg) {
            await sleep(250);
            navigate(ROUTE_PATHS.DASHBOARD);
          }
        }}
      />
    </FormContainer>
  );
};
