import { useClients } from '../../clients/client.service';
import { ContactsTableNestedContainer } from './contacts-table.nested.container';
import { Loader } from '../../../_core/loader.component';
import { useCurrCompany } from '../../companies/company.service';
import { IAdminProps } from '../../../_core/core.types';

export const ContactsTableContainer: React.FC<IAdminProps> = ({ isAdmin }) => {
  // const { clientInvites: invites } = useClientInvites();
  const { clients } = useClients();
  const { isPersonal } = useCurrCompany();

  if (!clients) return <Loader />;

  return (
    <ContactsTableNestedContainer isAdmin={isAdmin} isPersonalCompany={isPersonal} invites={[]} clients={clients} />
  );
};
