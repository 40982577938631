import { useState } from 'react';
import { Button, ButtonSize } from '../button/button.component';
import { ConfirmModal, IConfirmModalDetailsProps } from './confirm-modal.component';

interface IConfirmProps extends IConfirmModalDetailsProps {
  buttonTitle: string;
  loading?: boolean;
  size?: ButtonSize;
}

export const ConfirmButton = ({ buttonTitle, loading, size, ...modalProps }: IConfirmProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button text={buttonTitle} onClick={() => setIsOpen(true)} loading={loading} size={size} />
      <ConfirmModal {...modalProps} open={isOpen} onClose={closeModal} />
    </>
  );
};
