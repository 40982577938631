interface IFormStepProps {
  label: string;
  index: number;
  small?: boolean;
  state: 'active' | 'completed' | 'unvisited';
}

export const FormStep = ({ label, index, small, state }: IFormStepProps) => {
  let additionalClasses = '';
  switch (state) {
    case 'active':
      additionalClasses += 'text-primary border-primary';
      break;
    case 'completed':
      additionalClasses += 'text-blue-800 border-blue-800';
      break;
    default:
      additionalClasses += 'text-gray-500 border-gray-500';
  }

  return (
    <li className={`flex items-center space-x-2.5 rtl:space-x-reverse ${additionalClasses}`}>
      <span className={`flex items-center justify-center w-8 h-8 border rounded-full shrink-0 ${additionalClasses}`}>
        {index}
      </span>
      <span className={small ? 'hidden' : ''}>
        <h3 className="font-medium leading-tight">{label}</h3>
      </span>
    </li>
  );
};
