import { Button } from '../../../_core/button/button.component';
import { IRequest, REQUEST_STATUS } from '../../../../lib/types';
import { useMemo, useState } from 'react';
import { RequestTable, IRequestTableRowValues } from './request-table.component';
import { RIDialog } from '../../../_core/dialog/dialog.component';

import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';
import { EditRequestContainer } from '../../../domains/request/edit/edit-request.container';
import { RequestActionButton } from '../../../domains/request/request-action-button';
import { useFetchCurrUser } from '../../../domains/users/user.service';

export interface IRequestTableContainer extends IAdminProps, IPersonalCompanyProps {
  draftsMode?: boolean;
}

interface IRequestTableNestedContainerProps extends IRequestTableContainer {
  requests?: IRequest[];
}

export const RequestTableNestedContainer: React.FC<IRequestTableNestedContainerProps> = ({
  requests,
  isAdmin,
  draftsMode
}) => {
  const { user } = useFetchCurrUser();

  const [createRequest, setCreateRequest] = useState(false);

  const requestRows = useMemo(() => {
    if (!requests) return [];
    const sortable = [...requests];
    return sortable
      .filter((r) => (draftsMode ? r.status === REQUEST_STATUS.DRAFT : r.status !== REQUEST_STATUS.DRAFT))
      .sort((a, b) => {
        if (a.deletedAt && b.deletedAt) return a.deletedAt < b.deletedAt ? 1 : -1;
        if (a.deletedAt && !b.deletedAt) return 1;
        if (!a.deletedAt && b.deletedAt) return -1;
        return a.updatedAt < b.updatedAt ? 1 : -1;
      })
      .map((request, i) => {
        const row: IRequestTableRowValues = {
          request,
          values: [
            {
              children: (
                <b className={user?._id === request.createdBy._id ? 'text-green-600' : undefined}>
                  {request.createdBy.name}
                </b>
              )
            },
            { children: request.name },
            { children: new Date(request.updatedAt).toLocaleDateString() },
            { children: request.entity?.name ?? '' },
            { children: request.assignedTo?.user.name ?? request.assignedTo?.user.email ?? '' },
            { children: request.requestType?.type ?? '' },
            { children: request.deadline ? new Date(request.deadline).toLocaleDateString() : '' ?? '' },
            { children: request.deletedAt ? 'Archived' : request.status ?? 'DRAFT' },
            {
              children: (
                <RequestActionButton request={request} last={requests.length > 3 && i === requests.length - 1} />
              )
            }
          ]
        };

        return row;
      });
  }, [draftsMode, requests, user?._id]);

  if (!requestRows) return null;

  return (
    <>
      <RequestTable requests={requestRows}>
        {isAdmin && <Button onClick={() => setCreateRequest(true)} text="Create Request" size="large" />}
      </RequestTable>
      <RIDialog open={createRequest} setOpen={setCreateRequest}>
        <EditRequestContainer />
      </RIDialog>
    </>
  );
};
