import { useAuth0 } from '@auth0/auth0-react';
import { ForceRegisterPrompts } from './force-register-prompts.component';
import { useCurrCompany, useUserCompanies } from '../../../domains/companies/company.service';
import { useFetchCurrUser } from '../../../domains/users/user.service';
import { useLocation } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../../_routes';

export const ForceRegisterPromptsContainer = () => {
  const { user: authUser } = useAuth0();
  const { pathname } = useLocation();

  const { company, isPersonal, loading: loadingCompany, called: fetchedCurrCompany } = useCurrCompany();
  const { user, loading: loadingUser } = useFetchCurrUser();
  const {
    activeCompanies,
    loading: loadingCompanies,
    called: fetchedUserCompanies,
    error: userCompaniesError
  } = useUserCompanies({ skip: !user });

  if (!authUser || (loadingUser && !user)) return null;

  const isRegistered = !!user;
  return (
    <ForceRegisterPrompts
      authUser={authUser}
      companiesLoading={!fetchedUserCompanies || loadingCompanies || !fetchedCurrCompany || loadingCompany}
      companiesError={!!userCompaniesError && isRegistered}
      company={company}
      isPersonalCompany={isPersonal}
      hasCompany={!!activeCompanies?.length}
      hideRegisterCompany={pathname.startsWith(ROUTE_PATHS.JOIN)}
      isRegistered={isRegistered}
    />
  );
};
